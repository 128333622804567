import React, { PropsWithChildren } from 'react';
import { TagInfo } from './types';
import { LabeledRadioInButton } from '@commonsku/styles';
import './TagLabel.css';

export interface TagLabelProps {
    tag: TagInfo
    selectable?: boolean
    selected?: boolean
    onSelect?: (tag: TagInfo) => void
}

export const TagLabel = (props: PropsWithChildren<TagLabelProps>) => {
    const { tag, selectable, selected, onSelect } = props;

    if (selectable == null || !selectable) {
        return (
            <div className="tag-label">
                {tag.label}
            </div>
        );
    }

    return (
        <div className="selectable-tag-label">
            <LabeledRadioInButton
                label={tag.label}
                checked={selected}
                onChange={onSelect != null ? () => onSelect(tag) : undefined}
            />
        </div>
    );
}
