import React from 'react';

import AdminCompanyAddress from './AdminCompanyAddress';
import AdminCompanyTerm from './AdminCompanyTerm';
import AdminCompanyGeneralDataFormNumbers from './AdminCompanyGeneralDataFormNumbers';
import AdminCompanyGeneralDataDefaults from './AdminCompanyGeneralDataDefaults';
import AdminNextActionType from './AdminNextActionType';
import AdminImprint from './AdminImprint';
import AdminTax from './AdminTax';
import AdminShipMethod from './AdminShipMethod';
import AdminSupplierShipMethod from './AdminSupplierShipMethod';
import AdminShipAccount from './AdminShipAccount';
import AdminTabbedInPopup from './AdminTabbedInPopup';
import AdminStatus from './AdminStatus';
import AdminExchangeRate from './AdminExchangeRate';
import AdminBenefits from './AdminBenefits';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import { useIdentity } from '../../hooks';


const AdminTabbedSetup = () => {
    const identity = useIdentity();
    const defaultModule = 'SUPPLIER' === identity.company_type ? 'status' : 'addresses';
    let moduleMap = {
       'addresses': {
            'component': AdminCompanyAddress,
            'title': 'Company Contact Info',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'tax': {
            'component': AdminTax,
            'title': 'Tax',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'terms': {
            'component': AdminCompanyTerm,
            'title': 'Payment Terms',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'exchangeRate': {
            'component': AdminExchangeRate,
            'title': 'Currency Conversion',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'formNumbers': {
            'component': AdminCompanyGeneralDataFormNumbers,
            'title': 'Project Numbers',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'defaults': {
            'component': AdminCompanyGeneralDataDefaults,
            'title': 'Defaults',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'status': {
            'component': AdminStatus,
            'title': 'Statuses',
            'show': true,
        },
        'shipMethod': {
            'component': 'SUPPLIER' === identity.company_type ? AdminSupplierShipMethod : AdminShipMethod,
            'title': 'Ship Methods',
            'show': true,
        },
        'shipAccount': {
            'component': AdminShipAccount,
            'title': 'Shipping Accounts',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'imprints': {
            'component': AdminImprint,
            'title': 'Imprint Methods',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'actionTypes': {
            'component': AdminNextActionType,
            'title': 'Next Action Types',
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'benefits': {
            'component': AdminBenefits,
            'title': 'Benefits',
            'show': 'SUPPLIER' === identity.company_type,
	},
    };

    const [{ data }] = useCompanyGeneralData();
    if (data?.companyData?.use_margin_currency != '1') {
        delete moduleMap['exchangeRate'];
    }

    return <AdminTabbedInPopup moduleMap={moduleMap} defaultModule={defaultModule} />;
};

export default AdminTabbedSetup;
