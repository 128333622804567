import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
currency_id: "EUR"
date_created: "2022-06-09 10:47:48"
qbo_account_ref: "51"
qbo_currency_id: "bb3d0f63-f3c5-465e-8cac-493255491888"
qbo_currency_ref: "2"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_currency_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboCurrencies', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'qbo-currency',  {});
    dispatch(received(res.json.qbo_currencies));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'qbo-currency', data);
    dispatch(added(Object.assign(res.json.qbo_currency, {new: true})));
    dispatch(loaded());
    return res.json.qbo_currency;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-currency/' + data.qbo_currency_id, data);
    dispatch(updated({ id: data.qbo_currency_id, changes: data }));
    dispatch(loaded());
    return res.json.qbo_currency;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
