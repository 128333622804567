import { useEffect } from 'react';
import { selectors, getAll } from '../../../redux/companyDivisions';
import { useSelector, useDispatch } from 'react-redux';

export default function useCompanyDivisions() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyDivisions);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    return [
        items,
        loading,
    ];
};
