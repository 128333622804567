import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
csku_field: "client_name"
date_created: "2022-06-13 09:04:14"
qbo_class_id: "6b45b086-6ec1-4e2b-a070-87c2302b322a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_class_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboClasses', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const addItem = (data) => async (dispatch, getState) => {
    /*
    csku_field: client_name
    */
    dispatch(loading());
    const res = await oauth('POST', 'qbo-class', data);
    dispatch(added(Object.assign(res.json.qbo_class, {new: true})));
    dispatch(loaded());
    return res.json.qbo_class;
};

export const updateItem = (data) => async (dispatch, getState) => {
    /*
    csku_field: client_name
    */
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-class/' + data.qbo_class_id, data);
    dispatch(updated({ id: data.qbo_class, changes: data }));
    dispatch(loaded());
    return res.json.qbo_class;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
