import React from 'react';
import AdminCommissionClientRate from './AdminCommissionClientRate';
import AdminCommissionMarginRate from './AdminCommissionMarginRate';
import AdminCommissionPeopleRate from './AdminCommissionPeopleRate';
import AdminCommissionRate from './AdminCommissionRate';
import AdminCommissionSalesTargetRate from './AdminCommissionSalesTargetRate';
import AdminCompanyCommissionMaximum from './AdminCompanyCommissionMaximum';
import AdminCompanyService from './AdminCompanyService';
import AdminTabbedInPopup from './AdminTabbedInPopup';

// TODO: Replace the following with actual components
const moduleMap = {
    'rate': {
        'component': AdminCommissionRate,
        'title': 'Commission Rates',
        'show': true,
    },
    'people': {
        'component': AdminCommissionPeopleRate,
        'title': 'People',
        'show': true,
    },
    'service': {
        'component': AdminCompanyService,
        'title': 'Excludes',
        'show': true,
    },
    'maximum': {
        'component': AdminCompanyCommissionMaximum,
        'title': 'Maximum',
        'show': true,
    },
    'margin': {
        'component': AdminCommissionMarginRate,
        'title': 'Margin Table',
        'show': true,
    },
    'client': {
        'component': AdminCommissionClientRate,
        'title': 'Client Rates',
        'show': true,
    },
    'salesTarget': {
        'component': AdminCommissionSalesTargetRate,
        'title': 'Sales Goal',
        'show': true,
    },
};

const AdminTabbedCommission = () => {
    return <AdminTabbedInPopup moduleMap={moduleMap} defaultModule={'rate'} />;
};

export default AdminTabbedCommission;
