import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
active: "1"
carrier: "OTHER"
courier_id: null
created_by: "e25031ec-b7a8-4c96-b091-00c889a12487"
date_created: "2012-05-04 07:02:47"
ordering: "0"
service: "Fed Ex - 12582929 "
ship_code: null
ship_method_id: "efe91a8b-ff5b-4fbf-84cf-bdbcbae4efd7"
ship_method_name: "Fed Ex - 12582929 "
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
third_party: "UNAVAILABLE"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.ship_method_id,
    sortComparer: (a, b) => {
        if (parseInt(a.ordering) < parseInt(b.ordering)) {
            return -1;
        }

        if (parseInt(a.ordering) > parseInt(b.ordering)) {
            return 1;
        }

        return 0;
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyShipMethods', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'ship-method';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.ship_methods));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.ship_method, {new: true})));
    dispatch(loaded());
    return res.json.ship_method;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.ship_method_id, data);
    dispatch(updated({ id: data.ship_method_id, changes: data }));
    dispatch(loaded());
    return res.json.tax;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.ship_method_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.ship_method_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
