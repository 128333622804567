import { useEffect, useCallback, useMemo } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/companyShipMethods';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useCouriers from './useCouriers';
import useSortable from './useSortable';

export default function useCompanyShipMethod(tenantId = null) {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyShipMethods);
    const allItems = selectors.selectAll(reduxState);
    const loading = reduxState.loading;
    const couriers = useCouriers();
    const originalItems = useMemo(() => {
        if (tenantId) {
            return allItems.filter(item => item.tenant_id === tenantId);
        }
        return allItems;
    }, [allItems, tenantId]);
    const [items, onMove, onDrop] = useSortable(originalItems, updateItem, 'ordering');

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            if (item.ship_method_id) {
                await dispatch(updateItem({ id: item.ship_method_id, ...item }));
            } else {
                await dispatch(addItem({ ...item, ordering: items.length }));
            }
            toast.success("Saved successfully");
        };

        updateCall();
    }, [dispatch, items.length]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
            let order = 0;
            for (const i of items) {
                if (i.ship_method_id === item.ship_method_id) {
                    continue;
                }
                if (order != i.ordering) {
                    await dispatch(updateItem({ ...i, ordering: order }));
                }
                order++;
            }
        };

        deleteCall();
    }, [dispatch, items]);

    return [
        items,
        loading,
        save,
        remove,
        couriers,
        onMove,
        onDrop,
    ];
};
