import React, { forwardRef } from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyNextActionType';
import useCompanyNextActionType from './hooks/useCompanyNextActionType';
import TopInfo from './misc/Info';

export default function AdminNextActionType() {
    const [items, loading, save, remove, onMove, onDrop] = useCompanyNextActionType();

    let index = 0;
    const list = items.map((item, itemIndex) => {
        return (
            <div key={item.next_action_type_id} data-testid={`row-${itemIndex}`}>
                <Row>
                    <Form id={item.next_action_type_id} index={itemIndex} item={item} saveItem={save} deleteItem={remove} onMove={onMove} onDrop={onDrop} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message="Create custom Next Action Types to help your production team manage POs on their Production Reports. You can drag and drop your action items to match your order of operations." link='https://help.commonsku.com/knowledge/next-action-types' />
            {list}
            {add}
        </>
    );
};
