import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Col, Row, } from '@commonsku/styles';
import RichTextEditor from '../../LazyRichTextEditorTinymce';
import { debounce } from 'lodash';

export default function EmailSignature({
    data, save
}) {
    const defaultValues = useMemo(() => ({
        user_email_signature: '',
    }), []);

    const [form, setForm] = useState(defaultValues);

    useEffect(() => {
        setForm({
            user_email_signature: data.user_email_signature
        });
    }, [data.user_email_signature, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const saveForm = useCallback(debounce((formData) => {
        // Only save when the data has been changed.
        if (formData.user_email_signature != data.user_email_signature) {
            save(formData);
        }
    }, 2000), [save]);

    let formFields = (
        <Row>
            <Col padded sm={12}>
                <RichTextEditor value={form.user_email_signature}
                    onChange={(value) => {
                        setFormFieldValue({
                            target: {
                                name: 'user_email_signature',
                                value: value,
                            }
                        });
                        saveForm({
                            user_email_signature: value
                        });
                    }}
                />
            </Col>
        </Row>
    );

    return formFields;
}
