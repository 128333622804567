import React, { useEffect, useState } from 'react';
import { AddIcon } from '@commonsku/styles';
import useEmailTemplate from './hooks/useEmailTemplate';
import Form from './form/EmailTemplate';

const AdminEmailTemplate = () => {
    const [selected, setSelected] = useState('first');
    const [items, , save, remove] = useEmailTemplate(setSelected);

    useEffect(() => {
        if (selected === 'first' && items?.length > 0) {
            setSelected(items[0]);
        }
    }, [items, selected]);

    let form = (
        <>
            <Form item={selected} saveItem={save} deleteItem={remove} />
        </>
    );

    const list = items.map((item) => {
        return (
            <li key={item.email_template_id}>
                <a
                    href="/"
                    className={'module-tab' + (item.email_template_id === selected?.email_template_id ? ' active' : '')}
                    onClick={(e) => {
                        e.preventDefault();
                        setSelected(item);
                    }}
                >
                    {item.email_template_title}
                </a>
            </li>
        );
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', height: '100%', overflowY: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '250px', width: '250px', paddingTop: '20px', backgroundColor: '#E1F7FA', padding: 0, margin: 0, overflowY: 'hidden', paddingBottom: '40px' }}>
                <ul style={{ listStyle: 'none', margin: '0', overflowY: 'auto', paddingBottom: '20px' }}>
                    {list}
                </ul>
                <div key='new' style={{ position: 'absolute', bottom: '0' }}>
                    <a
                        style={{ display: 'flex', alignItems: 'center', width: '250px' }}
                        href="/"
                        className={'module-tab' + (selected === null ? ' active' : '')}
                        onClick={(e) => {
                            e.preventDefault();
                            setSelected(null);
                        }}
                    >
                        <AddIcon width="1rem"></AddIcon> <span>Add Template</span>
                    </a>
                </div>
            </div>
            <div style={{ flexGrow: 1, padding: '0 2rem', height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                {form}
            </div>
        </div>
    );
};

export default AdminEmailTemplate;
