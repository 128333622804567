import { find, map, isMatch, isEmpty } from 'lodash';
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button, LabeledInput, Row, Col, DoubleArrowIcon, colors, Loading } from '@commonsku/styles';
import { window } from '../../../global';
import SageUserTable from './SageUserTable';
import { getIdentityUtils, oauth } from '../../../utils';
import { setData } from '../../../redux/companyGeneralData';
import { useIdentity } from '../../../hooks';

const ScrollerDecoration = styled.div`
  background: linear-gradient(${props => props.directionUp ? "180deg" : "0deg"}, #DFEEF4 0%, rgba(166, 194, 198, 0.00) 100%);
  ${props => props.directionUp ? "" : "bottom: 0;"}
  position: absolute;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

export default function Sage({ data, save }) {
  const defaultValues = useMemo(() => ({
    sage_account: '',
    sage_login: '',
    sage_password: '',
    sage_token: '',
    use_sage_connect: '',
  }), []);
  const dispatch = useDispatch();
  const identity = useIdentity();
  const { hasUserFlags } = getIdentityUtils(identity);
  const userCredentialEnabled = true;

  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [scrolledToBottom, setScrolledToBottom] = useState(true);

  const [form, setForm] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState(defaultValues);

  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [hasConflict, setHasConflict] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let values = defaultValues;
    values = {
      sage_account: data.sage_account,
      sage_login: data.sage_login,
      sage_password: data.sage_password,
      sage_token: data.sage_token,
      use_sage_connect: data.use_sage_connect,
    };
    setForm(values);
  }, [data.cardconnect_merchid, data.sage_account, data.sage_login, data.sage_password, data.sage_token, data.use_sage_connect, defaultValues]);

  const ref = useRef(null); // Ref for the scrollable container

  const scrollToPosition = (direction) => {
    const element = ref.current;
    const offsetHeight = 300;
    if (element) {
      // Get the current scroll position
      const currentScrollPosition = element.scrollTop;

      // Calculate the new scroll position
      let newScrollPosition;
      if (direction === 'up') {
        newScrollPosition = Math.max(currentScrollPosition - offsetHeight, 0);
      } else if (direction === 'down') {
        newScrollPosition = Math.min(currentScrollPosition + offsetHeight, element.scrollHeight - element.clientHeight);
      }

      // Scroll to the new position
      element.scroll({
        top: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = useCallback(() => {
    const element = ref.current;
    if (element) {
      const isAtTop = element.scrollTop === 0;
      const threshold = 10;
      const isAtBottom = (element.scrollHeight - element.scrollTop - element.clientHeight) < threshold;

      if (isAtTop !== scrolledToTop) {
        setScrolledToTop(isAtTop);
      }
      if (isAtBottom !== scrolledToBottom) {
        setScrolledToBottom(isAtBottom);
      }
    }
  }, [scrolledToTop, scrolledToBottom]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      handleScroll();
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [handleScroll]);

  const setFormFieldValue = useCallback((e) => {
    const target = e.target;
    setForm((form) => ({ ...form, [target.name]: target.value }));
  }, []);

  const validateForm = useCallback((data) => {
    setFormErrors(defaultValues);
    let errorCount = 0;
    // Check data and set form errors
    for (const field of ['sage_account', 'sage_login', 'sage_password']) {
      if (data[field] === '') {
        setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
        errorCount++;
      }
    }

    return errorCount <= 0;
  }, [defaultValues]);

  const saveForm = async () => {
    if (!validateForm(form)) {
      return;
    }
    setLoading(true);
    if (!isMatch(data, form)) {
      await save(form, false);
    }
    if (userCredentialEnabled && !isEmpty(updatedUsers)) {
      const { json } = await oauth('PUT', 'user', {
        actionName: 'bulk_update',
        users: updatedUsers,
        parent_id: identity.company_id,
        parent_type: identity.company_type,
      });
      dispatch(setData({
        ...data,
        ...form,
        users: map(data.users, (user) => {
          return find(json.users, { user_id: user.user_id }) || user;
        })
      }));
    }
    setLoading(false);
  };

  const handleUsersChange = useCallback(({ updatedUsers, hasConflict }) => {
    setUpdatedUsers(updatedUsers);
    setHasConflict(hasConflict);
  }, []);

  const getTextInput = (field, label = null, sm = 4, xl = 4) => {
    const hasError = formErrors[field] !== '';
    return (
      <Col padded sm={sm} xl={xl}>
        <LabeledInput label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} />
        {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
      </Col>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <Row style={{ overflow: 'auto', width: '100%' }} onScroll={handleScroll} ref={ref}>
        {!scrolledToTop &&
          <div style={{ height: 0, position: 'sticky', top: 0, width: '100%', zIndex: 999 }}>
            <ScrollerDecoration directionUp onClick={() => scrollToPosition('up')}>
              <DoubleArrowIcon direction="up" />
            </ScrollerDecoration>
          </div>
        }
        <Row style={{ width: '100%' }}>
          {getTextInput('sage_account', 'SAGE Account', 6, 3)}
          {getTextInput('sage_login', 'License Login', 6, 3)}
          {getTextInput('sage_password', 'License Password', 6, 3)}
          {getTextInput('sage_token', 'Authentication Key', 6, 3)}
        </Row>
        <Row style={{ alignItems: 'center', width: '100%', marginTop: 10 }}>
          <Col padded sm={4} xl={2}>
            <Button size='small'
              onClick={() => {
                if (form.sage_token === '' && data.use_sage_connect != 1) {
                  alert('Cannot Enable SAGE Connect, please enter SAGE Token.');
                  return;
                }

                const confirmed = window.confirm(
                  data.use_sage_connect == 1
                    ? "Are you sure you want to disable SAGE Connect?"
                    : "Ready To Opt In? By selecting yes, you are activating the SAGE Connect integration offering (200 queries per month, per SAGE Total Access user). Please contact your SAGE rep for pricing and membership details."
                );
                if (!confirmed) {
                  return;
                }
                save({ ...form, use_sage_connect: data.use_sage_connect == 1 ? 0 : 1 });
              }}>{data.use_sage_connect == 1 ? 'Disable Sage Connect' : 'Enable Sage Connect'}</Button>
          </Col>
          <Col padded sm={8} xl={3}>
            <a href="https://help.commonsku.com/knowledge/sage" target="_blank" style={{ fontWeight: '600', fontSize: '14px' }}>Setup and Integration Details</a>
          </Col>
          <Col padded sm={12} xl={7}>
            <div>
              <div>To use SAGE Connect, please ensure that you have filled out all the fields above.</div>
              <div>Contact SAGE For pricing and membership details: <strong>(800) 925-7243</strong> or <a href="mailto:support@sageworld.com">support@sageworld.com</a></div>
            </div>
          </Col>
        </Row>
        {!userCredentialEnabled ? null : (
          !isEmpty(data.users)
            ? <SageUserTable users={data.users} onChange={handleUsersChange} />
            : <Row style={{ width: '100%', marginTop: 37 }}>
              <Col xs style={{ alignSelf: "100%", textAlign: 'center' }}>
                <Loading />
              </Col>
            </Row>
        )}
        {!scrolledToBottom && <div style={{ height: 0, position: 'sticky', bottom: 0, width: '100%' }}>
          <ScrollerDecoration onClick={() => scrollToPosition('down')}>
            <DoubleArrowIcon direction="down" />
          </ScrollerDecoration>
        </div>}
      </Row >
      <Row style={{ placeSelf: 'flex-end' }}>
        <Col padded sm={12} style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
          {hasConflict && <div style={{ color: colors.errors['60'], fontSize: 14 }}>Please ensure that all user names are unique before saving</div>}
          <Button size='small' onClick={saveForm} disabled={loading || hasConflict}>{loading ? 'Updating' : 'Save'}</Button>
        </Col>
      </Row>
    </div >
  );
}
