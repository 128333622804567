import React from 'react';
import { LightIndicator, TemplateIcon } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';

export default function AdminProjectTemplateSummary({ title }) {
    const [{ data }, loading, save, saveExtra, identity] = useCompanyGeneralData();

    const templates = data?.job_templates || [];

    return (
        <div style={{ display: 'flex' }}>
            <div><TemplateIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <LightIndicator name={`${templates?.length} templates configured`} on={templates?.length > 0 ? true : false} />
            </div>
        </div>
    );
}
