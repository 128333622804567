import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
company_term_id: "f27aa49d-38c7-46f8-8a80-f7098f7b786e"
company_type: "TENANT"
date_created: "2021-04-15 12:35:01"
display_order: "4"
terms_id: "8680c46d-2518-4f66-a182-db6e908df986"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.company_term_id,
    sortComparer: (a, b) => {
        if (parseInt(a.display_order) < parseInt(b.display_order)) {
            return -1;
        }

        if (parseInt(a.display_order) > parseInt(b.display_order)) {
            return 1;
        }

        return 0;
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyTerms', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'company-term',  {});
    dispatch(received(res.json.company_terms));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'company-term', data);
    dispatch(added(Object.assign(res.json.company_term, {new: true})));
    dispatch(loaded());
    return res.json.company_term;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'company-term/' + data.company_term_id, data);
    dispatch(updated({ id: data.company_term_id, changes: data }));
    dispatch(loaded());
    return res.json.company_term;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', 'company-term/' + data.company_term_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.company_term_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
