import React from 'react';
import { Col, Row, Box } from '@commonsku/styles';
import useTenantUsers from './hooks/useTenantUsers';
import Avatar from 'react-avatar';

const ContactAdmin = ({ message }) => {
    const [users,] = useTenantUsers();
    const listMessage = message || "You don't have permission to edit Company Settings. Please contact one of your team's admins to make changes:";

    const list = (users || []).map((item) => {
        if (item.role_name !== 'Admin') return null;
        return (
            <Row key={item.user_id}>
                <Col sm={12} padded>
                    <Avatar src={item.user_image_paths?.small || null} name={item.contact_full_name} round={true} size='30' /> {item.contact_full_name} ({item.contact_email})
                </Col>
            </Row>
        );
    });

    return (
        <Row>
            <Col sm={12}>
                <Box style={{ padding: '15px', margin: '15px' }}>
                    <div>
                        {listMessage}
                    </div>
                    {list}
                </Box>
            </Col>
        </Row>
    );
};

export default ContactAdmin;
