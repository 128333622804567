import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { oauth } from '../../../utils';

export default function useQuickbookLineItemType() {
    const dispatch = useDispatch();
    const [lineItemTypes, setLineItemTypes] = useState([]);

    const saveQboAccount = useCallback((itemData, lineItemType) => {
        // https://vandelaypromotions.commonsku.local/v1/qbo-account/b46533ec-88bc-40ec-9cbc-2c3d92235022
        // qbo_account_ref: 28
        console.log('saveQboAccount', itemData, lineItemType);

        const call = async () => {
            const res = await oauth('PUT', 'qbo-account/' + lineItemType.qbo_account_id, itemData);

            setLineItemTypes(old => {
                // Update state to have the new data
                const newLineItemTypes = [];
                for (const type of old) {
                    if (type.qbo_account && type.qbo_account.qbo_account_id === lineItemType.qbo_account_id) {
                        type.qbo_account = {...type.qbo_account, ...itemData};
                    }
                    newLineItemTypes.push(type);
                }
                console.log(newLineItemTypes, 'new');
                return newLineItemTypes;
            });
        };

        call();
    }, []);

    const saveQboItem = useCallback((itemData, lineItemType) => {
        // https://vandelaypromotions.commonsku.local/v1/qbo-item/01474800-bda1-4779-88b8-2b4e1d26e7e8
        // qbo_item_ref: 1
        console.log('saveQboAccount', itemData, lineItemType);

        const call = async () => {
            const res = await oauth('PUT', 'qbo-item/' + lineItemType.qbo_item_id, itemData);

            setLineItemTypes(old => {
                // Update state to have the new data
                const newLineItemTypes = [];
                for (const type of old) {
                    if (type.qbo_item && type.qbo_item.qbo_item_id === lineItemType.qbo_item_id) {
                        type.qbo_item = {...type.qbo_item, ...itemData};
                    }
                    newLineItemTypes.push(type);
                }
                console.log(newLineItemTypes, 'new');
                return newLineItemTypes;
            });
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('GET', 'line-item-type', {});
            setLineItemTypes(res.json.line_item_types);
        };
        call();
    }, [dispatch]);

    return [lineItemTypes, saveQboAccount, saveQboItem];
};
