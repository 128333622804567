import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { LabeledIconInput, Row, Col } from '@commonsku/styles';

export default function CompanyCommissionMaximum({ data, save }) {
    const defaultValues = useMemo(() => ({
        maximum_commission: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    useEffect(() => {
        let values = defaultValues;
        if (data) {
            values = {
                ...values,
                maximum_commission: Number(data.maximum_commission).toFixed(2),
            };
        }
        setForm(values);
    }, [data, data.maximum_commission, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['maximum_commission']) {
            if (data[field].toString().trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            } else if (field === 'maximum_commission') {
                const rate = Number(data[field]);
                if (isNaN(rate) || rate > 100 || rate < 0) {
                    setFormErrors((errors) => ({ ...errors, [field]: 'Rate must be between 0 and 100' }));
                    errorCount++;
                }
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const getTextInput = (field, label = null) => {
        return (
            <>
                <LabeledIconInput
                    style={{ maxWidth: '100px' }}
                    iconPosition='right'
                    Icon={<div>%</div>}
                    className='align-with-select'
                    label={label || ''}
                    name={field}
                    value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (!validateForm(form)) {
                            return;
                        }
                        save(form);
                    }} />
            </>
        );
    };

    const formFields = (<>
        <Row>
            <Col padded sm={12}>
                <h5 style={{ fontWeight: 'bold', marginBottom: '-0.5rem' }}>Maximum Commission (Threshold)</h5>
            </Col>
        </Row>
        <Row>
            <Col padded sm={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getTextInput('maximum_commission', '')}
                    <div style={{ marginLeft: '0.5rem' }}>of Margin</div>
                </div>
                {formErrors['maximum_commission'] !== '' ? <span style={{ color: 'red' }}>{formErrors['maximum_commission']}</span> : ''}
            </Col>
        </Row></>
    );

    return formFields;
}
