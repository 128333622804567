import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useCompanyTax from '../../hooks/useCompanyTax';
import useQuickbookTax from '../../hooks/useQuickbookTax';
import { getItemRefByName } from './helpers';

export default function Tax({ qboTaxCodes }) {
    const [taxes] = useCompanyTax();
    const [items, save] = useQuickbookTax();

    if (qboTaxCodes.length === 0) return <Loading />;

    const getSelect = (ref, existingData) => {
        const options = taxes.map(t => ({ label: t.label, value: t.tax_id }));
        let value = null;
        if (existingData) {
            value = options.filter(o => existingData.tax_ids.indexOf(o.value) !== -1);
        }
        return (
            <>
                <LabeledSelect
                    label=''
                    placeholder='Select a tax code'
                    isMulti
                    options={options}
                    value={value}
                    onChange={(selectedItems) => {
                        selectedItems = selectedItems === null ? [{label: '', value: ''}] : selectedItems;
                        let data = {
                            qbo_tax_code_ref: ref,
                            tax_ids: selectedItems.map(s => s.value),
                        };
                        if (existingData) {
                            data = {...existingData, ...data};
                        }
                        // console.log(data, 'data');
                        save(data);
                    }}
                />
            </>
        );
    };

    const currentMappings = qboTaxCodes.map((tax) => {
        const ref = tax.ref;
        const existingData = items.filter(i => i.qbo_tax_code_ref == ref)?.[0];
        const key = existingData ? existingData.qbo_tax_id : tax;
        return (
            <Row key={'qbo-tax-qbo-mapping-' + ref}>
                <Col sm={6}>
                    <Input value={tax.name} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{ paddingLeft: '1rem' }}>
                    {getSelect(ref, existingData)}
                </Col>

            </Row>
        );
    });
    return (
        <>
            {currentMappings}
        </>
    );
}
