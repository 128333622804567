import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, addItem, updateItem } from '../../../redux/companyQboFieldMappings';

export default function useQuickbookFieldMapping() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyQboFieldMappings);
    const items = selectors.selectAll(reduxState);

    const save = useCallback((item) => {
        const updateCall = async () => {
            console.log('save qbo field mapping item', item);
            let response = null;
            if (item.qbo_field_mapping_id) {
                response = await dispatch(updateItem({ id: item.qbo_field_mapping_id, ...item }));
            } else {
                response = await dispatch(addItem({ ...item }));
            }
            return response;
        };
        return updateCall();
    }, [dispatch]);

    return [
        items,
        save,
    ];
};
