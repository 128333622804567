import { colors, IconButton, Link } from '@commonsku/styles';
import React from 'react';

interface AdminTabbedCommissionPaywallProps {
    paywallCtaUrl: string
}

const AdminTabbedCommissionPaywall = ({
    paywallCtaUrl
}: AdminTabbedCommissionPaywallProps) => {
    return <div style={{ flexGrow: 1, margin: '60px 16px 20px', height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
        <div style={{background: colors.teal[20], color: colors.neutrals[100], padding: 32, gap: '2rem', display: 'flex', alignItems: 'center', marginBottom: '4rem' }}>
            <div style={{flex:'1 0 0'}}>
            <h4 style={{marginBottom: 0}}>Upgrade to Advanced to Unlock Commissions Management</h4>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '1rem'}}>
                <Link target="_blank" as={'a'} href={paywallCtaUrl}><IconButton size="large" cta>Contact Us</IconButton></Link>
            </div>
        </div>
        <div style={{display:'flex', alignSelf:'stretch',gap:'2rem', alignItems: 'flex-start'}}>
            <div style={{display:'flex', paddingLeft: '2rem', alignItems: 'flex-start', gap: '4rem', alignSelf: 'stretch', color: colors.neutrals[100]}}>
                <p style={{flex: '1 0 0', alignSelf: 'stretch', fontSize: '1.3rem'}}>Streamline your team's commission payments with commonsku. By unlocking access to the Commission Management feature with the Advanced package you can set individual commission rates, set rates by project role, set commission limits and multipliers, and more!</p>
                <div style={{display: 'flex', height: '300px', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={'../../../images/paywalls/cloverconnect-2.svg'} style={{width:'300px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 16}}/>
                </div>
            </div>
        </div>
    </div>
};

export default AdminTabbedCommissionPaywall;
