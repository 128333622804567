import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, LabeledCheckbox, IconButton } from '@commonsku/styles';
import AddButton from '../misc/AddButton';

export default function CompanyTax({ item, saveItem, deleteItem }) {
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        description: '',
        label: '',
        tax_id: '',
        percent: '',
        tax_exempt: 0,
    }), []);

    const [form, setForm] = useState(item || defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['label', 'percent']) {
            if (data[field].toString().trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            } else if (field === 'percent') {
                const taxRate = Number(data[field]);
                if (isNaN(taxRate) || taxRate > 100 || taxRate < 0) {
                    setFormErrors((errors) => ({ ...errors, [field]: 'Rate must be between 0 and 100' }));
                    errorCount++;
                }
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        };
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <Input
                    style={{ marginBottom: '0' }}
                    disabled={item?.editable == 0 ? 'disabled' : null}
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <>
            {getTextInput('label', 2)}
            {getTextInput('description', 6)}
            {getTextInput('percent', 2)}
            <Col padded sm={1} className='centered-checkbox'>
                <LabeledCheckbox checked={+form['tax_exempt'] === 1} onChange={(e) => {
                    if (+item?.editable === 0) return;

                    setFormFieldValue({
                        target: {
                            name: 'tax_exempt',
                            value: +form['tax_exempt'] === 1 ? 0 : 1,
                        }
                    });
                    // IMPORTANT: for checkbox, you have to directly call the redux method
                    // DON'T use saveForm callback
                    // OTHERWISE, it will always have the wrong value because the callback is cached by react
                    if (item) {
                        saveItem({ ...form, tax_exempt: +form['tax_exempt'] === 1 ? 0 : 1 });
                    }

                }} />
            </Col>

            {!item ?
                (<Col padded sm={1} style={{ textAlign: 'left' }} >
                    <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                </Col>)
                : null}
        </>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
}
