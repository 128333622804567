import React, { useState, useCallback, forwardRef, useEffect } from 'react';

import { ArrowIcon, Col, IconButton, Row } from '@commonsku/styles';
import AdminSettingTopTabs from './AdminSettingTopTabs';
import AdminSettings from './AdminSettings';
import AdminMySettings from './AdminMySettings';
import AdminBilling from './AdminBilling';
import AdminLicenses from './AdminLicenses';
import { useIdentity } from '../../hooks';
import { getIdentityUtils } from '../../utils';

const AdminSettingsApp = () => {
    const [activeTab, setActiveTab] = useState('settings');
    const identity = useIdentity();
    const { hasCapabilities } = getIdentityUtils(identity);
    const canEdit = hasCapabilities(['MODIFY-SETTINGS']);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const initTab = query.get('tab');
        if (initTab) {
            setActiveTab(initTab);
        }
    }, []);

    const modules = {
        'settings': AdminSettings,
        'my-settings': AdminMySettings,
        'billing': AdminBilling,
        'licenses': AdminLicenses,
    };
    let Module = modules[activeTab];
    return (
        <>
            <Row>
                <Col sm={12} style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexGrow: 1 }}>
                        <AdminSettingTopTabs
                            activeTab={activeTab}
                            samePageTabs={['my-settings', 'settings', 'billing', 'licenses']}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </Col>
            </Row>
            <Module />
        </>
    );
};

export default AdminSettingsApp;
