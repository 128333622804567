import React from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/AccountStatus';
import useSupplierStatus from './hooks/useSupplierStatus';
import TopInfo from './misc/Info';

export default function AdminSupplierStatus() {
    const [items, loading, save, remove] = useSupplierStatus();

    const list = items.map((item, index) => {
        return (
            <div key={item.account_status_id} data-testid={`row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Create custom statutes to help segment your suppliers on your Suppliers tab.' link='https://help.commonsku.com/knowledge/supplier-details#status' />
            {list}
            {add}
        </>
    );
}
