import React, { useState, useCallback, useEffect } from 'react';

import { Col, Row, Box, Button, Popup, GearIcon, Loading, HistoryIcon } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import ShopsFeesView from '../ShopsFeesView';

import { oauth, getIdentityUtils } from '../../utils';
import { window } from '../../global';
import { get } from 'lodash';
import AdminBillingPaymentSummary from './AdminBillingPaymentSummary';
import AdminBillingShopFeeSummary from './AdminBillingShopFeeSummary';
import AdminBillingPOFeeSummary from './AdminBillingPOFeeSummary';
import AdminBillingPOFeeDetails from './AdminBillingPOFeeDetails';
import ContactAdmin from './ContactAdmin';
import LicenceInfo from './LicenceInfo';
import PlanInfo from './PlanInfo';
import '../../../scss/css/admin-settings.css';
import AdminBillingRenewalSummary from './AdminBillingRenewalSummary';
import AdminBillingRenewalDetails from './AdminBillingRenewalDetails';
import withReducers from '../../store/withReducers';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';

const createBillingPortal = (type) => {
    return () => {
        return oauth('POST', 'stripe', { action: 'create-billing-portal', type })
            .then(({ json }) => {
                window.open(get(json, 'session.url'));
            });
    };
};

const changePaymentPortal = createBillingPortal('CHANGE_PAYMENT_METHOD_PORTAL');
const billingHistoryPortal = createBillingPortal('BILLING_HISTORY_PORTAL');
const legacyHistoryPortal = createBillingPortal('LEGACY_HISTORY_PORTAL');

const AdminBilling = () => {
    const identity = useIdentity();
    const [selectedModule, setSelectedModule] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [state, setState] = useState({
        quantity: 0, activeUsers: 0, hasLegacyAccount: false
    });
    const [data, setData] = useState({});
    const { hasCapabilities, isTenant } = getIdentityUtils(identity);
    const canEdit = hasCapabilities(['MODIFY-SETTINGS']);
    const hasPOFees = hasCapabilities(['FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS'], true);
    const isFull = isTenant() && hasCapabilities('FEATURE-FULL');
    const [ { data: company } ] = useCompanyGeneralData();

    useEffect(() => {
        setLoading(true);
        oauth('GET', 'stripe', {}).then(
            ({ json }) => {
                const { stripe_info, active_users } = json || {};
                setState({
                    quantity: get(stripe_info, 'stripe_subscription_item.quantity', 0),
                    activeUsers: active_users || 0,
                    hasLegacyAccount: !!stripe_info.stripe_has_legacy_account,
                });
                setData(stripe_info);
                setLoading(false);
            },
            () => setError('No payment information found.')
        );
    }, []);

    const moduleMap = {
        'paymentMethod': {
            'component': null,
            'title': 'Payment Method',
            'onclick': changePaymentPortal,
            'summary': AdminBillingPaymentSummary,
            'show': true,
        },
        'shopFees': {
            'component': ShopsFeesView,
            'title': 'Shop Fees',
            'summary': AdminBillingShopFeeSummary,
            'show': 'SUPPLIER' !== identity.company_type,
        },
        'poFees': {
            'component': AdminBillingPOFeeDetails,
            'title': 'Purchase Order Fees',
            'summary': AdminBillingPOFeeSummary,
            'show': hasPOFees,
	    },
        'history': {
            'component': null,
            'title': 'Billing History',
	        'onclick': billingHistoryPortal,
            'content': 'Click to view your billing history',
            'icon': <HistoryIcon size='large' mr={16} color='#DAE9EE' />,
            'show': true,
        },
        'contractRenewal': {
            'component': !!company.churning ? null : AdminBillingRenewalDetails,
            'title': 'Renewal',
            'summary': AdminBillingRenewalSummary,
            'show': true,
            'noHeader': true,
            'popupStyle': { maxWidth: '50rem', height: 'auto', maxHeight: 'initial' },
            'defaultCursor': !!company.churning,
	    },
        'legacyHistory': {
            'component': null, 'title': 'Billing History (Before July 2021)', 'onclick': legacyHistoryPortal,
            'content': 'Click to view your billing history before commonsku\'s July 2021 Stripe account update.',
            'icon': <HistoryIcon size='large' mr={16} color='#DAE9EE' />,
            'show': state.hasLegacyAccount,
        },
    };

    if (!state.hasLegacyAccount) {
        delete moduleMap['legacyHistory'];
    }

    const onCloseModule = useCallback(() => {
        setSelectedModule(null);
    }, []);

    if (loading) return <Loading />;
    if (error !== '') return <p>{error}</p>;

    let popup = null;
    if (selectedModule) {
        const ModuleContainer = moduleMap[selectedModule]['component'];
        const popupStyle = moduleMap[selectedModule]['popupStyle'] ?? {};

        if (ModuleContainer) {
            popup = (
                <Popup
                    className={moduleMap[selectedModule]['isTabbed'] ? 'tabbed' : 'non-tabbed'}
                    style={{
                        padding: moduleMap[selectedModule]['isTabbed'] ? 0 : 20,
                        maxWidth: '1600px',
                        width: 'calc(100vw - 100px)',
                        height: 'calc(100vh - 100px)',
                        ...popupStyle
                    }}
                    noHeader={!!moduleMap[selectedModule]['noHeader']}
                    header={moduleMap[selectedModule]['noHeader'] ? null :
                        <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                            <h2 style={{ margin: '0.2em' }}>{moduleMap[selectedModule]['title']}</h2>
                            <div>
                                <Button onClick={onCloseModule}>Done</Button>
                            </div>
                        </Row>
                    }>
                    <ModuleContainer identity={identity} inPopup={true} onClose={onCloseModule} />
                </Popup>
            );
        }
    }

    const list = Object.keys(moduleMap).filter(
      moduleKey => moduleMap[moduleKey].show
    ).map((moduleKey) => {
        const SummaryContainer = moduleMap[moduleKey]['summary'] ?? null;
        const onclickCallback = moduleMap[moduleKey]['onclick'] ?? null;
        const height = moduleMap[moduleKey]['height'] ?? null;
        const content = moduleMap[moduleKey]['content'] ?? null;
        const icon = moduleMap[moduleKey]['icon'] ?? null;
        const defaultCursor = moduleMap[moduleKey]['defaultCursor'] ?? null;

        return (
            <Col key={moduleKey} lg={6} sm={12}>
                <Box style={{ padding: '15px', margin: '15px', cursor: canEdit && !defaultCursor ? 'pointer': 'default' }} onClick={(e) => {
                    if (!canEdit) return;
                    if (onclickCallback) {
                        onclickCallback();
                    } else {
                        setSelectedModule(moduleKey);
                    }
                }}>
                    <div style={{ height: height || '80px', overflow: 'hidden' }}>
                        {SummaryContainer ?
                            <SummaryContainer title={moduleMap[moduleKey]['title']} data={data} canEdit={canEdit} /> :
                            <div style={{ display: 'flex' }}>
                                <div>{icon ? icon : <GearIcon size='large' mr={16} color='#DAE9EE' />}</div>
                                <div style={{ flexGrow: 1 }}>
                                    <h5>{moduleMap[moduleKey]['title']}</h5>
                                    {content ? <div className='tile-summary-content'>{content}</div> : null}
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </Col>
        );
    });

    return (
        <>
            {canEdit ? ('SUPPLIER' === identity.company_type ? <PlanInfo feature_name={identity.features} /> : (isFull ? <LicenceInfo data={state} /> : null)) : <ContactAdmin message="You don't have permission to edit Billing Settings. Please contact one of your team's admins to make changes:" />}
            <Row>
                {list}
            </Row>
            {popup}
        </>
    );
};

export default withReducers(AdminBilling, {
    companyGeneralData: companyGeneralDataReducer,
});
