import { useEffect, useCallback } from 'react';
import { getData, updateData } from '../../../redux/userGeneralData';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useUserGeneralData() {
    const dispatch = useDispatch();
    const identity = useSelector(state => state.identity);
    const data = useSelector(state => state.userGeneralData);
    const loading = data.loading;

    const reloadData = useCallback(async () => {
        if (!data.user_email_signature) {
            await dispatch(getData(identity.user_id));
        }
    }, [data.user_email_signature, dispatch, identity.user_id]);

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    const save = useCallback((data) => {
        const updateCall = async () => {
            const result = await dispatch(updateData(identity.user_id, data));
            if (result === true) {
                toast.success("Saved successfully");
            } else {
                toast.error(result);
            }
            return result;
        };

        return updateCall();
    }, [dispatch, identity.user_id]);

    return [
        data,
        loading,
        save,
    ];
};
