import React, { useState, useCallback} from 'react';
import { Button, Input, Col, IconButton } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

export default function AccountStatus({ item, saveItem, deleteItem }) {
    const [name, setName] = useState(item?.account_status_name || '');
    const [showForm, setShowForm] = useState(item ? true : false);
    const [error, setError] = useState('');
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const saveForm = useCallback(() => {
        if (name.trim() === '') {
            setError('Required');
            return;
        } else {
            setError('');
        }

        if (!item) {
            setShowForm(false);
            setName('');
        }
        saveItem({ ...item, account_status_name: name });
    }, [item, name, saveItem]);

    let form = (
        <>
            <Col sm={11}>
                <Input name="name" value={name} onChange={e => setName(e.target.value)} onBlur={(e) => {
                    if (!item) return;
                    saveForm();
                }} />
                {error !== '' ? <span style={{ color: 'red' }}>Required</span> : ''}
            </Col>
            <Col sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                {
                    item ? <IconButton preset='delete' onClick={() => {
                        setPopupOpen(true);
                        setDeleteItem(item);
                    }} /> : <Button size="small" onClick={() => { saveForm(); }}>Add</Button>
                }

            </Col>
            {deletePopup}
        </>
    );

    if (!showForm) {
        form = (
            <Col sm={12} style={{ textAlign: 'center' }}>
                <Button className='add-button' onClick={() => {
                    setShowForm(true);
                }}>
                    <AddButton />
                </Button>
            </Col>
        );
    }

    return form;
}
