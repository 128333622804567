import React from 'react';
import { ReceiptLongIcon } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { parseMysqlDate, formatMoney, getIdentityUtils } from '../../utils';
import useBillingPeriods from './hooks/useBillingPeriods';

export default function AdminBillingPOFeeSummary({ title }) {
    const [billingPeriods,] = useBillingPeriods('CLOSED');
    let summary = 'No purchase order fees';
    if (billingPeriods.length > 0) {
        const current = billingPeriods.sort((a, b) => b.billing_period_end.localeCompare(a.billing_period_end))[0];
        summary = `Latest fee from ${current.billing_period_name} for $${formatMoney(current.po_fees)}`;
    }

    return (
        <div style={{ display: 'flex' }}>
            <div><ReceiptLongIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <p className='tile-summary-content'>{summary}</p>
            </div>
        </div>
    );
}
