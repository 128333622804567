import React, { useCallback, useState } from 'react';
import { oauth } from '../../utils';
import { PopupTitleProps, PopupViewProps, TagInfo } from './types';
import { AlertNotification, ArrowIcon, Button, Col, Loading, Row, Text, colors } from '@commonsku/styles';
import { TagLabel } from './TagLabel';
import { pluralize } from './utils';
import './MergeTagsView.css';

const MERGE_TAGS_ERROR = "Unable to merge tags.";

export const MergeTagsTitle = ({ tagCount, onBack }: PopupTitleProps) => (
    <Row className="delete-tags-title">
        <ArrowIcon
            size="large"
            direction="left"
            onClick={onBack}
            pointer
        />
        <span>
            Merge {tagCount} Tags
        </span>
    </Row>
);

export const MergeTagsView = ({
    selectedTags: tagsToMerge,
    onNewTags,
    onRemoveTags,
    onUpdateView,
}: PopupViewProps) => {
    const [isMerging, setIsMerging] = useState(false);
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState<TagInfo>(tagsToMerge[0]);

    const handleMerge = useCallback(() => {
        const mergeTags = async (tag_id: string, tags: TagInfo[]) => {
            try {
                await oauth('POST', 'tagged-resource', {
                    action: 'merge',
                    tag_id: tag_id,
                    tags_to_merge: tags.map(tag => tag.id),
                });

                onRemoveTags(tags);

                const mergedTag: TagInfo = {
                    ...selected,
                    projects: sumTaggedResources('projects'),
                    clients: sumTaggedResources('clients'),
                    contacts: sumTaggedResources('contacts'),
                    suppliers: sumTaggedResources('suppliers'),
                    products: sumTaggedResources('products'),
                };

                onNewTags([mergedTag]);
                onUpdateView('table');
            } catch (e) {
                setError(true);
            } finally {
                setIsMerging(false);
            }
        }

        setIsMerging(true);
        mergeTags(selected.id, tagsToMerge);
    }, [selected, tagsToMerge]);

    const sumTaggedResources = useCallback((
        resourceType: keyof { 
            [K in keyof TagInfo as TagInfo[K] extends number ? K : never]: any 
        }
    ) =>
        tagsToMerge.reduce((total, tag) => total + tag[resourceType], 0)
    , [tagsToMerge]);

    return (
        <div className="merge-tags">
            {isMerging &&
                <div className="merging-overlay">
                    <Row className="merging-indicator">
                        <Col>
                            <Loading />
                        </Col>
                        <Col>
                            <Text style={{ color: colors.teal['65'] }}>
                                Merging...
                            </Text>
                        </Col>
                    </Row>
                </div>
            }
            <Row className="merge-tags-alert">
                <AlertNotification alertType="neutral">
                    <div>
                        <b>Select which tag you want to keep.</b>
                        {' '}The other {' ' + pluralize(tagsToMerge.length - 1, 'tag', (n) => `${n} tags`)} will be replaced with the one you select.
                        This will affect
                        {' ' + pluralize(sumTaggedResources('projects'), '1 project', (n) => `${n} projects`)},
                        {' ' + pluralize(sumTaggedResources('clients'), '1 client', (n) => `${n} clients`)},
                        {' ' + pluralize(sumTaggedResources('contacts'), '1 contact', (n) => `${n} contacts`)},
                        {' ' + pluralize(sumTaggedResources('suppliers'), '1 supplier', (n) => `${n} suppliers`)},
                        and {pluralize(sumTaggedResources('products'), '1 product', (n) => `${n} products`)}.
                        <br /><br />
                        You cannot undo this action.
                    </div>
                </AlertNotification>
            </Row>
            {error &&
                <Row className="merge-tags-alert">
                    <AlertNotification alertType="error">
                        {MERGE_TAGS_ERROR}
                    </AlertNotification>
                </Row>
            }
            <Row className="tag-container">
                {tagsToMerge.map(tag => (
                    <TagLabel
                        key={tag.id}
                        tag={tag}
                        selectable
                        selected={selected === tag}
                        onSelect={setSelected}
                    />
                ))}
            </Row>
            <Row className="merge-tags-actions" justify="flex-end">
                <Button 
                    size="medium"
                    secondary
                    onClick={() => onUpdateView('table')}
                >
                    Cancel
                </Button>
                <Button
                    size="medium"
                    onClick={handleMerge}
                >
                    Merge to "{selected.label}"
                </Button>
            </Row>
        </div>
    );
}
