import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, Row, LabeledSelect, IconButton, HandleIcon } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

const CompanyExchangeRate = ({
    currencies, item, saveItem, deleteItem
}) => {
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        from_currency: '',
        to_currency: '',
        rate: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);

    const [formErrors, setFormErrors] = useState(defaultValues);
    const currencyOptions = currencies?.map(c => ({ label: c.currency_id, value: c.currency_id }));
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['from_currency', 'to_currency', 'rate']) {
            if (data[field].toString().trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <Input
                    className='no-margin-bottom'
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const getSelect = (field, col = 4) => {
        if (item) return <Col padded sm={col} style={{ paddingTop: '12px' }}>{form[field]}</Col>;

        return (
            <Col padded sm={col}>
                <LabeledSelect
                    isDisabled={item ? true : false}
                    label=""
                    name="from_currency"
                    value={currencyOptions?.find(c => c.value === form[field])}
                    options={currencyOptions}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: field, value: selected.value } });
                    }}
                />
                {formErrors[field] !== '' ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <div style={{ width: '100%' }}>
            <Row>
                {getSelect('from_currency', 2)}
                {getSelect('to_currency', 2)}
                {getTextInput('rate', 7)}

                {!item ?
                    (<Col padded sm={1} style={{ textAlign: 'left' }} >
                        <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                    </Col>)
                    : null}

                {item ?
                    <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                        <IconButton preset='delete' onClick={() => {
                            setPopupOpen(true);
                            setDeleteItem(item);
                        }} />
                    </Col>
                    : <Col sm={1}></Col>}
                {deletePopup}
            </Row>
        </div>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
};

export default CompanyExchangeRate;
