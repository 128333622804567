import React from 'react';
import { Row, Col, Button, LabeledRadioInButton, Loading } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import ErrorBoundary from '../../components/ErrorBoundary';
import AdminStripe from './AdminStripe';
import AdminCardConnect from './AdminCardConnect';

export default function CreditCardIntegration() {
    const [{ data }, loading , , saveExtra] = useCompanyGeneralData();
    const software = data?.companyData?.credit_card_software;

    const handleChangeExtraData = (field, value) => {
        saveExtra({
            [field]: value
        });
    };

    if (loading !== 'idle') return <Loading />;

    return (
        <ErrorBoundary>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <LabeledRadioInButton flexGrow label="Clover Connect" checked={software === 'CARDCONNECT'} onChange={(e) => { handleChangeExtraData('credit_card_software', 'CARDCONNECT'); }} />
                <LabeledRadioInButton flexGrow label="Stripe" checked={software === 'STRIPE'} onChange={(e) => { handleChangeExtraData('credit_card_software', 'STRIPE'); }} />
                <LabeledRadioInButton flexGrow label="None" checked={!software} onChange={(e) => { handleChangeExtraData('credit_card_software', ''); }} />
            </div>

            <div style={{ padding: '1em 0' }}>
                {software === 'CARDCONNECT'
                    ? <AdminCardConnect />
                    : software === 'STRIPE'
                        ? <AdminStripe />
                        : null}
            </div>
        </ErrorBoundary>
    );
}
