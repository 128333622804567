import React, { useEffect, useState } from 'react';
import { Box, Col, Button, InfoIcon } from '@commonsku/styles';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyTax';
import useCompanyTax from './hooks/useCompanyTax';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';
import AdminCompanyTaxNexus from './AdminCompanyTaxNexus';
import { rebuildTooltip } from '../helpers';

export default function AdminTax() {
    const [items, loading, save, remove] = useCompanyTax();
    const [data, , , saveExtra] = useCompanyGeneralData();
    const [isZip2Tax, setIsZip2Tax] = useState(false);

    useEffect(() => {
        setIsZip2Tax(data.data.companyData?.zip2tax == '1');
        rebuildTooltip();
    }, [data.data.companyData?.zip2tax]);

    const editableList = items.filter(i => +i.editable === 1).map((item, index) => {
        return (
            <div key={item.tax_id} data-testid={`editable-row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>

        );
    });

    const nonEditableList = items.filter(i => +i.editable === 0).map((item, index) => {
        return (
            <div key={item.tax_id} data-testid={`non-editable-row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message={isZip2Tax ? "If you are a US distributor using QuickBooks Online's automated sales tax tool, select the state(s) in your tax nexus, and Zip2Tax will automatically apply tax rates to your sales forms based on the ship-to address." : "Create tax codes to apply to your sales and purchase forms. Once you've created your tax codes, you can add a client or supplier's default tax directly to their page."} link={isZip2Tax ? 'https://help.commonsku.com/knowledge/zip2tax-1' : 'https://help.commonsku.com/knowledge/tax-codes'} />
            <Row>
                <Col padded sm={6}>
                    <Button secondary={!isZip2Tax} className={!isZip2Tax ? 'white-button' : 'lighter-button'} style={{ width: '100%' }} onClick={(e) => { saveExtra({ zip2tax: 0 }); }}>
                        Manually enter tax codes
                    </Button>
                </Col>
                <Col padded sm={6}>
                    <Button secondary={isZip2Tax} className={isZip2Tax ? 'white-button' : 'lighter-button'} style={{ width: '100%' }} onClick={(e) => { saveExtra({ zip2tax: 1 }); }}>
                        Zip2Tax
                    </Button>
                </Col>
            </Row>
            {isZip2Tax ? <AdminCompanyTaxNexus /> :
                (<>
                    <Row>
                        <Col padded sm={2}>
                            <b>Label</b> <InfoIcon altText='' style={{ verticalAlign: 'middle' }} data-padding='8px 8px' data-html={true}
                                data-tip='<div style="padding: 10px; max-width: 300px;">The tax label identifies tax rates on your orders and should be an easily identifiable shorthand. For example, E, GST, or WA SEA.</div>' width='16px' />
                        </Col>
                        <Col padded sm={6}>
                            <b>Description</b> <InfoIcon altText='' style={{ verticalAlign: 'middle' }} data-padding='8px 8px' data-html={true}
                                data-tip='<div style="padding: 10px; max-width: 300px;">The tax description is client-facing and describes what the tax rate includes. For example, Exempt, Government Sales Tax, or Seattle Sales Tax.</div>' width='16px' />
                        </Col>
                        <Col padded sm={2}>
                            <b>Rate</b> <InfoIcon altText='' style={{ verticalAlign: 'middle' }} data-padding='8px 8px' data-html={true}
                                data-tip='<div style="padding: 10px; max-width: 300px;">The tax rate should be a percentage (0-100).</div>' width='16px' />
                        </Col>
                        <Col padded sm={2}>
                            <b>Tax Exempt</b>
                        </Col>
                    </Row>
                    {nonEditableList}
                    {editableList}
                    {add}
                </>)}
            <ReactTooltip />
        </>
    );
}
