import React, { useCallback, useState } from 'react';
import { Button, LabeledInput, Col, LabeledSelect, DefaultStar, IconButton } from '@commonsku/styles';
import { countries, states, isValidPostal } from '../../../geo';
import _ from 'lodash';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

export default function CompanyAddress({ defaultAddressId, item, saveItem, deleteItem, saveCompanyData }) {
    const [showForm, setShowForm] = useState(false);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const defaultValues = {
        address_name: '',
        address_company: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        address_line_4: '',
        address_city: '',
        address_country: '',
        address_state: '',
        address_postal: '',
        parent_type: '',
        parent_id: '',
    };

    const [form, setForm] = useState(item || defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    const country_options = countries.map(c => ({ label: c, value: c }));
    const base_states = _.get(states, form['address_country'], []);
    const state_options = [{ value: '', label: '' }].concat(
        base_states.map(s => ({ label: s.name, value: s.name }))
    );

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['address_line_1', 'address_country', 'address_city', 'address_postal']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (data['address_postal'] && !isValidPostal(data['address_postal'])) {
            setFormErrors((errors) => ({ ...errors, address_postal: 'Invalid zip/postal code' }));
            errorCount++;
        }

        if (['Canada', 'United States', 'Australia'].indexOf(data['address_country']) !== -1 && !data['address_state']) {
            setFormErrors((errors) => ({ ...errors, address_state: 'Required' }));
            errorCount++;
        }

        if (errorCount > 0) return false;

        return true;
    }, []);

    const getTextInput = (field, label = null, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <LabeledInput label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const stateLabel = `State${['Canada', 'United States', 'Australia'].indexOf(form['address_country']) !== -1 ? ' *' : ''}`;
    let state = (
        <LabeledSelect
            label={stateLabel}
            name="address_state"
            value={{ label: form['address_state'], value: form['address_state'] }}
            options={state_options}
            onChange={(item) => setFormFieldValue({ target: { name: 'address_state', value: item.value } })}
        />
    );
    if (['Canada', 'United States', 'Australia'].indexOf(form['address_country']) === -1) {
        state = (
            <>
                <LabeledInput className='align-with-select' label='State' name='address_state' value={form['address_state']} onChange={setFormFieldValue} />
            </>
        );
    }

    const formFields = (
        <>
            {getTextInput('address_name', 'Address Name', 6)}
            {getTextInput('address_company', 'Address Company', 6)}
            {getTextInput('address_line_1', 'Address Line 1 *', 6)}
            {getTextInput('address_line_2', 'Address Line 2', 6)}
            {getTextInput('address_line_3', 'Address Line 3', 6)}
            {getTextInput('address_line_4', 'Address Line 4', 6)}
            {getTextInput('address_city', 'City *', 6)}
            {getTextInput('address_postal', 'Zip/Postal Code *')}

            <Col padded sm={6}>
                <LabeledSelect
                    label="Country *"
                    name="address_country"
                    value={{ label: form['address_country'], value: form['address_country'] }}
                    options={country_options}
                    onChange={(item) => {
                        setFormFieldValue({ target: { name: 'address_country', value: item.value } });
                        setFormFieldValue({ target: { name: 'address_state', value: '' } });
                    }}
                />
                {formErrors['address_country'] ? <span style={{ color: 'red' }}>{formErrors['address_country']}</span> : ''}
            </Col>

            <Col padded sm={6}>
                {state}
                {formErrors['address_state'] ? <span style={{ color: 'red' }}>{formErrors['address_state']}</span> : ''}
            </Col>

            <Col padded sm={6} style={{ margin: '1rem 0' }}>
                <Button size='small' onClick={() => {
                    if (!validateForm(form)) {
                        return;
                    }
                    if (!item) setForm(defaultValues);
                    saveItem({ ...item, ...form });
                    setShowForm(false);
                }}>{item ? 'Save' : 'Add'}</Button>
                <Button secondary style={{ marginLeft: '1rem' }} size='small' onClick={() => {
                    setForm(item || defaultValues);
                    setShowForm(false);
                }}>Cancel</Button>
                {item && item.parent_type != 'TENANT-BILLING' && item.parent_type != 'TENANT-SHIPPING' && item.address_id !== defaultAddressId ?
                    <IconButton size='small' ml={16} preset='delete' onClick={() => {
                        setDeleteItem(item);
                        setPopupOpen(true);
                    }} />
                    : null}
            </Col>
            {deletePopup}
        </>
    );

    let viewOnly = (
        <Col sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => {
                setShowForm(true);
            }}>
                <AddButton />
            </Button>
        </Col>
    );

    if (item) {
        viewOnly = (
            <>
                <Col sm={3} style={{ fontSize: '1.1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>{item.address_name}</Col>
                <Col sm={7}>
                    <div>{item.address_line_1}</div>
                    <div>{`${item.address_city} ${item.address_state} ${item.address_postal}`}</div>
                    <div>{item.address_country}</div>
                </Col>
                <Col padded sm={1} style={{ display: 'flex', alignItems: 'center' }}>{item.address_id === defaultAddressId ? <DefaultStar forceSelected={true} /> : (
                    <button onClick={(e) => {
                        saveCompanyData({ tenant_default_address_id: item.address_id });
                    }}><DefaultStar /></button>
                )}</Col>
                <Col padded sm={1} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <IconButton preset='edit' onClick={() => {
                            setShowForm(true);
                        }} />
                    </div>
                </Col>
            </>
        );
    }

    return showForm ? formFields : viewOnly;
}
