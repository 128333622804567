import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
commission_margin_id: "aa29c172-22e0-4a87-ab54-588d49629788"
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
margin_min: "90"
multiplier: "1.00"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.commission_margin_id,
    sortComparer: (a, b) => {
        if (parseInt(a.margin_min) < parseInt(b.margin_min)) {
            return -1;
        }

        if (parseInt(a.margin_min) > parseInt(b.margin_min)) {
            return 1;
        }

        return 0;
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('commissionMarginRates', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'commission-margin-rate';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.commission_margin_rates));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.commission_margin_rate, {new: true})));
    dispatch(loaded());
    return res.json.commission_margin_rate;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.commission_margin_id, data);
    dispatch(updated({ id: data.commission_margin_id, changes: data }));
    dispatch(loaded());
    return res.json.commission_margin_rate;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.commission_margin_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.commission_margin_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
