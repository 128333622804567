import React from 'react';
import Form from './form/CompanyGeneralFormNumbers';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import TopInfo from './misc/Info';

export default function AdminCompanyGeneralDataFormNumbers() {
    const [data, loading, save] = useCompanyGeneralData();

    return (
        <>
            <TopInfo message="Project numbers are unique IDs for your sales and purchase forms. Choose where you'd like your project and form numbers to start (it must be higher than the current number), and they'll climb in ascending order as your team creates new projects." link='https://help.commonsku.com/knowledge/project-numbers' />
            <Form data={data.data} loading={loading} save={save} />
        </>
    );
}
