import React, { useRef, useCallback } from 'react';
import { Button, LightIndicator } from '@commonsku/styles';
import { oauth } from '../../utils';
import TopInfo from './misc/Info';

export default function AdminXeroConnect({ reloadData }) {
    const popupRef = useRef(null);

    const doSomething = useCallback(() => {
        reloadData();
    }, [reloadData]);

    const checkPopupWindow = useCallback(() => {
        setTimeout(() => {
            // Detect open window is closed
            const interval = setInterval(() => {
                if (popupRef.current.closed) {
                    console.log('xero popup is closed. do something');
                    doSomething();
                    clearInterval(interval);
                }
            }, 1000);
        }, 3000);
    }, [doSomething]);

    const getXeroConnect = useCallback(() => {
        const openConnectWindow = async () => {
            const res = await oauth('GET', 'oauth2-provider/xero', {});
            // Open window and set up track window close
            const width = window.screen.width;
            const height = window.screen.height;
            const w = width > 800 ? 800 : width;
            const h = height > 800 ? 800 : height;
            const top = (height - h) / 2;
            const left = (width - w) / 2;
            const params = { width: w, height: h, top: top, left: left };
            console.log(params);
            popupRef.current = window.open(
                res.json.authorization_url,
                'OAuth2 Popup',
                Object.keys(params).map((key) => {
                    return key + '=' + params[key];
                }).join(','));
            checkPopupWindow();
        };

        openConnectWindow();

    }, [checkPopupWindow]);

    return (
        <>
            <TopInfo
                message={(
                    <>
                        Click the Connect to Xero button below to authorize commonsku and Xero to connect and
                        share data for the purpose of our integration,
                        following <a href="https://www.xero.com/ca/legal/terms/" target='_blank'>Xero's terms of use</a> and
                        commonsku's <a href="https://commonsku.com/terms" target='_blank'>terms of service</a> and{' '}
                        <a href="https://commonsku.com/privacy" target='_blank'>privacy policy</a>. You can disconnect the integration anytime.
                    </>
                )}
                link="https://help.commonsku.com/knowledge/connect-to-xero"
            >
            </TopInfo>
            <LightIndicator name={'Not connected'} on={false} />
            <Button size="small" onClick={getXeroConnect}>Connect to Xero</Button>
        </>
    );

}
