import React from 'react';
import { Box } from '@commonsku/styles';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyBenefits';
import useCompanyBenefits from './hooks/useCompanyBenefits';
import TopInfo from './misc/Info';

export default function AdminBenefits() {
    const [items, loading, save, remove] = useCompanyBenefits();

    const list = items.map((item) => {
        return (
            <Row key={item.benefit_id}>
                <Form item={item} saveItem={save} deleteItem={remove} />
            </Row>
        );
    });

    return (
        <>
            <TopInfo message='Specify which benefits you offer distributors.' link="https://help.commonsku.com/knowledge/september-2021-benefits-filter" />
            {list}
        </>
    );
}
