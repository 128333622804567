import React, { useEffect, useState } from 'react';
import { Row, Col, InfoIcon, Loading } from '@commonsku/styles';
import Form from './form/CompanyCommissionPeopleRate';
import useCompanyCommissionRate from './hooks/useCompanyCommisionRate';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';
import useTenantUsers from './hooks/useTenantUsers';

export default function AdminCommissionPeopleRate() {
    const [users, saveUser] = useTenantUsers();
    const [rates] = useCompanyCommissionRate();

    let list = (users || []).map((item) => {
        return (
            <Row key={item.user_id}>
                <Form item={item} rates={rates} saveItem={saveUser} />
            </Row>
        );
    });
    if (list.length === 0) {
        list = (
            <Loading />
        );
    }

    const message = <>
        <p>Assign your reps' commission rates based on their role within a project. You can set your reps' commissions to calculate on projects where:</p>
        <ul>
            <li>They are the client rep only</li>
            <li>They are the order rep only</li>
            <li>They are both the client and order rep</li>
        </ul></>;

    return (
        <>
            <TopInfo message={message} link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#apply' />
            <Row>
                <Col padded sm={3}>
                </Col>
                <Col padded sm={3}>
                    <b>Client Rep Only</b>
                </Col>
                <Col padded sm={3}>
                    <b>Order Rep Only</b>
                </Col>
                <Col padded sm={3}>
                    <b>Client and Order Rep</b>
                </Col>
            </Row>
            {list}
            <ReactTooltip />
        </>
    );
}
