import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
csku_field: "client_tenant_account_number"
date_created: "2022-06-13 08:29:27"
mapping_type: "INVOICE"
qbo_field: "QBO_BILLADDR_LINE1"
qbo_field_mapping_id: "10294edd-75b3-4b2a-97f8-0c7803cd7637"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_field_mapping_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboFieldMappings', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const addItem = (data) => async (dispatch, getState) => {
    /*
    qbo_field: QBO_BILLADDR_LINE2
    csku_field: client_name
    mapping_type: INVOICE
    */
    dispatch(loading());
    const res = await oauth('POST', 'qbo-field-mapping', data);
    dispatch(added(Object.assign(res.json.qbo_field_mapping, {new: true})));
    dispatch(loaded());
    return res.json.qbo_field_mapping;
};

export const updateItem = (data) => async (dispatch, getState) => {
    /*
    csku_field: client_tenant_account_number
    */
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-field-mapping/' + data.qbo_field_mapping_id, data);
    dispatch(updated({ id: data.qbo_field_mapping, changes: data }));
    dispatch(loaded());
    return res.json.qbo_field_mapping;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
