import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Col, IconButton, LabeledCheckbox } from '@commonsku/styles';

export default function CompanyBenefits({ item, saveItem, deleteItem }) {
    const [form, setForm] = useState(item);

    useEffect(() => setForm(item), [item]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const saveForm = useCallback(() => {
        saveItem(form);
    }, [form, saveItem]);

    const toggleBenefit = useCallback(() => {
        if (!item.supplier_benefit_id) {
            saveItem(item);
	} else {
            deleteItem(item);
	}
    }, [item, saveItem, deleteItem]);

    const getTextInput = (field, col = 4) => {
        return (
            <Col padded sm={col}>
                <Input style={{ marginBottom: 0 }} name={field} value={form[field] ?? ''} disabled={!item.supplier_benefit_id} onChange={setFormFieldValue} onBlur={saveForm} />
            </Col>
        );
    };

    let formFields = (
        <>
            <Col padded sm={4}>
                <LabeledCheckbox label={item.benefit_label} checked={!!item.supplier_benefit_id} onChange={toggleBenefit} value={item.benefit_id} />
            </Col>
            {getTextInput('benefit_description', 8)}
        </>
    );

    return formFields;
}
