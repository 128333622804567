import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import { getGeneralSlice } from './helpers';

/*
active: "1"
created_by: "d6baac9c-9327-48f9-b51e-f3378a894f30"
date_created: "2011-10-27 00:00:00"
default_supplier_id: "19"
ext_artwork_id: null
ext_decoration_id: null
ext_location_id: null
imprint_description: ""
imprint_front_end_enabled: "1"
imprint_id: "a3b784bc-ffea-11e0-b240-1231392503bd"
imprint_name: "Applique"
imprint_price_description: ""
imprint_price_description_default: ""
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.imprint_id,
    sortComparer: (a, b) => (a.imprint_name + a.imprint_id).localeCompare(b.imprint_name + b.imprint_id)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyImprints', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'imprint';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl, { 'max-results': 1000 });
    dispatch(received(res.json.imprints));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.imprint, { new: true })));
    dispatch(loaded());
    return res.json.imprint;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.imprint_id, data);
    dispatch(updated({ id: data.imprint_id, changes: data }));
    dispatch(loaded());
    return res.json.imprint;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.imprint_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.imprint_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
