import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import Sage from './form/Sage';

export default function AdminSage() {
    const [{ data }, , save] = useCompanyGeneralData();

    return <Sage data={data} save={save}/>;
}
