import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, addItem, updateItem } from '../../../redux/companyQboClasses';

export default function useQuickbookClass() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyQboClasses);
    const items = selectors.selectAll(reduxState);

    const save = useCallback((item) => {
        const updateCall = async () => {
            console.log('save qbo class reference item', item);
            let response = null;
            if (item.qbo_class_id) {
                response = await dispatch(updateItem({ id: item.qbo_class_id, ...item }));
            } else {
                response = await dispatch(addItem({ ...item }));
            }
            return response;
        };
        return updateCall();
    }, [dispatch]);

    return [
        items,
        save,
    ];
};
