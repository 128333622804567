import React from 'react';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import AdminQuickbooksConnect from './AdminQuickbooksConnect';
import AdminQuickbooksSetup from './AdminQuickbooksSetup';

export default function AdminQuickbooks() {
    const [data, loading, save, saveExtra, identity, reloadData, disconnectQuickbook] = useCompanyGeneralData();
    const qboSetup = !!data?.data.companyData.qbo_oauth_date_created;

    return (
        <>
            {qboSetup ? <AdminQuickbooksSetup data={data.data} save={saveExtra} disconnect={disconnectQuickbook} /> : <AdminQuickbooksConnect reloadData={reloadData} />}
        </>
    );
}
