export const getAccountRefByName = (name, qboAccounts) => {
    let ref = 1;
    for (const account of qboAccounts) {
        if (account.Name === name) {
            return ref;
        }
        ref++;
    }
    return '';
};

export const getItemRefByName = (name, qboItems) => {
    let ref = 1;
    for (const item of qboItems) {
        if (item === name) {
            return ref;
        }
        ref++;
    }
    return '';
};

export const getObjectByKey =(key, accounts) => {
    for (const account of accounts) {
        if (account.ref == key) {
            return account;
        }
    }
    return null;
};