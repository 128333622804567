import {
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

const slice = createSlice({
    name: 'userGeneralData',
    initialState: {
        loading: 'idle',
        data: {}
    },
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        setData(state, action) {
            state.data = {...state.data, ...action.payload};
            state.loading = 'idle';
        },
    }
});

export const {
    setData,
    loading,
    loaded
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getData = (id) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', `user/${id}`,  {});
    dispatch(setData(res.json.user));
    return res.json.user;
};

export const updateData = (id, data) => async (dispatch, getState) => {
    dispatch(loading());
    let res = {};
    try {
        res = await oauth('PUT', `user/${id}`, data);
    } catch (err) {
        console.log('Failed to update', err.json.error);
        return err.json.error;
    }
    dispatch(setData(res.json.user));
    return true;
};

export default slice.reducer;
