import { get, map, filter, keys, keyBy, pick, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Button, LightIndicator } from '@commonsku/styles';
import Loading from './Loading';
import { oauth } from '../utils';
import AdminXeroConnect from './admin-new/AdminXeroConnect';
import TopInfo from './admin-new/misc/Info';
import useCompanyTax from './admin-new/hooks/useCompanyTax';

const XeroAccountsTable = () => {
    const [accounts, setAccounts] = useState([]);
    const [lineItemTypes, setLineItemTypes] = useState({});
    useEffect(() => {
        oauth('GET', 'line-item-type', {}).then(({ json }) => {
            setLineItemTypes(keyBy(filter(map(json.line_item_types, 'xero_account')), 'xero_account_id'));
        });

        oauth('POST', 'xero', { action: 'get_accounts' }).then(({ json }) => {
            setAccounts(json.accounts);
        });
    }, []);

    return <table>
        <thead>
            <tr>
                <th>Line item type</th>
                <th>Xero Account Code</th>
            </tr>
        </thead>
        <tbody>
            {map(lineItemTypes, ({ line_item_type_label, xero_account_code }, xero_account_id) => {
                return <tr key={xero_account_id}>
                    <td><input name="line_item_type_label" disabled="disabled" type="text" value={line_item_type_label} /></td>
                    <td className="xero-account-code">
                        <input name="xero_account_id" type="hidden" value={xero_account_id} />
                        <select
                            name="xero_account_code" data-placeholder="Select an Account" value={xero_account_code}
                            onChange={(e) => {
                                oauth('PUT', `xero-account/${xero_account_id}`, {
                                    'xero_account_code': e.target.value,
                                }).then(({ json }) => {
                                    const xero_account = get(json, 'xero_account');
                                    setLineItemTypes({
                                        ...lineItemTypes,
                                        [get(xero_account, 'xero_account_id')]: xero_account,
                                    });
                                });
                            }}
                        >
                            <option />
                            {map(accounts, ({ Code, Name }, j) => {
                                return <option key={j} value={Code}>{Code} - {Name}</option>;
                            })}
                        </select>
                    </td>
                </tr>;
            })}
        </tbody>
    </table>;
};

const XeroTaxesTable = () => {
    const [taxes, setTaxes] = useState({});
    const [taxCodes] = useCompanyTax();
    useEffect(() => {
        Promise.all([
            oauth('POST', 'xero', { action: 'get_tax_rates' }),
            oauth('GET', 'xero-tax', {}),
        ]).then(([taxRatesResponse, taxResponse]) => {
            const tax_rates = filter(
                get(taxRatesResponse, 'json.tax_rates'),
                t => t.Status === 'ACTIVE'
            );
            const xero_taxes = keyBy(get(taxResponse, 'json.xero_taxes'), 'xero_tax_type');
            const taxes = tax_rates.reduce(
                (t, tr) => ({
                    ...t,
                    [tr.TaxType]: {
                       tax_ids: [],
                       xero_tax_type: tr.TaxType,
                       xero_tax_label: tr.Name,
                       ...xero_taxes[tr.TaxType],
                    }
                }),
                {}
            );
            setTaxes(taxes);
        });
    }, []);

    return <table>
        <thead>
            <tr>
                <th>Xero Tax Rate Name</th>
                <th>commonsku Tax Codes</th>
            </tr>
        </thead>
        <tbody>
            {map(taxes, ({ xero_tax_id, xero_tax_type, xero_tax_label, tax_ids }, i) => {
                const options = map(taxCodes, ({ tax_id, label }) => {
                    return { value: tax_id, label };
                });
                return <tr key={i}>
                    <td className="xero-tax">
                        <input name="xero_tax_label" type="text" disabled="disabled" value={xero_tax_label} />
                    </td>
                    <td className="commonsku-tax-codes" style={{ minWidth: 400 }}>
                        <Select
                            isMulti placeholder="Select some commonsku Tax Codes"
                            value={filter(options, ({ value }) => {
                                return tax_ids.indexOf(value) > -1;
                            })}
                            options={options}
                            onChange={(values) => {
                                const data = { xero_tax_type, tax_ids: map(values, 'value') };
                                const promise = xero_tax_id
                                    ? oauth('PUT', `xero-tax/${xero_tax_id}`, data)
                                    : oauth('POST', `xero-tax`, data)
                                    ;
                                promise.then(({ json }) => {
                                    const tax = get(json, 'xero_tax');
                                    setTaxes({
                                        ...taxes,
                                        [get(tax, 'xero_tax_type', '')]: tax,
                                    });
                                });
                            }}
                        />
                    </td>
                </tr>;
            })}
        </tbody>
    </table>;
};

const XeroAdminNew = ({ reloadData }) => {
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        oauth('POST', 'xero', { action: 'get_connections' })
            .then(({ json }) => {
                console.log(json.connections, 'connections');
                setStatus(isEmpty(json.connections) ? 'disconnected' : 'connected');
            })
            ;
    }, []);

    return <div className="expandable">
        {
            status === 'loading' ? <Loading /> : (
                status === 'disconnected' ? (
                    <AdminXeroConnect reloadData={reloadData} />
                ) : (
                    <>
                        <TopInfo
                            message={(
                                <>
                                    Use the dropdowns below to map your commonsku items and taxes to Xero.
                                    Don't see the Xero account you're looking for?
                                    Try disconnecting and reconnecting the integration to pull an up-to-date list of accounts.
                                </>
                            )}
                            link="https://help.commonsku.com/knowledge/connect-to-xero"
                        >
                        </TopInfo>
                        <LightIndicator name={'Connected to Xero'} on={true} />
                        <Button size="small" onClick={() => {
                            oauth('POST', 'xero', { action: 'disconnect' }).then(() => {
                                setStatus('disconnected');
                            });
                        }}>Disconnect from Xero</Button>
                        <XeroAccountsTable />
                        <XeroTaxesTable />
                    </>
                )
            )
        }
    </div>;
};

export default XeroAdminNew;
