import { Button } from '@commonsku/styles';
import React from 'react';
import useConfirmDelete from './hooks/useConfirmDelete';
import StripePaymentMethods from './form/StripePaymentMethods';

export default function AdminStripeSetup({ disconnect, data, save }) {

    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({
        deleteItemCallback: disconnect,
        message: 'Disconnecting will clear your token and you will need to re-authenticate.',
        deleteLabel: 'Disconnect',
        showUndoMessage: false,
    });

    const disconnectStripe = () => {
        setDeleteItem({});
        setPopupOpen(true);
    };

    return (
        <div>
            <p>
                You are successfully connected to Stripe!
                To disconnect your Stripe account, click the Disconnect from Stripe button below
                and <a href="https://support.stripe.com/questions/disconnect-your-stripe-account-from-a-connected-third-party-platform" target="_blank" rel="noreferrer">remove commonsku from your Stripe account's authorized applications</a>.
            </p>

            <Button size='small' onClick={disconnectStripe}>Disconnect from Stripe</Button>
            {deletePopup}
            <p>
                &nbsp;
            </p>
            <StripePaymentMethods data={data.companyData} save={save} />
        </div>

    );
}
