import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

import { oauth } from '../utils';

const adapter = createEntityAdapter({
    selectId: (charge) => charge.charge_id,
    sortComparer: (a, b) => {
        let cmp = a.division_name.localeCompare(b.division_name);
        if (!!cmp) {
            return cmp;
        }
        cmp = a.distributor_name.localeCompare(b.distributor_name);
        if (!!cmp) {
            return cmp;
        }
        return a.po_number.localeCompare(b.po_number);
    }
});

const slice = createSlice({
    name: 'billingCharges',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
    }
});

export const  {
    loading,
    received,
} = slice.actions;

const baseUrl = 'charge';

/**
 * The actions (thunk)
 */
export const getAll = (billing_period_id) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl, { billing_period_id});
    dispatch(received(res.json.charges));
    return res.json;
};

export const selectors = adapter.getSelectors();

export default slice.reducer;

