import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, LabeledInput, Row, Col, LabeledSelect, AlertNotification } from '@commonsku/styles';

export default function UserEsp({ data, save }) {
    const defaultValues = useMemo(() => ({
        esp_account: "",
        esp_default_market: "",
        esp_login: "",
        esp_password: "",
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);
    const default_market_options = [
        { label: 'CAN', value: 'CANALL' },
        { label: 'USA', value: 'USAALL' },
    ];

    useEffect(() => {
        let values = defaultValues;
        values = {
            ...values,
            esp_account: data.esp_account,
            esp_default_market: data.esp_default_market,
            esp_login: data.esp_login,
            esp_password: data.esp_password,
        };
        setForm(values);
    }, [data.esp_account, data.esp_default_market, data.esp_login, data.esp_password, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        let resetValues = data['esp_account'] === '' && data['esp_login'] === '' && data['esp_password'] === '';
        if (!resetValues) {
            for (const field of ['esp_account', 'esp_login', 'esp_password']) {
                if (data[field] === '') {
                    setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                    errorCount++;
                }
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(async (formData) => {
        if (!validateForm(formData)) {
            return;
        }
        const result = await save(formData);
        if (result !== true) {
            setFormErrors((errors) => ({ ...errors, esp_login: result, esp_account: '', esp_password: '' }));
        }
    }, [save, validateForm]);

    const getSelect = ({ label, name, options }) => {
        const value = options.filter(c => c.value === form[name]);
        return (
            <>
                <LabeledSelect
                    label={label}
                    name={name}
                    value={value}
                    options={options}
                    onChange={(item) => {
                        setFormFieldValue({ target: { name: name, value: item.value } });
                    }}
                />
                {formErrors[name] ? <span style={{ color: 'red' }}>{formErrors[name]}</span> : ''}
            </>);
    };

    const getTextInput = (field, label = null, type = 'text') => {
        const hasError = formErrors[field] !== '';
        return (
            <>
                <LabeledInput
                    placeholder='Type Here'
                    style={{ marginBottom: '0' }}
                    error={hasError}
                    type={type}
                    className='align-with-select'
                    label={label || field}
                    name={field}
                    value={form[field]}
                    onChange={setFormFieldValue}
                />
                {hasError ? <AlertNotification alertType="error">{formErrors[field]}</AlertNotification> : ''}
            </>
        );
    };

    const formFields = (
        <Row>
            <Col padded sm={12}>
                {getTextInput('esp_account', 'ASI Number')}
            </Col>
            <Col padded sm={12}>
                {getTextInput('esp_login', 'Username (one license per user)')}
            </Col>
            <Col padded sm={12}>
                {getTextInput('esp_password', 'Password', 'password')}
            </Col>
            <Col padded sm={12}>
                {getSelect({ label: 'Default Market', name: 'esp_default_market', options: default_market_options })}
            </Col>
            <Col padded sm={12}>
                <Button onClick={() => {
                    saveForm(form);
                }}>Save</Button>
            </Col>
        </Row>
    );

    return formFields;
}
