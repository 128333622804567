import { useEffect, useCallback } from 'react';
import { selectors, fetchUserReps } from '../../../redux/users';
import {
    createLoadUserReps,
    createUpdateUser,
  } from '../../../actions';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useTenantUsers() {
    const dispatch = useDispatch();
    const identity = useSelector(s => s.identity);
    const dropdownItems = useSelector(state => state.dropdowns.users) ?? [];
    const entityItems = useSelector(state => state.entities.users);
    const items = dropdownItems.filter(
      i => (
        'client_rep_commission_rate_id' in i &&
        'order_rep_commission_rate_id' in i &&
        'combined_rep_commission_rate_id' in i
      )
    ).map(
      i => ({...i, ...entityItems[i.user_id]})
    );

    useEffect(() => {
        dispatch(createLoadUserReps(identity.company_id, identity.company_type, true));
    }, [dispatch, identity.company_id, identity.company_type]);

    const saveUser = useCallback((user, field, value) => {
        const updateCall = async () => {
            const res = dispatch(createUpdateUser(user.user_id, field, value, user[field]));
            toast.success("Saved successfully");

            // Update dropdown of users

        };

        updateCall();
    }, [dispatch]);

    return [
        items,
        saveUser,
    ];
};
