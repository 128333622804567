import React from 'react';
import { LightIndicator, MailIcon } from '@commonsku/styles';
import useEmailTemplate from './hooks/useEmailTemplate';

export default function AdminEmailTemplateSummary({ title }) {
    const [items, , , ] = useEmailTemplate();
    return (
        <div style={{ display: 'flex' }}>
            <div><MailIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <LightIndicator name={`${items?.length} templates`} on={items?.length > 0 ? true : false} />
            </div>
        </div>
    );
}
