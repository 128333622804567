import React, { useEffect, useState } from 'react';
import {
    AdminTagView,
    DeleteTagsTitle,
    DeleteTagsView,
    MergeTagsTitle,
    MergeTagsView,
    NoTagsTitle,
    NoTagsView,
    PopupTitleProps,
    PopupViewProps,
    TagInfo,
    TagTableTitle,
    TagTableView,
} from '../tag-management';
import { oauth } from '../../utils';
import './AdminCompanyTags.css';

interface ViewComponents {
    Title: (props: PopupTitleProps) => JSX.Element | null
    View: (props: PopupViewProps) => JSX.Element
}

const VIEW_MAP: Record<AdminTagView, ViewComponents> = {
    table: {
        Title: TagTableTitle,
        View: TagTableView,
    },
    delete: {
        Title: DeleteTagsTitle,
        View: DeleteTagsView,
    },
    merge: {
        Title: MergeTagsTitle,
        View: MergeTagsView,
    },
    'no-tags': {
        Title: NoTagsTitle,
        View: NoTagsView,
    },
}

export interface AdminCompanyTagsProps {
    setTitle: (title: JSX.Element) => void;
}

export const AdminCompanyTags = ({ setTitle }: AdminCompanyTagsProps) => {
    const [selectedTags, setSelectedTags] = useState<TagInfo[]>([]);
    const [view, setView] = useState<AdminTagView>('table');
    const [tags, setTags] = useState<TagInfo[]>([]);
    const [error, setError] = useState(false);
    const [fetching, setFetching] = useState(true);

    const ViewComponent = VIEW_MAP[view].View;
    const TitleComponent = VIEW_MAP[view].Title;

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const { json } = await oauth('GET', 'tagged-resource/detailed-summary', {});
                const tags: TagInfo[] = json.tagged_resources.map(tag => ({
                    id: tag.tag_id,
                    label: tag.label,
                    projects: Number(tag.num_projects),
                    products: Number(tag.num_products),
                    clients: Number(tag.num_clients),
                    contacts: Number(tag.num_contacts),
                    suppliers: Number(tag.num_suppliers),
                }));
                setTags(tags);

                if (tags.length === 0) {
                    setView('no-tags');
                }
            } catch (e) {
                setError(true);
            } finally {
                setFetching(false);
            }
        }
        fetchTags();
    }, []);

    useEffect(() => setTitle(
        <TitleComponent
            tagCount={selectedTags.length}
            onBack={() => setView('table')}
        />
    ), [view]);

    const addTags = (newTags: TagInfo[]) => {
        setTags([...newTags, ...tags]);
    }
    
    const removeTags = (tagsToRemove: TagInfo[]) => {
        setTags(tags.filter(tag => !tagsToRemove.includes(tag)));
        setSelectedTags([]);
    }

    return (
        <div className="admin-tags-wrapper">
            <ViewComponent
                tags={tags}
                selectedTags={selectedTags}
                isFetchingTags={fetching}
                fetchingTagsError={error}
                onNewTags={addTags}
                onRemoveTags={removeTags}
                onSelect={setSelectedTags}
                onUpdateView={setView}
            />
        </div>
    );
}
