import React from 'react';
import { ShopIcon } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { parseMysqlDate, formatMoney } from '../../utils';

const getShopsFees = state => Object.values(state.entities.shops_fees);

export default function AdminBillingShopFeeSummary({ title }) {
    const shop_fees = useSelector(getShopsFees);
    let summary = 'No shop fees';
    if (shop_fees.length > 0) {
        summary = `Latest fee from ${parseMysqlDate(shop_fees[0].charged_date)} for ${formatMoney(shop_fees[0].charged_sum)} ${shop_fees[0].charged_currency_converted}`;
    }

    return (
        <div style={{ display: 'flex' }}>
            <div><ShopIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <p className='tile-summary-content'>{summary}</p>
            </div>
        </div>
    );
}
