import React from "react";
import { Text, colors } from "@commonsku/styles";

export default function DashboardPageTitle({ title }) {
  return (
    <Text
      style={{
        fontWeight: "600",
        fontSize: "32px",
        color: colors.neutrals["90"],
        fontFamily: "skufont-regular",
      }}
    >
      {title}
    </Text>
  );
}
