import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
commission_rate: "10.00"
commission_rate_id: "58ab7f92-86bc-4963-800d-9f8b8b975299"
commission_rate_label: "aaa"
commission_source: "MARGIN"
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.commission_rate_id,
    sortComparer: (a, b) => (a.commission_rate_label + a.commission_rate).localeCompare(b.commission_rate_label + b.commission_rate)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('commissionRates', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'commission-rate';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.commission_rates));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.commission_rate, {new: true})));
    dispatch(loaded());
    return res.json.commission_rate;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.commission_rate_id, data);
    dispatch(updated({ id: data.commission_rate_id, changes: data }));
    dispatch(loaded());
    return res.json.commission_rate;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.commission_rate_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.commission_rate_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
