import React from 'react';
import { CreditCardIcon, LightIndicator } from '@commonsku/styles';

export default function AdminBillingPaymentSummary({ title, data, canEdit }) {
    const { last4, exp_month, exp_year } = data?.stripe_default_payment_method?.card || { last4: false, exp_month: 0, exp_year: 0 };

    return (
        <div style={{ display: 'flex' }}>
            <div><CreditCardIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <LightIndicator name={last4 ? (canEdit ? `....${last4} expires ${exp_month}/${exp_year}` : 'configured') : 'No payment info'} on={last4 ? true : false} />
            </div>
        </div>
    );
}
