import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, LabeledInput, Row, Col } from '@commonsku/styles';

export default function CardConnect({ data, save }) {
    const defaultValues = useMemo(() => ({
        cardconnect_merchid: '',
        cardconnect_merchid_repeat: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    useEffect(() => {
        let values = defaultValues;
        if (data.cardconnect_merchid) {
            values = {
                cardconnect_merchid: data.cardconnect_merchid,
                cardconnect_merchid_repeat: data.cardconnect_merchid,
            };
        }
        setForm(values);
    }, [data.cardconnect_merchid, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const eventTarget = e.target;
        setForm((form) => ({ ...form, [eventTarget.name]: eventTarget.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['cardconnect_merchid', 'cardconnect_merchid_repeat']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            } else {
                if (field === 'cardconnect_merchid_repeat') {
                    if (data['cardconnect_merchid'] !== data['cardconnect_merchid_repeat']) {
                        console.log('mismatch2');
                        setFormErrors((errors) => ({ ...errors, [field]: 'Mismatch' }));
                        errorCount++;
                    }
                }
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        save({ ...form }, true);
    }, [form, save, validateForm]);

    const getTextInput = (field, label = null, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <LabeledInput label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const formFields = (
        <>
            <p>
                Use <a href="https://www.commonsku.com/integrations/cloverconnect" target="_blank" rel="noreferrer">Clover Connect</a> to accept payments on your commonsku Shops and invoices. Enter your Clover Connect merchant ID below to link your account to commonsku.
            </p>
            <Row>
                {getTextInput('cardconnect_merchid', 'Merchant ID', 12)}
                {getTextInput('cardconnect_merchid_repeat', 'Confirm Merchant ID', 12)}
                <Col padded col={12}>
                    <Button size='small' onClick={saveForm}>Save</Button>
                </Col>

            </Row>
        </>
    );

    return formFields;
}
