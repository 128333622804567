import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/commissionClientRates';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useCompanyCommissionClientRates() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.commissionClientRates);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            if (item.commission_client_rate_id) {
                await dispatch(updateItem({ id: item.commission_client_rate_id, ...item }));
            } else {
                await dispatch(addItem(item));
            }
            toast.success("Saved successfully");
        };

        updateCall();
    }, [dispatch]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
        };

        deleteCall();
    }, [dispatch]);

    return [
        items,
        loading,
        save,
        remove,
    ];
};
