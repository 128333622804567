import React, { useState } from 'react';
import { TabBar, Tab } from '@commonsku/styles';

import AdminClientStatus from './AdminClientStatus';
import AdminSupplierStatus from './AdminSupplierStatus';
import AdminDistributorStatus from './AdminDistributorStatus';
import { useIdentity } from '../../hooks';


const AdminStatus = () => {
    const identity = useIdentity();
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            label: 'Client Statuses',
            content: <AdminClientStatus />,
            show: 'SUPPLIER' !== identity.company_type,
        },
        {
            label: 'Supplier Statuses',
            content: <AdminSupplierStatus />,
            show: 'SUPPLIER' !== identity.company_type,
        },
        {
            label: 'Distributor Statuses',
            content: <AdminDistributorStatus />,
            show: 'SUPPLIER' === identity.company_type,
        },
    ].filter(t => t.show);
    return (
        <>
            <TabBar padded>
                {tabs.map((t, i) => <Tab key={`tabs_${i}`} selected={activeTab === i} onClick={() => setActiveTab(i)} style={activeTab === i ? { borderBottomColor: '#EB1D6E' } : null}
                >
                    {t.label}
                </Tab>)}
            </TabBar>
            {tabs[activeTab] ? tabs[activeTab].content : null}
        </>
    );

};

export default AdminStatus;
