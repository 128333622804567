import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
date_created: "2022-08-14 18:13:04"
qbo_tax_code_ref: "2"
qbo_tax_id: "b8bb39f6-41ac-4606-b985-8e05f91663cc"
tax_ids: ["4e7ef8b2-2652-4219-b51f-12d1a14e1090", "b260d7ca-fff9-11e0-b240-1231392503bd"]
0: "4e7ef8b2-2652-4219-b51f-12d1a14e1090"
1: "b260d7ca-fff9-11e0-b240-1231392503bd"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_tax_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboTaxes', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'qbo-tax',  {});
    dispatch(received(res.json.qbo_taxes));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'qbo-tax', data);
    dispatch(added(Object.assign(res.json.qbo_tax, {new: true})));
    dispatch(loaded());
    return res.json.qbo_tax;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-tax/' + data.qbo_tax_id, data);
    dispatch(updated({ id: data.qbo_tax_id, changes: data }));
    dispatch(loaded());
    return res.json.qbo_tax;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
