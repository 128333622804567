import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, LabeledSelect } from '@commonsku/styles';
import Form from './form/SupplierShipMethod';
import useCompanyShipMethod from './hooks/useCompanyShipMethod';
import useCompanyDivisions from './hooks/useCompanyDivisions';
import TopInfo from './misc/Info';

export default function AdminSupplierShipMethod() {
    const [divisions,] = useCompanyDivisions();
    const [divisionId, setDivisionId] = useState();
    const [items, loading, save, remove, couriers, onMove, onDrop] = useCompanyShipMethod(divisionId);
    const divisionOptions = useMemo(() => divisions?.map(
        d => ({ value: d.division_id, label: d.division_name })
    ) ?? [], [divisions]);
    const selectedDivision = divisionOptions.find(d => d.value === divisionId);
    useEffect(() => {
        setDivisionId(divisions?.[0]?.division_id);
    }, [divisions]);

    let index = 0;
    const list = items.filter(item => item.tenant_id === divisionId).map((item, itemIndex) => {
        return (
            <div key={item.ship_method_id} data-testid={`row-${itemIndex}`}>
                <Row style={{ borderBottom: '1px solid #ececec', marginBottom: '10px', paddingBottom: '10px' }}>
                    <Form key={item.ship_method_id} index={itemIndex} onMove={onMove} onDrop={onDrop} divisionId={divisionId} couriers={couriers} item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} divisionId={divisionId} couriers={couriers} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Add or update the billing and shipping methods asnd couriers (FedEx, UPS, or Other). You can drag and drop your favorite method to the top of the list to make it your default.' link='https://help.commonsku.com/knowledge/ship-method' />
            <Row>
                <Col padded sm={12}>
                    <LabeledSelect
                        label="Division"
                        name="division_id"
                        value={selectedDivision}
                        options={divisionOptions}
                        onChange={(selected) => setDivisionId(selected.value)}
                    />
                </Col>
            </Row>
            {list}
            {add}
        </>
    );
}
