import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useQuickbookFieldMapping from '../../hooks/useQuickbookFieldMapping';
import { qbo_invoice_field_labels, qbo_invoice_field_options } from '../../../../company_setting_options';

export default function FieldMapping({ qboInvoiceFields }) {
    const [fields, save] = useQuickbookFieldMapping();

    // Well, we have to keep/modify the state here
    // Since it is a weird setup in qbo custom fields mapping
    const [mapped, setMapped] = useState([]);

    // console.log(qboInvoiceFields, 'qboInvoiceFields', qbo_invoice_field_labels);

    useEffect(() => {
        setMapped(qboInvoiceFields);
    }, [qboInvoiceFields]);

    if (qboInvoiceFields.length === 0) return <Loading />;

    const getSelect = (qboField) => {
        const value = qboField.csku_field ? qbo_invoice_field_options.filter(c => c.value === qboField.csku_field)[0] : null;
        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder='Select a commonsku option'
                    label=''
                    options={qbo_invoice_field_options}
                    value={value}
                    onChange={async (item) => {
                        item = item === null ? {label: '', value: ''} : item;
                        let data = {
                            qbo_field: qboField.qbo_field,
                            csku_field: item.value,
                            mapping_type: 'INVOICE',
                        };
                        const savedData = await save({...qboField, ...data});
                        setMapped((old) => {
                            const newFields = [];
                            for (const f of old) {
                                if (f.qbo_field === qboField.qbo_field) {
                                    newFields.push({...f, qbo_field_mapping_id: savedData.qbo_field_mapping_id, csku_field: item.value});
                                } else {
                                    newFields.push(f);
                                }
                            }

                            return newFields;
                        });
                    }}
                />
            </>
        );
    };

    const currentMappings = mapped.map((qboField) => {
        const label = qbo_invoice_field_labels.filter(l => l.value === qboField.qbo_field)[0];
        return (
            <Row key={'qbo-field-mapping-' + qboField.qbo_field}>
                <Col sm={6}>
                    <Input value={label?.label} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{paddingLeft: '1rem'}}>
                    {getSelect(qboField)}
                </Col>

            </Row>
        );
    });

    return (
        <>
            {currentMappings}
        </>
    );
}
