import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, getAll, addItem, updateItem } from '../../../redux/companyQboCurrencies';

export default function useQuickbookCurrency() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyQboCurrencies);
    const items = selectors.selectAll(reduxState);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const save = useCallback((item) => {

        const updateCall = async () => {
            console.log('save qbo currency item', item);
            if (item.qbo_currency_id) {
                await dispatch(updateItem({ id: item.qbo_currency_id, ...item }));
            } else {
                await dispatch(addItem({ ...item }));
            }
        };

        updateCall();
    }, [dispatch]);

    return [
        items,
        save,
    ];
};
