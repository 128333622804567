import React, { forwardRef } from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyTerm';
import useCompanyTerm from './hooks/useCompanyTerm';
import TopInfo from './misc/Info';

export default function AdminCompanyTerm() {
    const [items, loading, save, remove, onMove, onDrop] = useCompanyTerm();
    let index = 0;

    const list = items.map((item, index) => {
        return (
            <div key={item.company_term_id} data-testid={`row-${index}`}>
                <Row>
                    <Form id={item.company_term_id} index={index++} item={item} saveItem={save} deleteItem={remove} onMove={onMove} onDrop={onDrop} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Create custom payment terms for your clients and suppliers. You can drag and drop your preferred payment term to the top of the list to make it your default.' link='https://help.commonsku.com/knowledge/payment-terms' />
            {list}
            {add}
        </>
    );
};
