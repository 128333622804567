import React, { useCallback } from 'react';
import { Button } from '@commonsku/styles';
import { oauth } from '../../utils';

export default function AdminStripeConnect() {
    const getStripeConnect = useCallback(() => {
        const redirect = async () => {
            const res = await oauth('POST', 'stripe', { 'action': 'request_code', 'redirect': window.location.href });
            window.location.href = res.json.stripe_auth_url;
        };

        redirect();

    }, []);

    return (
        <>
            <p>
                Use <a href="https://www.commonsku.com/integrations/stripe" target="_blank">Stripe</a> to accept payments on your commonsku Shops and invoices.
                Click the Connect to Stripe button below to link your account to commonsku.
            </p>
            <div>
                <Button onClick={getStripeConnect}>Connect To Stripe</Button>
            </div>
        </>

    );
}
