import React, { useMemo, useState } from 'react';
import { Col, ButtonsGroup, LabeledRadio } from '@commonsku/styles';

export default function CompanyCommissionService({
    item, saveItem, deleteItem
}) {
    const defaultValues = useMemo(() => ({
        service_id: '',
        exclude_from_margin: '0',
    }), []);

    const [form, setForm] = useState(item || defaultValues);

    let formFields = (
        <>
            <Col padded sm={12}>
                <b>{item.service_name}</b>
            </Col>
            <Col padded sm={12}>
                <ButtonsGroup>
                    <LabeledRadio label="Included" checked={form.exclude_from_margin == 0} onChange={(e) => {
                        setForm((form) => ({ ...form, exclude_from_margin: '0' }));
                        saveItem({ ...item, exclude_from_margin: '0' });
                    }} />
                    <LabeledRadio label="Excluded" checked={form.exclude_from_margin == 1} onChange={(e) => {
                        setForm((form) => ({ ...form, exclude_from_margin: '1' }));
                        saveItem({ ...item, exclude_from_margin: '1' });
                    }} />
                </ButtonsGroup>
            </Col>
        </>
    );

    return formFields;
}
