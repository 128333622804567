import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Input, Label,Row, Button, Col, colors, Spinner, Loading } from "@commonsku/styles";
import { updateCompanyData } from "../../../actions";
import { useIdentity } from "../../../hooks";
import { toast } from 'react-toastify';
import { usePromodataStatus } from '../../promo-data/hooks';

export default function Promodata() {
  const [promoDataToken , setPromoDataToken] = useState('');
  const [message,setMessage] = useState('');
  const [messageColor, setMessageColor] = useState(colors.primary1.main);
  const [loading, setLoading]= useState(false);
  const dispatch = useDispatch();
  const identity = useIdentity();
  const promodataStatus = usePromodataStatus();

  const onDeleteToken =() => {
    if(window.confirm("Are you sure you want to delete existing promodata token?")) {
      dispatch(updateCompanyData(identity.company_id, {promodata_access_token : ''}));
      toast.info("Existing Promodata token is deleted. You can save new token.");
      setMessage('');
      setPromoDataToken('');
    }
  };

  return <><Row>
    <div className="column tip">
      Promodata is the leading Australian promotional product database. Containing products from over 100 APPA Suppliers, Promodata is a subscription-based service only available to APPA distributors. To access Promodata within Commonsku you will need to enter your unique Promodata API key. For further information on obtaining an API key  or Promodata licensing contact hello@promodata.com.au
    </div>
  </Row>
    {promodataStatus === 'ACTIVE'
      ? <div style={{ paddingTop: '15px' }}>
        <p>
          Promodata token is configured.
        </p>
        <Button onClick={onDeleteToken}>Delete Token</Button>
      </div>
      : <>
        <Row style={{ paddingTop: '20px' }}>
          <Col md={4} >
            <Label style={{ fontWeight: 'bold' }} >
              Promodata Token
            </Label>
          </Col>
          <Col md={8}>
            <Input value={promoDataToken} onChange={(e) => setPromoDataToken(e.target.value)} />
          </Col>
        </Row>
        <Row>
          {loading ? <Col style={{ marginTop: '15px' }}><Spinner /></Col> : (promoDataToken && <Button
            style={{ float: 'right' }}
            onClick={() => {
              setLoading(true);
              setMessage('');
              const now = moment();
              dispatch(updateCompanyData(identity.company_id, { promodata_access_token: promoDataToken }))
                .then(({ payload }) => {
                  const checked = get(payload, 'company_data.promodata_date_checked');
                  if (!isEmpty(payload.error)) {
                    setMessageColor(colors.errors.main);
                    setMessage(' '.concat(payload.error));
                  } else if (moment(checked).isValid() && now.isBefore(checked)) {
                    setMessageColor(colors.primary1.main);
                    setMessage('Promodata token has been successfully added.');
                  } else {
                    setMessageColor(colors.errors.main);
                    setMessage('Token is invalid or expired. If you are unable to access Promodata after entering your unique API key, contact hello@promodata.com.au for assistance.');
                  }
                })
                .finally(() => {
                  setLoading(false);
                })
                ;
            }}
          >Save Promodata Token</Button>)}
        </Row>
        {message && <Row>
          <Col md={12} style={{ color: messageColor, marginTop: '15px' }}>{message}</Col>
        </Row>}
      </>}
  </>
}