import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HeadlessTable, Col, Row, Loading, LabeledSelect, Label, useWindowSize } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { parseMysqlDate, formatMoney, getIdentityUtils } from '../../utils';
import useBillingPeriods from './hooks/useBillingPeriods';
import useBillingCharges from './hooks/useBillingCharges';
import DownloadArtifact from '../DownloadArtifact';

const getAvailableTableSize = (innerWidth, innerHeight) => {
  // Popup width - padding - border
  let width = Math.min(1600, innerWidth - 100) - 40 - 2;

  // Popup height - padding
  let height = Math.min(1600, innerHeight - 100) - 40 - 2
  // height
  return [Math.floor(width), Math.floor(height * 0.9)];
};

const GlobalStyle = createGlobalStyle`
  .react-table-sticky .row.sticky {
    max-width: initial;
    margin-left: 0;
    margin-right: 0;
  }
`;

function useTableSize() {
  const [windowWidth, windowHeight] = useWindowSize();
  const [availWidth, availHeight] = getAvailableTableSize(windowWidth, windowHeight);
}

export default function AdminBillingPOFeeDetails() {
  const [billingPeriods,] = useBillingPeriods('CLOSED');
  const [billingPeriodId, setBillingPeriodId] = useState();
  const billingPeriodOptions = useMemo(() => {
    return billingPeriods.map(
      bp => ({ value: bp.billing_period_id, label: bp.billing_period_name })
    );
  }, [billingPeriods]);
  const selectedBillingPeriod = billingPeriods.find(
    bp => bp.billing_period_id === billingPeriodId
  );
  const artifact = selectedBillingPeriod && selectedBillingPeriod.artifacts.find(
    a => a.artifact_type === 'SUPPLIER-BILLING-REPORT'
  );

  const [charges, chargesLoading] = useBillingCharges(billingPeriodId);

  const [windowWidth, windowHeight] = useWindowSize();
  const [tableWidth, availableHeight] = getAvailableTableSize(windowWidth, windowHeight);
  const [tableHeight, setTableHeight] = useState(availableHeight);

  const summaryRef = useRef(null);
  useEffect(() => {
    if (summaryRef.current) {
      setTableHeight(availableHeight - Math.ceil(summaryRef.current.clientHeight));
    }
  }, [billingPeriodId, availableHeight, summaryRef]);

  const tableColumns = useMemo(() => {
    return [
      {
        Header: <Label>Division</Label>,
        accessor: 'division_name',
        width: Math.floor(0.2 * tableWidth),
      },
      {
        Header: <Label>Distributor</Label>,
        accessor: 'distributor_name',
        width: Math.floor(0.2 * tableWidth),
      },
      {
        Header: <Label>Accounts</Label>,
        accessor: (row, index) => row.account_number ?? row.supplier_account ?? row.promo_api_account,
        id: 'account',
        Cell: ({ row }) => <>
          {row.original.account_number && <div>{row.original.account_number}</div>}
          {row.original.supplier_account && <div>{row.original.supplier_account}</div>}
          {row.original.promo_api_account && <div>{row.original.promo_api_account}</div>}
        </>,
        width: Math.floor(0.15 * tableWidth),
      },
      {
        Header: <Label>PO #</Label>,
        accessor: 'po_number',
        width: Math.floor(0.15 * tableWidth),
      },
      {
        Header: <Label>Submit Method</Label>,
        accessor: 'submit_method',
        Cell: ({ value }) => <>{'epo' === value ? 'ePO' : 'Manual'}</>,
        width: Math.floor(0.1 * tableWidth),
      },
      {
        Header: <Label>PO Total</Label>,
        accessor: 'po_total',
        Cell: ({ value, row }) => <>${formatMoney(value)} {row.original.po_currency}</>,
        width: Math.floor(0.1 * tableWidth),
      },
      {
        Header: <Label>Fee Total</Label>,
        accessor: 'fee_total',
        Cell: ({ value, row }) => <>${formatMoney(value)} {row.original.fee_currency}</>,
        width: Math.floor(0.1 * tableWidth),
      },
    ];
  }, [tableWidth]);

  const scrollOffsetDivRef = useRef(null)
  const pageIndexDivRef = useRef(null)
  const sortDirectionDivRef = useRef(null)
  const currentColumnsDivRef = useRef(null)
  const horizontalOffsetDivRef = useRef(null)

  const [defaultScrollOffset, setDefaultScrollOffset] = useState(0);
  const onChangeSelected = useCallback(() => {
    setDefaultScrollOffset(parseInt(scrollOffsetDivRef.current.innerText) || 0)
  }, [setDefaultScrollOffset]);
  const onChangeSortOrColumns = useCallback((sortDirection, currentColumns) => {
    console.log(sortDirection);
    console.log(currentColumns);
  }, []);

  const table = (<HeadlessTable
    columns={tableColumns}
    data={charges}
    rowIdFIeld="charge_id"
    defaultSort={{ id: 'division_name', desc: false }}
    defaultScrollOffset={0}
    defaultHorizontalOffset={0}
    onChangeSelected={onChangeSelected}
    pageIndexDivRef={pageIndexDivRef}
    sortDirectionDivRef={sortDirectionDivRef}
    currentColumnsDivRef={currentColumnsDivRef}
    minHeight={tableHeight}
    pagination={false}
    scrollOffsetDivRef={scrollOffsetDivRef}
    horizontalOffsetDivRef={horizontalOffsetDivRef}
    onChangeSortOrColumns={onChangeSortOrColumns}
  />);  


  const chargeDetails = charges.reduce(
    (o, c) => ({
      po_totals: {
        ...o.po_totals,
        [c.po_currency]: (o.po_totals[c.po_currency] ?? 0) + Number(c.po_total)
      },
      fee_totals: {
        ...o.fee_totals,
        [c.fee_currency]: (o.fee_totals[c.fee_currency] ?? 0) + Number(c.fee_total)
      }
    }),
    {
      po_totals: {},
      fee_totals:{} 
    }
  );

  const loading = chargesLoading === 'pending';
  const loaded = !!billingPeriodId && chargesLoading === 'idle';

  return (
    <>
      <GlobalStyle />
      <Row ref={summaryRef}>
        <Col padded sm={2}>
          <LabeledSelect
            label="Billing Period"
            name="billing_period_id"
            value={billingPeriodOptions.find(bp => bp.value === billingPeriodId)}
            options={billingPeriodOptions}
            onChange={(selected) => setBillingPeriodId(selected.value)}
          />
        </Col>
        <Col padded sm={2} style={{ alignSelf: 'center' }}>
          {artifact &&
            <DownloadArtifact
              artifact_id={artifact.billing_period_artifact_id}
              filename={`PO-Billing-${selectedBillingPeriod.billing_period_name.replaceAll(' ', '')}.csv`}
              style={{ marginTop: '1rem' }}
            >
              Billing Report
            </DownloadArtifact>}
        </Col>
        <Col padded sm={8}>
          {loaded && <><Label>Total Fees</Label>{Object.keys(chargeDetails.fee_totals).map(cur => <div key={cur}>${formatMoney(chargeDetails.fee_totals[cur])} {cur}</div>)}</>}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {loading ? <Loading /> : (loaded ? table : null)}
        </Col>
      </Row>
    </>
  );
}
