import React, { useState } from 'react';
import { Row, Col, Popup, Button } from '@commonsku/styles';
import Form from './form/CompanyCommissionSalesTargetRate';
import useCompanyCommissionSalesTargetRates from './hooks/useCompanyCommissionSalesTargetRates';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';
import AdminCommissionPeopleSalesTarget from './AdminCommissionPeopleSalesTarget';

export default function AdminCommissionSalesTargetRate() {
    const [items, , save, remove] = useCompanyCommissionSalesTargetRates();
    const [openPeople, setOpenPeople] = useState(false);
    let popup = null;
    if (openPeople) {
        popup = (
            <Popup
                style={{ padding: 20, maxWidth: '1600px', zIndex: 10001 }}
                width='calc(100vw - 100px)'
                height='calc(100vh - 100px)'
                header={
                    <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                        <h2 style={{ margin: '0.2em' }}>Assign Sales Goals</h2>
                        <div>
                            <Button onClick={() => setOpenPeople(false)}>Done</Button>
                        </div>
                    </Row>
                }>
                <AdminCommissionPeopleSalesTarget />
            </Popup>
        );
    }

    const list = items.map((item, index) => {
        return (
            <Row key={item.commission_sales_target_id}>
                <Form item={item} nextItem={items[index + 1]} saveItem={save} deleteItem={remove} />
            </Row>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    const peopleButton = (
        <Button size='small' onClick={() => {
            setOpenPeople(true);
        }} key='people-button'>Assign Sales Goals</Button>
    );

    return (
        <>
            <TopInfo message={'Increase or decrease your team\'s commissions by applying a multiplier to their commission based on their annual sales goal attainment.'} link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#sales-goal' buttons={[peopleButton]} />
            <Row>
                <Col padded sm={3}>
                    <b>% of Goal</b>
                </Col>
                <Col padded sm={1}>

                </Col>
                <Col padded sm={3}>

                </Col>
                <Col padded sm={3}>
                    <b>Multiplier</b>
                </Col>
                <Col padded sm={2}>
                </Col>
            </Row>
            {list}
            {add}
            {popup}
            <ReactTooltip />
        </>
    );
}
