import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
active: "1"
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
company_type: "TENANT"
date_created: "2021-04-15 12:35:01"
display_order: "0"
label: "Check Stock"
next_action_type_id: "d72b8c13-8753-4c94-8ce3-a16404da59a1"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.next_action_type_id,
    sortComparer: (a, b) => {
        if (parseInt(a.display_order) < parseInt(b.display_order)) {
            return -1;
        }

        if (parseInt(a.display_order) > parseInt(b.display_order)) {
            return 1;
        }

        return 0;
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyNextActionTypes', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'next-action-type';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl, {});
    dispatch(received(res.json.next_action_types));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.next_action_type, {new: true})));
    dispatch(loaded());
    return res.json.next_action_type;
};

export const updateItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.next_action_type_id, data);
    dispatch(updated({ id: data.next_action_type_id, changes: res.json.next_action_type }));
    dispatch(loaded());
    return res.json.next_action_type;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.next_action_type_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    dispatch(deleted(data.next_action_type_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
