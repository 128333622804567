import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/companyBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useCompanyBenefits() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyBenefits);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            if (item.supplier_benefit_id) {
                await dispatch(updateItem({ id: item.supplier_benefit_id, ...item }));
                toast.success("Saved successfully");
	    } else {
                await dispatch(addItem(item));
	    }
        };

        updateCall();
    }, [dispatch]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
        };

        deleteCall();
    }, [dispatch]);

    return [
        items,
        loading,
        save,
        remove,
    ];
};
