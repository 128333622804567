import React, { useEffect, useState } from 'react';
import { LightIndicator, Link, TargetIcon } from '@commonsku/styles';
import { useDispatch } from 'react-redux';
import { oauth, dateStr } from '../../utils';
import { formatTargetMoney } from "../../containers/AnalyticsHelper";
import { getIdentityUtils } from '../../utils';
import { useIdentity } from '../../hooks';

export default function AdminCompanySalesTargetSummary({ title, paywalled }) {
    const identity = useIdentity();
    const { hasCapabilities } = getIdentityUtils(identity);
    const canEdit = hasCapabilities(['MODIFY-SETTINGS']);

    const date = new Date();
    const currentYear = date.getFullYear();
    const dispatch = useDispatch();
    const [currentTotal, setCurrentTotal] = useState(0);
    const [previousTotal, setPreviousTotal] = useState(0);

    useEffect(() => {
        const getTargets = async () => {
            const currentYearData = await oauth('GET', 'page/get-targets-management', {
                start_stamp: dateStr(new Date(currentYear, 0, 1)),
                end_stamp: dateStr(new Date(currentYear + 1, 0, 1))
            });
            const lastYearData = await oauth('GET', 'page/get-targets-management', {
                start_stamp: dateStr(new Date(currentYear - 1, 0, 1)),
                end_stamp: dateStr(new Date(currentYear, 0, 1))
            });

            setCurrentTotal(currentYearData.json.payload?.reduce((prev, current) => (parseInt(String(current?.invoice_target).replaceAll(",", "")) || 0) + prev, 0));
            setPreviousTotal(lastYearData.json.payload?.reduce((prev, current) => (parseInt(String(current?.invoice_target).replaceAll(",", "")) || 0) + prev, 0));
        };

        getTargets();

    }, [currentYear, dispatch]);

    const prevText = previousTotal > 0 ? (canEdit ? `total: $${formatTargetMoney(previousTotal)}` : 'set up') : 'not set up';
    const currentText = currentTotal > 0 ? (canEdit ? `total: $${formatTargetMoney(currentTotal)}` : 'set up') : 'not set up';

    return (
        <div style={{ display: 'flex' }}>
            <div><TargetIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                { !paywalled ?
                    <>
                        <LightIndicator name={`${currentYear - 1} targets ${prevText}`} on={previousTotal > 0 ? true : false} />
                        <LightIndicator name={`${currentYear} targets ${currentText}`} on={currentTotal > 0 ? true : false} />
                    </> : <>
                        <p style={{marginBottom: 0}}>Upgrade to the Advanced tier to gain access to the Targets feature.</p>
                        <Link as={'a'} href={'#'}>Learn More</Link>
                    </>
                }
            </div>
        </div>
    );
}
