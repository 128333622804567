import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/companyTerms';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useSortable from './useSortable';

export default function useCompanyTerm() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyTerms);
    const originalItems = selectors.selectAll(reduxState);
    const [items, onMove, onDrop] = useSortable(originalItems, updateItem);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            if (item.company_term_id) {
                await dispatch(updateItem({ id: item.company_term_id, ...item }));
            } else {
                await dispatch(addItem(item));
            }
            toast.success("Saved successfully");
        };

        updateCall();
    }, [dispatch]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
            let order = 0;
            for (const i of items) {
                if (i.company_term_id === item.company_term_id) {
                    continue;
                }
                if (order != i.display_order) {
                    await dispatch(updateItem({...i, display_order: order}));
                }
                order++;
            }

            // Update all the items to have a new display order
        };

        deleteCall();
    }, [dispatch, items]);

    return [
        items,
        loading,
        save,
        remove,
        onMove,
        onDrop,
    ];
};
