import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

import { memoizeOAuthGet } from '../utils';

const adapter = createEntityAdapter({
    selectId: (billingPeriod) => billingPeriod.billing_period_id,
    sortComparer: (a, b) => a.billing_period_start.localeCompare(b.billing_period_start)
});

const slice = createSlice({
    name: 'billingPeriods',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
    }
});

export const  {
    loading,
    received,
} = slice.actions;

const baseUrl = 'billing-period';

/**
 * The actions (thunk)
 */
export const getAll = (status) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await memoizeOAuthGet(baseUrl, { status, limit: 60 });
    dispatch(received(res.json.billing_periods));
    return res.json;
};

export const selectors = adapter.getSelectors();

export default slice.reducer;

