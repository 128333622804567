import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { qbo_class_options } from '../../../../company_setting_options';
import useQuickbookClass from '../../hooks/useQuickbookClass';

export default function ClassReference({ qboClasses }) {
    const [fields, save] = useQuickbookClass();

    // Well, we have to keep/modify the state here
    // Since it is a weird setup in qbo custom fields mapping
    const [mapped, setMapped] = useState({});

    // console.log(qboClasses, 'qboClasses', qbo_class_options);

    useEffect(() => {
        setMapped(qboClasses[0]);
    }, [qboClasses]);

    if (qboClasses.length === 0) return <Loading />;

    const getSelect = (qboClass) => {
        const value = qboClass?.csku_field ? qbo_class_options.filter(c => c.value === qboClass.csku_field)[0] : null;
        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder='Select a commonsku option'
                    label=''
                    options={qbo_class_options}
                    value={value}
                    onChange={async (item) => {
                        item = item === null ? {label: '', value: ''} : item;
                        let data = {
                            csku_field: item.value,
                        };
                        const savedData = await save({...qboClass, ...data});
                        setMapped((old) => {
                            return {...old, ...savedData};
                        });
                    }}
                />
            </>
        );
    };

    const currentMappings = (
        <Row key={'qbo-class-' + mapped?.company_id}>
            <Col sm={6}>
                <Input value='Class Ref' disabled={true} style={{ height: '42px' }}></Input>
            </Col>
            <Col sm={6} style={{paddingLeft: '1rem'}}>
                {getSelect(mapped)}
            </Col>
        </Row>
    );

    return (
        <>
            {currentMappings}
        </>
    );
}
