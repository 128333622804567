import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, IconButton } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

export default function CompanyCommissionClientRate({
    item, saveItem, deleteItem
}) {
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        commission_client_rate_id: '',
        client_rate_label: '',
        multiplier: '',
    }), []);

    const [form, setForm] = useState(item || defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['client_rate_label', 'multiplier']) {
            if (data[field].toString().trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            } else if (field === 'multiplier') {
                const min = Number(data[field]);
                if (isNaN(min)) {
                    setFormErrors((errors) => ({ ...errors, [field]: 'Multiplier must be number' }));
                    errorCount++;
                }
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4, hasIcon = false) => {
        const hasError = formErrors[field] !== '';
        const Component = Input;
        return (
            <Col padded sm={col}>
                <Component
                    style={{ marginBottom: '0' }}
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <>
            {getTextInput('client_rate_label', 8, true)}
            {getTextInput('multiplier', 3)}

            {!item ?
                (<Col padded sm={1} style={{ textAlign: 'left' }} >
                    <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                </Col>)
                : null}

            {item ?
                <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                    <IconButton preset='delete' onClick={() => {
                        setPopupOpen(true);
                        setDeleteItem(item);
                    }} />
                </Col>
                : <Col sm={1}></Col>}
            {deletePopup}
        </>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
}
