import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, LabeledInput, LabeledCheckbox, Col, Row, LabeledSelect, DefaultStar, HandleIcon, IconButton } from '@commonsku/styles';
import _ from 'lodash';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';
import { useIdentity } from '../../../hooks';
import { getIdentityUtils } from '../../../utils';
import { DraggableItemContainer } from '../../DraggableItem';

const THIRD_PARTY_OPTIONS = [
    { label: 'Unavailable', value: 'UNAVAILABLE' },
    { label: 'Available', value: 'AVAILABLE' },
    { label: 'Required', value: 'REQUIRED' },
];

function SupplierShipMethod({
    divisionId, couriers, item, saveItem, deleteItem,
    index, onMove, onDrop,
}) {
    const identity = useIdentity();
    const { hasCapabilities } = getIdentityUtils(identity);

    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        tenant_id: divisionId,
        ship_method_name: '',
        carrier: '',
        service: '',
        courier_id: '',
        third_party: 'UNAVAILABLE',
        is_pickup: false,
    }), []);

    const [form, setForm] = useState(item || defaultValues);
    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);

    const [formErrors, setFormErrors] = useState({ ...defaultValues, third_party: '', is_pickup: '' });
    const selectedCourier = couriers?.find(c => c.value === form['courier_id']);
    const selectedThirdParty = THIRD_PARTY_OPTIONS.find(t => t.value === form['third_party']);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;

        // Check data and set form errors
        for (const field of ['ship_method_name', 'courier_id', 'third_party', 'is_pickup']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }
        if (hasCapabilities(['FEATURE-CONNECTEDPLUS'])) {
            for (const field of ['carrier', 'service']) {
                if (data[field] === '') {
                    setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                    errorCount++;
                }
            }
	}

        if (errorCount > 0) return false;

        return true;
    }, []);

    const getTextInput = (field, label = null, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <LabeledInput label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const formFields = (
        <>
            {getTextInput('ship_method_name', 'Ship Method Name', 6)}

            <Col padded sm={6}>
                <LabeledSelect
                    label="Courier"
                    name="courier_id"
                    value={selectedCourier}
                    options={couriers}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: 'courier_id', value: selected.value } });

                        // Direct save item call
                        if (item?.ship_method_id) {
                            saveItem({ ...form, courier_id: selected.value });
                        }
                    }}
                />
                {formErrors['courier_id'] !== '' ? <span style={{ color: 'red' }}>{formErrors['courier_id']}</span> : ''}
            </Col>

            <Col padded sm={6}>
                <LabeledSelect
                    label="Third Party Accounts"
                    name="third_party"
                    value={selectedThirdParty}
                    options={THIRD_PARTY_OPTIONS}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: 'third_parth', value: selected.value } });

                        // Direct save item call
                        if (item?.ship_method_id) {
                            saveItem({ ...form, third_party: selected.value });
                        }
                    }}
                />
                {formErrors['third_party'] !== '' ? <span style={{ color: 'red' }}>{formErrors['third_party']}</span> : ''}
            </Col>

            <Col padded sm={6} style={{ marginTop: '2rem' }}>
                <LabeledCheckbox
                    label="Is Pickup?"
                    name="is_pickup"
                    value={Number(item?.is_pickup ?? 0)}
                    checked={Number(item?.is_pickup ?? 0)}
                    onChange={() => {
                        setFormFieldValue({ target: { name: 'is_pickup', value: !Number(item?.is_pickup ?? 0) } });

                        // Direct save item call
                        if (item?.ship_method_id) {
                            saveItem({ ...form, is_pickup: !Number(item?.is_pickup ?? 0) });
                        }
                    }}
                />
                {formErrors['is_pickup'] !== '' ? <span style={{ color: 'red' }}>{formErrors['is_pickup']}</span> : ''}
            </Col>

            {hasCapabilities(['FEATURE-CONNECTEDPLUS']) && getTextInput('carrier', 'PromoStandards Carrier', 6)}
            {hasCapabilities(['FEATURE-CONNECTEDPLUS']) && getTextInput('service', 'PromoStandards Service', 6)}

            <Col padded sm={6} style={{ margin: '1rem 0' }}>
                <Button size='small' onClick={() => {
                    if (!validateForm(form)) {
                        return;
                    }
                    if (!item) setForm(defaultValues);
                    saveItem({ ...item, ...form });
                    setShowForm(false);
                }}>{item ? 'Save' : 'Add'}</Button>
                <Button secondary style={{ marginLeft: '1rem' }} size='small' onClick={() => {
                    setForm(item || defaultValues);
                    setShowForm(false);
                }}>Cancel</Button>
            </Col>
            {deletePopup}
        </>
    );

    let viewOnly = (
        <Col sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => {
                setShowForm(true);
            }}>
                <AddButton />
            </Button>
        </Col>
    );

    if (item) {
        viewOnly = (
            <DraggableItemContainer
                index={index}
                onDrop={onDrop}
                onMove={onMove}
                item={item}
                canDrag={Boolean(item)}
                type="Item"
            >
                <div style={{ width: '100%', display: 'flex' }}>
                    <Col sm={1} className="align-self-center">{item ? <HandleIcon color="#DAE9EE" altText={item.ship_method_name || ''} /> : ''}</Col>
                    <Col sm={3} style={{ fontSize: '1.1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>{item.ship_method_name}</Col>
                    <Col sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                        {couriers.find(c => c.value === item.courier_id)?.label}
                    </Col>
                    <Col sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                        3rd Party Shipping {THIRD_PARTY_OPTIONS.find(t => t.value === item.third_party)?.label}
                    </Col>
                    <Col padded sm={1} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <IconButton preset='edit' onClick={() => {
                                setShowForm(true);
                            }} />
                        </div>
                    </Col>
                </div>
            </DraggableItemContainer>
        );
    }

    return showForm ? formFields : viewOnly;
}

export default SupplierShipMethod;
