import { Button, Input } from '@commonsku/styles';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useIdentity } from '../../hooks';
import { getIdentityUtils, oauth } from '../../utils';
import { TagInfo } from './types';
import './AddTag.css';

const CREATE_TAG_ERROR = "Unable to create tag";
const DUPLICATE_TAG_ERROR = "This tag already exists!";
const FORMAT_ERROR = "Commas are not permitted in tags!";

export interface AddTagProps {
    onAddTag: (tag: TagInfo) => void
    isDuplicateTag: (label: string) => boolean
    disabled?: boolean
    highlight?: boolean
}

export const AddTag = ({
    onAddTag,
    isDuplicateTag,
    disabled,
    highlight,
}: AddTagProps) => {
    const [error, setError] = useState<string>();
    const [tagLabel, setTagLabel] = useState('');
    const identity = useIdentity();
    const { hasCapabilities } = getIdentityUtils(identity);
    const canAddTag = hasCapabilities(['CREATE-TAGS']);

    const createTag = useCallback(async () => {
        try {
            if (error != null) return;

            if (isDuplicateTag(tagLabel)) {
                setError(DUPLICATE_TAG_ERROR);
                return;
            }

            const { json } = await oauth('POST', 'tag', {
                action: 'create',
                label: tagLabel,
            });

            const newTag = {
                id: json.tag_id,
                label: json.label,
                projects: 0,
                products: 0,
                clients: 0,
                contacts: 0,
                suppliers: 0,
            };

            onAddTag(newTag);
            setTagLabel('');
        } catch (e) {
            setError(CREATE_TAG_ERROR);
        }
    }, [tagLabel]);

    const onLabelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const label = e.target.value; 
        setTagLabel(label);

        if (label.includes(',')) {
            setError(FORMAT_ERROR);
        } else {
            setError(undefined);
        }
    }, []);

    return (
        <div className={"add-tag " + (highlight ? "with-highlight" : "no-highlight")}>
            <div className="add-tag-controls">
                {highlight != null && highlight &&
                    <div className="button-highlight" />
                }
                <Input
                    className="add-tag-input"
                    name="add-tag"
                    placeholder="Tag name to add"
                    value={tagLabel}
                    onChange={onLabelChange}
                    disabled={disabled || !canAddTag}
                />
                <Button
                    size="medium"
                    onClick={createTag}
                    disabled={disabled || !canAddTag || error != null || tagLabel.length === 0}
                >
                    Add Tag
                </Button>
                </div>
            {error != null &&
                <span className="add-tag-error">
                    {error}
                </span>
            }
        </div>
    );
}
