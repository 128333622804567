import React from 'react';
import Form from './form/CompanyCommissionMaximum';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import TopInfo from './misc/Info';

export default function AdminCompanyCommissionMaximum() {
    const [data, , save] = useCompanyGeneralData();

    return (
        <>
            <TopInfo message='Set a limit on the total commission payable on a project. If multiple reps are entitled to commissions, their rates will be proportionately reduced and will not exceed the allowable limit.' link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#threshold' />
            <Form data={data.data} save={save} />
        </>
    );
}
