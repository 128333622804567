import React, { useCallback, useMemo, useState } from 'react';
import { Button, LabeledSelect, Col, Row, IconButton} from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';
import _ from 'lodash';
import { states } from '../../../geo';

export default function CompanyTaxNexus({ item, saveItem, deleteItem }) {
    const [showForm, setShowForm] = useState(false);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const defaultValues = useMemo(() => ({
        state: '',
        tax_nexus_id: '',
        tenant_id: '',
    }), []);

    const base_states = _.get(states, 'United States', []);
    const state_options = base_states.map(s => ({ label: s.name, value: s.abbr }));

    const [form, setForm] = useState(item || defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const saveForm = useCallback(() => {
        saveItem({ ...item, ...form });
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
    }, [defaultValues, form, item, saveItem]);

    let state = (
        <LabeledSelect
            name="state"
            value={state_options.filter(c => c.value === form['state'])}
            options={state_options}
            onChange={(item) => setFormFieldValue({ target: { name: 'state', value: item.value } })}
        />
    );

    const formFields = (
        <div style={{ width: '100%' }}>
            <Row>
                {item ? <Col padded sm={11}>{state_options.filter(c => c.value === form['state'])[0]?.label}</Col> : null}
                {!item && showForm ?<Col padded sm={11}>{state}</Col> : null}
                {!item && !showForm ?
                    <Col padded sm={12} style={{ textAlign: 'center' }}>
                        <Button className='add-button' onClick={() => { setShowForm(true); }}>
                            <AddButton />
                        </Button>
                    </Col>
                    : null}

                <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                    {item ? <IconButton preset='delete' onClick={() => {
                        setDeleteItem(item);
                        setPopupOpen(true);
                    }} /> : (showForm ? <Button size='small' onClick={() => {
                        saveForm();
                    }}>Add</Button> : null)
                    }
                </Col>
            </Row>
            {deletePopup}
        </div>
    );

    return formFields;
}