import { IdType, Row } from "react-table";

export const pluralize = (
    n: number,
    one: string | ((n: number) => string),
    many: string | ((n: number) => string),
    zero?: string | ((n: number) => string),
) => {
    const pluralCase = (zero != null && n === 0)
        ? zero
        : (n === 1)
            ? one
            : many;

    if (typeof pluralCase === 'string') return pluralCase;
    return pluralCase(n);
}

export const caseInsensitiveSort = <RowType extends object>(
    rowA: Row<RowType>,
    rowB: Row<RowType>,
    accessor: IdType<RowType>,
) => {
    const a: string = rowA.values[accessor];
    const b: string = rowB.values[accessor];

    return a.localeCompare(b, undefined, {sensitivity: 'base'});
};
