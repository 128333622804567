import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useCompanyTax from '../../hooks/useCompanyTax';
import useQuickbookTaxAccount from '../../hooks/useQuickbookTaxAccount';
import { getObjectByKey } from './helpers';

export default function TaxCode({ qboAccounts }) {
    const [taxes] = useCompanyTax();
    const [taxAccounts, save] = useQuickbookTaxAccount();
    const accountOptions = qboAccounts.filter(q => q.AccountType === 'Other Current Liability');
    accountOptions.unshift({ Name: '' });

    if (taxes.length === 0) return <Loading />;

    const getSelect = (tax) => {
        const existingAccountRefs = taxAccounts.filter(t => t.tax_id === tax.tax_id);
        const existingAccountRef = existingAccountRefs[0]?.qbo_account_ref;
        const existingAccount = getObjectByKey(existingAccountRef, qboAccounts);

        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder='Select a liability account'
                    label=''
                    options={accountOptions.map(a => ({ label: a.Name, value: a.ref }))}
                    value={existingAccount ? { label: existingAccount.Name, value: existingAccount.ref } : null}
                    onChange={(item) => {
                        item = item === null ? { label: '', value: '' } : item;
                        let data = {
                            qbo_account_ref: item.value,
                            tax_id: tax.tax_id,
                        };
                        if (existingAccountRefs.length > 0) {
                            data = { ...existingAccountRefs[0], ...data };
                        }
                        save(data);
                    }}
                />
            </>
        );
    };

    const currentMappings = taxes.map((tax) => {
        return (
            <Row key={'qbo-tax-account-' + tax.tax_id}>
                <Col sm={6}>
                    <Input value={tax.label} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{ paddingLeft: '1rem' }}>
                    {getSelect(tax)}
                </Col>

            </Row>
        );
    });
    return (
        <>
            {currentMappings}
        </>
    );
}
