import React from 'react';
import { Box } from '@commonsku/styles';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyImprint';
import useCompanyImprint from './hooks/useCompanyImprint';
import TopInfo from './misc/Info';

export default function AdminImprint() {
    const [items, loading, save, remove] = useCompanyImprint();

    const list = items.map((item, index) => {
        return (
            <div key={item.imprint_id} data-testid={`row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Add, edit, or remove imprint methods for your supplier and decorator POs.' link='https://help.commonsku.com/knowledge/imprint-methods' />
            {list}
            {add}
        </>
    );
}
