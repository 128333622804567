import { useEffect } from 'react';
import { selectors, getAll } from '../../../redux/billingPeriods';
import { useSelector, useDispatch } from 'react-redux';

export default function useBillingPeriods(status = null) {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.billingPeriods);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll(status));
    }, [dispatch, status]);

    return [
        items,
        loading,
    ];
};
