import { colors, IconButton, Link } from '@commonsku/styles';
import React from 'react';

interface AdminAvalaraPaywallProps {
    paywallCtaUrl: string
}
const AdminAvalaraPaywall = ({
    paywallCtaUrl
}: AdminAvalaraPaywallProps) => {
    return  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', height: '100%', overflowY: 'hidden' }}>
        <div style={{ flexGrow: 1, marginLeft: 16, height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
            <div style={{background: colors.teal[20], color: colors.neutrals[100], padding: 32, gap: '2rem', display: 'flex', alignItems: 'center', marginBottom: '4rem' }}>
                <div style={{flex:'1 0 0'}}>
                    <h4>Upgrade to Advanced to Unlock the Avalara Integration</h4>
                    <p>Connecting commonsku with Avalara AvaTax provides an easy, fast, and accurate automated tax solution to save you time and money.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '1rem'}}>
                    <Link target="_blank" as={'a'} href={paywallCtaUrl}><IconButton size="large" cta>Contact Us</IconButton></Link>
                </div>
            </div>
            <div style={{display:'flex', alignSelf:'stretch',gap:'2rem', alignItems: 'flex-start'}}>
                <div style={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', gap: '1.6875rem', flex: '1 0 0', width: '37.5rem', color: colors.neutrals[100]}}>
                    <div style={{display: 'flex', height: '4.5rem', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={'../../../images/paywalls/cskuX.png'} style={{width:'15rem'}}/><img src={'../../../images/paywalls/avalara.png'} style={{width:'15rem'}}/>
                    </div>
                    <p style={{width: '37.5rem'}}>Fully automate and customize your tax calculations based on your jurisdictions with Avalara's AvaTax solution. Say goodbye to manual calculations and tracking, and hello to effortless tax compliance.</p>
                </div>
            </div>
        </div>
    </div>;
};

export default AdminAvalaraPaywall;