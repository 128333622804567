import React from 'react';
import { CommunityIcon, ConnectedIcon, ConnectedPlusIcon, Col, Row, Box } from '@commonsku/styles';

const FEATURE_DETAILS = {
    'COMMUNITYBASIC': {
        icon: CommunityIcon,
        name: 'Community',
    },
    'COMMUNITYPLUS': {
        icon: CommunityIcon,
        name: 'Community+',
    },
    'CONNECTEDBASIC': {
        icon: ConnectedIcon,
        name: 'Connected',
    },
    'CONNECTEDPLUS': {
        icon: ConnectedPlusIcon,
        name: 'Connected+',
    }
};

const PlanInfo = ({ feature_name }) => {
    const name = FEATURE_DETAILS[feature_name]?.name ?? feature_name[0].toUpperCase() + feature_name.slice(1).toLowerCase();
    const Icon = FEATURE_DETAILS[feature_name]?.icon ?? CommunityIcon;
    return (
        <Row>
            <Col sm={12}>
                <Box style={{ padding: '15px', margin: '15px', display: 'flex', alignItems: 'center' }}>
                    <Icon size='large' mr={16} /> You are on the {name} plan
                </Box>
            </Col>
        </Row>
    );
};

export default PlanInfo;
