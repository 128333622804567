import React, { useCallback, useMemo, useState } from 'react';
import { Col, LabeledSelect } from '@commonsku/styles';
import Avatar from 'react-avatar';

export default function CompanyCommissionAssignClientRate({
    item, saveItem, sourceOptions
}) {
    const defaultValues = useMemo(() => ({
        commission_client_rate_id: '',
    }), []);

    const [form, setForm] = useState(item || defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const getSelect = (name, col = 6) => {
        let value = form[name];
        if (!value) {
            value = null;
        }
        const selectedSource = sourceOptions.find(c => c.value === value);
        return (
            <Col sm={col}>
                <LabeledSelect
                    name={name}
                    value={selectedSource}
                    options={sourceOptions}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: name, value: selected.value } });
                        saveItem(item.client_id, {commission_client_rate_id: selected.value});
                    }}
                />
            </Col>);
    };

    let formFields = (
        <>
            <Col padded sm={6}><Avatar name={item.client_name} round={true} size={30} /> {item.client_name}</Col>
            {getSelect('commission_client_rate_id')}
        </>
    );

    return formFields;
}
