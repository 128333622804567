import React from 'react';
import { Row, Col, InfoIcon } from '@commonsku/styles';
import Form from './form/CompanyShipMethod';
import useCompanyShipMethod from './hooks/useCompanyShipMethod';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';

export default function AdminShipMethod() {
    const [items, loading, save, remove, couriers, onMove, onDrop] = useCompanyShipMethod();

    let index = 0;
    const list = items.map((item, itemIndex) => {
        return (
            <div key={item.ship_method_id} data-testid={`editable-row-${itemIndex}`}>
                <Row>
                    <Form index={index++} item={item} saveItem={save} deleteItem={remove} couriers={couriers} onMove={onMove} onDrop={onDrop} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} couriers={couriers} />
        </Row>
    );

    return (
        <>
            <TopInfo message="Add your team's preferred shipping methods and couriers (FedEx, UPS, or Other). You can drag and drop your favorite method to the top of the list to make it your default." link='https://help.commonsku.com/knowledge/ship-method' />
            <Row>
                <Col padded sm={1}></Col>
                <Col padded sm={3}>
                    <b>Ship Method Name</b>
                </Col>
                <Col padded sm={7}>
                    <b>Courier</b>
                </Col>
                <Col padded sm={1}>
                    <b>Delete</b>
                </Col>
            </Row>
            {list}
            {add}
            <ReactTooltip />
        </>
    );
}
