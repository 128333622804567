import { useEffect, useCallback, useState } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/companyExchangeRates';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { oauth } from '../../../utils';

export default function useCompanyExchangeRate() {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyExchangeRates);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        const getCurrencies = async () => {
            const res = await oauth('GET', 'currency', {});
            console.log(res.json.currencies, 'currency');
            setCurrencies(res.json.currencies || []);
        };

        getCurrencies();
    }, []);

    const save = useCallback((item) => {
        const updateCall = async () => {
            if (item.exchange_rate_id) {
                await dispatch(updateItem({ id: item.exchange_rate_id, ...item }));
            } else {
                await dispatch(addItem(item));
            }
            toast.success("Saved successfully");
        };

        updateCall();
    }, [dispatch]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
        };

        deleteCall();
    }, [dispatch]);

    return [
        items,
        loading,
        save,
        remove,
        currencies,
    ];
};
