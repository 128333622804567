import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useQuickbookCurrency from '../../hooks/useQuickbookCurrency';
import { getObjectByKey } from './helpers';

export default function Currency({ qboCurrencies, qboAccounts }) {
    const [mappedCurrencies, saveMappedCurrency] = useQuickbookCurrency();
    const accountsPayable = qboAccounts.filter(q => q.AccountType === 'Accounts Payable');
    accountsPayable.unshift({ Name: '' });

    if (qboCurrencies.length === 0) return <Loading />;

    const getCurrencySelect = (qboCurrency) => {
        const existingAccountRefs = mappedCurrencies.filter(m => m.currency_id === qboCurrency);
        const existingAccountRef = existingAccountRefs[0]?.qbo_account_ref;
        const existingAccount = getObjectByKey(existingAccountRef, qboAccounts);
        // console.log(existingAccountRefs, existingAccountRef, existingAccount, 'existingAccount');
        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder='Select an accounts payable'
                    label=''
                    options={accountsPayable.map(a => ({ label: a.Name, value: a.ref }))}
                    value={existingAccount ? { label: existingAccount.Name, value: existingAccount.ref } : null}
                    onChange={(item) => {
                        item = item === null ? { label: '', value: '' } : item;
                        let data = {
                            qbo_account_ref: item.value,
                            currency_id: qboCurrency,
                            qbo_currency_ref: qboCurrencies.indexOf(qboCurrency) + 1,
                        };
                        if (existingAccountRefs.length > 0) {
                            data = { ...existingAccountRefs[0], ...data };
                        }
                        saveMappedCurrency(data);
                    }}
                />
            </>
        );
    };

    const currentMappings = qboCurrencies.map((qboCurrency) => {
        return (
            <Row key={'qbo-currency-' + qboCurrency}>
                <Col sm={6}>
                    <Input value={qboCurrency} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{ paddingLeft: '1rem' }}>
                    {getCurrencySelect(qboCurrency)}
                </Col>

            </Row>
        );
    });
    return (
        <>
            {currentMappings}
        </>
    );
}
