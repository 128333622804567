import { getAccountStatusAdapter, getGeneralSlice, getAccountStatusThunkActions } from './helpers';

const adapter = getAccountStatusAdapter();

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('distributorAccountStatus', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const thunkActions = getAccountStatusThunkActions(slice.actions, 'DISTRIBUTOR');

export const {
    getAll,
    addItem,
    updateItem,
    deleteItem,
} = thunkActions;

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
