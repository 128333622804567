import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, Row, LabeledSelect, IconButton, LabeledIconInput } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

export default function CompanyCommissionRate({
    item, saveItem, deleteItem
}) {
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        commission_rate_id: '',
        commission_rate: '',
        commission_rate_label: '',
        commission_source: '',
    }), []);

    const sourceOptions = [{ label: 'Margin', value: 'MARGIN' }, { label: 'Sales', value: 'SUBTOTAL' }];

    const [form, setForm] = useState(item || defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);
    const selectedSource = sourceOptions.find(c => c.value === form['commission_source']);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['commission_rate', 'commission_rate_label', 'commission_source']) {
            if (data[field].toString().trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4, hasIcon = false) => {
        const hasError = formErrors[field] !== '';
        const Component = hasIcon ? LabeledIconInput : Input;
        return (
            <Col padded sm={col}>
                <Component
                    style={{ marginBottom: '4px' }}
                    iconPosition='right'
                    Icon={<div>%</div>}
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <>
            {getTextInput('commission_rate_label', 6)}
            {getTextInput('commission_rate', 2, true)}

            <Col padded sm={3}>
                <LabeledSelect
                    label=""
                    name="commission_source"
                    value={selectedSource}
                    options={sourceOptions}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: 'commission_source', value: selected.value } });

                        // Direct save item call
                        if (item?.commission_rate_id) {
                            saveItem({ ...form, commission_source: selected.value });
                        }
                    }}
                />
                {formErrors['commission_source'] !== '' ? <span style={{ color: 'red' }}>{formErrors['commission_source']}</span> : ''}
            </Col>

            {!item ?
                (<Col padded sm={1} style={{ textAlign: 'left' }} >
                    <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                </Col>)
                : null}

            {item ?
                <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                    <IconButton preset='delete' onClick={() => {
                        setPopupOpen(true);
                        setDeleteItem(item);
                    }} />
                </Col>
                : <Col sm={1}></Col>}
            {deletePopup}
        </>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
}
