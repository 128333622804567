import {
  entitiesReducer as coreEntitiesReducer,
  dropdownsReducer,
} from '../reducers/core';
import displayReducer from '../reducers/display';
import tempReducer from '../reducers/temp';
import emailTemplateReducer from '../redux/emailTemplates';
import commissionRateReducer from '../redux/commissionRates';
import companyServiceReducer from '../redux/companyServices';
import commissionMarginRateReducer from '../redux/commissionMarginRates';
import commissionClientRateReducer from '../redux/commissionClientRates';
import commissionSalesTargetRateReducer from '../redux/commissionSalesTargetRates';
import clientStatusReducer from '../redux/clientStatus';
import supplierStatusReducer from '../redux/supplierStatus';
import distributorStatusReducer from '../redux/distributorStatus';
import companyAddressReducer from '../redux/companyAddresses';
import companyGeneralDataReducer from '../redux/companyGeneralData';
import userGeneralDataReducer from '../redux/userGeneralData';
import companyTermReducer from '../redux/companyTerms';
import companyNextActionTypeReducer from '../redux/companyNextActionTypes';
import companyImprintReducer from '../redux/companyImprints';
import companyTaxReducer from '../redux/companyTaxes';
import companyTaxNexusReducer from '../redux/companyTaxNexus';
import companyShipMethodReducer from '../redux/companyShipMethods';
import companyShipAccountReducer from '../redux/companyShipAccounts';
import companyExchangeRateReducer from '../redux/companyExchangeRates';
import companyQboCurrencyReducer from '../redux/companyQboCurrencies';
import companyQboTaxReducer from '../redux/companyQboTax';
import companyQboTaxAccountReducer from '../redux/companyQboTaxAccounts';
import companyQboCustomFieldsReducer from '../redux/companyQboCustomFields';
import companyQboFieldMappingsReducer from '../redux/companyQboFieldMappings';
import companyQboClassesReducer from '../redux/companyQboClasses';
import companyBenefitsReducer from '../redux/companyBenefits';
import companyDivisionsReducer from '../redux/companyDivisions';
import billingPeriodsReducer from '../redux/billingPeriods';
import billingChargesReducer from '../redux/billingCharges';
import { decorationReducer, decoratorReducer, decoratorIdReducer } from '../reducers/admin_decoration_charges';
import { divisionReducer, supplierReducer } from '../reducers/supplier';

import { getInitialState } from '../global';
import { mpSlice, tempDataSlice } from '../components/marketing-platforms';

var initialState = getInitialState();

const defaultDropdownState = initialState.dropdowns ? initialState.dropdowns : {};
const dropdowns = (state = defaultDropdownState, action) => {
  const new_state = Object.assign({}, state, dropdownsReducer(state, action), {
    divisions: divisionReducer(state.divisions ? state.divisions : [], action),
    suppliers: supplierReducer(state.suppliers ? state.suppliers : [], action),
    decorators: decoratorReducer(state.decorators ? state.decorators : [], action),
  });

  return new_state;
};

const defaultIdentityState = initialState.identity ? initialState.identity : {};
const identityReducer = (state = defaultIdentityState, action) => {
  return state;
};

const entitiesReducer = (state = initialState.entities || {}, action) => {
  return Object.assign({}, state, coreEntitiesReducer(state, action), {
    decorations: decorationReducer(state.decorations ? state.decorations : [], action),
    decorator_id: decoratorIdReducer(state.decorator_id ? state.decorator_id : '', action),
  });
};

export const reducers = {
  display: displayReducer,
  temp: tempReducer,
  dropdowns: dropdowns,
  entities: entitiesReducer,
  identity: identityReducer,
  emailTemplates: emailTemplateReducer,
  commissionRates: commissionRateReducer,
  companyServices: companyServiceReducer,
  commissionMarginRates: commissionMarginRateReducer,
  commissionClientRates: commissionClientRateReducer,
  commissionSalesTargetRates: commissionSalesTargetRateReducer,
  clientStatus: clientStatusReducer,
  supplierStatus: supplierStatusReducer,
  distributorStatus: distributorStatusReducer,
  companyAddresses: companyAddressReducer,
  companyGeneralData: companyGeneralDataReducer,
  userGeneralData: userGeneralDataReducer,
  companyTerms: companyTermReducer,
  companyNextActionTypes: companyNextActionTypeReducer,
  companyImprints: companyImprintReducer,
  companyTaxes: companyTaxReducer,
  companyTaxNexus: companyTaxNexusReducer,
  companyShipMethods: companyShipMethodReducer,
  companyShipAccounts: companyShipAccountReducer,
  companyExchangeRates: companyExchangeRateReducer,
  companyQboCurrencies: companyQboCurrencyReducer,
  companyQboTaxAccounts: companyQboTaxAccountReducer,
  companyQboTaxes: companyQboTaxReducer,
  companyQboCustomFields: companyQboCustomFieldsReducer,
  companyQboFieldMappings: companyQboFieldMappingsReducer,
  companyQboClasses: companyQboClassesReducer,
  companyBenefits: companyBenefitsReducer,
  companyDivisions: companyDivisionsReducer,
  billingPeriods: billingPeriodsReducer,
  billingCharges: billingChargesReducer,
  [mpSlice.name]: mpSlice.reducer,
  [tempDataSlice.name]: tempDataSlice.reducer,
};
