import React, { useState } from 'react';
import Form from './form/CompanyCommissionAssignClientRate';
import { Row, Col, LabeledSelect, Input, Button } from '@commonsku/styles';
import useCompanyCommissionClientRates from './hooks/useCompanyCommissionClientRates';
import useClients from './hooks/useClients';

export default function AdminCommissionAssignClientRate() {
    const [clients, loadClients, saveClient] = useClients();
    const [rates] = useCompanyCommissionClientRates();
    const [searchFilters, setSearchFilters] = useState({
        name: '',
        rate: '',
    });

    const tooMany = clients.length >= 100;

    const rateOptions = [{ value: '', label: '' }].concat(
        rates.map(r => ({
            label: `${r.client_rate_label} (x ${r.multiplier})`,
            value: r.commission_client_rate_id,
        }))
    );

    const list = (clients || []).map((item) => {
        return (
            <Row key={item.client_id + '-rate'}>
                <Form item={item} sourceOptions={rateOptions} saveItem={saveClient} />
            </Row>
        );
    });

    return (
        <>
            <Row>
                <Col sm={5} padded>
                    <Input
                        placeholder={'Search for a client'}
                        name={'search_client_name'}
                        onChange={(e) => {
                            const value = e.target.value;
                            setSearchFilters((old) => ({ ...old, name: value }));
                        }}
                    />
                </Col>
                <Col sm={5} padded>
                    <LabeledSelect
                        placeholder={'Any'}
                        name={'search_client_rate'}
                        options={rateOptions}
                        onChange={(item) => {
                            setSearchFilters((old) => ({ ...old, rate: item.value }));
                        }}
                    />
                </Col>
                <Col sm={2} padded>
                    <Button size='small'
                        onClick={() => {
                            console.log('search filters', searchFilters);
                            loadClients(searchFilters.name, searchFilters.rate);
                        }}>
                        Search
                    </Button>
                </Col>
            </Row>
            {tooMany ? <p>Too many clients to show. Only the first {clients.length} are shown. Please enter some criteria to narrow it down.</p> : null}
            {list}
        </>
    );
}
