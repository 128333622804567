import React, { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Row, Col, LabeledIconInput, SearchIcon, IconButton, TrashIcon, MergeIcon, useWindowSize, InfoIcon, Link } from '@commonsku/styles';
import { TagTable } from './TagTable';
import { PopupViewProps, TagInfo } from './types';
import { getIdentityUtils } from '../../utils';
import { useIdentity } from '../../hooks';
import { AddTag } from './AddTag';
import './TagTableView.css';

export const TagTableTitle = () => (
    <Row className="tag-table-title">
        <span className="title-text">
            Manage Tags
        </span>
        <Link
            className="help-link"
            as="a"
            href="https://help.commonsku.com/knowledge/tags"
            target="_blank"
            rel="noreferrer"
        >
            <InfoIcon size="medium" />
            learn more
        </Link>
    </Row>
);

export const TagTableView = ({
    tags,
    selectedTags,
    isFetchingTags,
    fetchingTagsError,
    onNewTags,
    onSelect,
    onUpdateView,
}: PopupViewProps) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [filterString, setFilterString] = useState('');
    const [filteredTags, setFilteredTags] = useState<TagInfo[]>([]);
    const contentRef = useRef<HTMLDivElement | null>();
    const windowSize = useWindowSize();
    const identity = useIdentity();
    const { hasCapabilities } = getIdentityUtils(identity);
    const canMergeTags = hasCapabilities(['MODIFY-TAGS', 'DELETE-TAGS']);
    const canDeleteTags = hasCapabilities(['DELETE-TAGS']);

    const actionsDisabled = isFetchingTags || fetchingTagsError;

    useLayoutEffect(() => {
        if (contentRef.current != null) {
            setContentHeight(contentRef.current.offsetHeight);
        }
    }, [windowSize]);

    const tagFilter = useCallback(
        (tag: TagInfo) => tag.label.toLowerCase().includes(filterString.trim()),
        [filterString],
    );

    useEffect(() => {
        setFilteredTags(tags.filter(tagFilter));
    }, [tagFilter, tags]);

    const onFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const filter = e.target.value.toLowerCase();
        setFilterString(filter);
        setFilteredTags(tags.filter(tagFilter));
    }, [tagFilter]);

    return (
        <div className="admin-tags">
            <Row className="admin-tags-actions">
                <Col>
                    <LabeledIconInput
                        className="filter-tags"
                        name="filter"
                        Icon={<SearchIcon />}
                        value={filterString}
                        onChange={onFilterChange}
                        placeholder="Filter"
                        disabled={actionsDisabled}
                    />
                </Col>
            </Row>
            <Row className="admin-tags-content" ref={contentRef}>
                <TagTable
                    tags={filteredTags}
                    height={contentHeight}
                    loading={isFetchingTags}
                    error={fetchingTagsError}
                    selectedTags={selectedTags}
                    onSelectTags={onSelect}
                />
            </Row>
            <Row className="admin-tags-actions" justify="space-between">
                <AddTag
                    onAddTag={tag => onNewTags([tag])}
                    isDuplicateTag={label => tags.some(tag => tag.label === label)}
                    disabled={actionsDisabled}
                />
                <div>
                    <IconButton
                        size="medium"
                        style={{ marginRight: 8 }}
                        Icon={<TrashIcon />} 
                        disabled={!canDeleteTags || selectedTags.length === 0}
                        onClick={() => onUpdateView('delete')}
                    >
                        Delete Selected
                    </IconButton>
                    <IconButton
                        size="medium"
                        Icon={<MergeIcon />}
                        disabled={!canMergeTags || selectedTags.length < 2}
                        onClick={() => onUpdateView('merge')}      
                    >
                        Merge Selected
                    </IconButton>
                </div>
            </Row>
        </div>
    );
}
