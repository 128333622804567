import React from 'react';
import { Col, Row, Box } from '@commonsku/styles';
const LicenceInfo = ({ data }) => {
    return (
        <Row>
            <Col sm={12}>
                <Box style={{ padding: '15px', margin: '15px' }}>
                    <div>
                        You are using {data.activeUsers || 0} of {data.quantity || 0} licenses. Go to the <a href="/admin_users_new.php">Manage Users</a> tab to change the number of licenses.
                    </div>
                </Box>
            </Col>
        </Row>
    );
};

export default LicenceInfo;
