import { colors, IconButton, Link } from '@commonsku/styles';
import React from 'react';

interface MarketingSoftwareAppPaywallProps {
    paywallCtaUrl: string
}

const MarketingSoftwareAppPaywall = ({
    paywallCtaUrl
}: MarketingSoftwareAppPaywallProps) => {
    return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', height: '100%', overflowY: 'hidden' }}>
        <div style={{ flexGrow: 1, marginLeft: 16, height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
            <div style={{background: colors.teal[20], color: colors.neutrals[100], padding: 32, gap: '2rem', display: 'flex', alignItems: 'center', marginBottom: '4rem' }}>
                <div style={{flex:'1 0 0'}}>
                <h4>Upgrade to Advanced to Unlock the Marketing Automation Integrations</h4>
                <p>Connect your marketing automation platform with commonsku to seamlessly communicate with your clients and free up valuable time for your team.</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '1rem'}}>
                <Link target="_blank" as={'a'} href={paywallCtaUrl}><IconButton size="large" cta>Contact Us</IconButton></Link>
                </div>
            </div>
            <div style={{display:'flex', alignSelf:'stretch',gap:'2rem', alignItems: 'flex-start'}}>
                <div style={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', gap: '1.6875rem', flex: '1 0 0', color: colors.neutrals[100]}}>
                    <img src={'../../images/paywalls/mc.png'} style={{width:'15rem'}}/>
                    <p>Sync your contacts from commonsku to Mailchimp, and begin elevating your marketing strategy with email campaigns and landing pages.</p>
                </div>
                <div style={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', gap: '1.6875rem', flex: '1 0 0', color: colors.neutrals[100]}}>
                    <img src={'../../images/paywalls/hubspot.png'} style={{width:'15rem'}}/>
                    <p>Sync your contacts from commonsku to Hubspot to nurture your clients and prospects with email campaigns, landing pages, and social media management.</p>
                </div>
                <div style={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', gap: '1.6875rem', flex: '1 0 0', color: colors.neutrals[100]}}>
                    <img src={'../../images/paywalls/zapier.png'} style={{width:'15rem'}} />
                    <p>Unlock a variety of time-saving automations for your team, and connect the applications in your tech stack to create powerful processes with Zapier.</p>
                </div>
            </div>
        </div>
    </div>
};

export default MarketingSoftwareAppPaywall;
