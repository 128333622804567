import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
commission_sales_target_id: "28125df5-9ce3-4ab1-8581-3fdcccefaf65"
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
multiplier: "1.00"
sales_target_max: "90"
sales_target_min: "0"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.commission_sales_target_id,
    sortComparer: (a, b) => {
        if (parseInt(a.sales_target_min) < parseInt(b.sales_target_min)) {
            return -1;
        }

        if (parseInt(a.sales_target_min) > parseInt(b.sales_target_min)) {
            return 1;
        }

        return 0;
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('commissionSalesTargetRates', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'commission-sales-target-rate';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.commission_sales_target_rates));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.commission_sales_target_rate, {new: true})));
    dispatch(loaded());
    return res.json.commission_sales_target_rate;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.commission_sales_target_id, data);
    dispatch(updated({ id: data.commission_sales_target_id, changes: data }));
    dispatch(loaded());
    return res.json.commission_sales_target_rate;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.commission_sales_target_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.commission_sales_target_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
