import React, { useCallback, useState } from 'react';
import { oauth } from '../../utils';
import { PopupTitleProps, PopupViewProps, TagInfo } from './types';
import { pluralize } from './utils';
import { AlertNotification, ArrowIcon, Button, Col, Loading, Row, Text, colors } from '@commonsku/styles';
import { TagLabel } from './TagLabel';
import './DeleteTagsView.css';

const DELETE_TAGS_ERROR = "Unable to delete tags.";

export const DeleteTagsTitle = ({ tagCount, onBack }: PopupTitleProps) => (
    <Row className="delete-tags-title">
        <ArrowIcon
            size="large"
            direction="left"
            onClick={onBack}
            pointer
        />
        <span>
            Are you sure you want to delete {pluralize(
                tagCount, 
                'this tag',
                 n => `these ${n} tags`
            )}?
        </span>
    </Row>
);

export const DeleteTagsView = ({
    selectedTags: tagsToDelete,
    onRemoveTags,
    onUpdateView,
}: PopupViewProps) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(false);

    const handleDelete = useCallback(() => {
        const deleteTags = async (tags: TagInfo[]) => {
            try {
                await oauth('POST', 'tagged-resource', {
                    action: 'multi-delete',
                    tag_ids: tags.map(tag => tag.id),
                });

                onRemoveTags(tags);
                onUpdateView('table');
            } catch (e) {
                setError(true);
            } finally {
                setIsDeleting(false);
            }
        }

        setIsDeleting(true);
        deleteTags(tagsToDelete);
    }, []);

    return (
        <div className="delete-tags">
            {isDeleting &&
                <div className="delete-overlay">
                    <Row className="delete-indicator">
                        <Col>
                            <Loading />
                        </Col>
                        <Col>
                            <Text style={{ color: colors.teal['65'] }}>
                                Deleting...
                            </Text>
                        </Col>
                    </Row>
                </div>
            }
            <Row className="delete-tags-alert">
                <AlertNotification alertType="neutral">
                    {pluralize(tagsToDelete.length, '1 tag', n => `${n} tags`)} will be deleted. You cannot undo this action.
                </AlertNotification>
            </Row>
            {error &&
                <Row className="delete-tags-alert">
                    <AlertNotification alertType="error">
                        {DELETE_TAGS_ERROR}
                    </AlertNotification>
                </Row>
            }
            <Row className="tag-container">
                {tagsToDelete.map(tag => (
                    <TagLabel tag={tag} key={tag.id} />
                ))}
            </Row>
            <Row className="delete-tags-actions" justify="flex-end">
                <Button 
                    size="medium"
                    secondary
                    onClick={() => onUpdateView('table')}
                >
                    Cancel
                </Button>
                <Button
                    size="medium"
                    variant="error"
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            </Row>
        </div>
    );
}
