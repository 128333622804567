import { useEffect } from 'react';
import { selectors, getAll } from '../../../redux/billingCharges';
import { useSelector, useDispatch } from 'react-redux';

export default function useBillingCharges(billingPeriodId) {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.billingCharges);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        if (billingPeriodId) {
            dispatch(getAll(billingPeriodId));
        }
    }, [dispatch, billingPeriodId]);

    return [
        items,
        loading,
    ];
};
