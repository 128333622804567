import { useState } from 'react';
import { AlertNotification, IconButton, Popup } from '@commonsku/styles';

export default function useConfirmDelete({ message, deleteItemCallback, deleteLabel = 'Delete', showUndoMessage = true }) {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(null);

    let popup = null;
    let messageBody = <div>{message || 'Are you sure you want to delete?'}</div>;
    if (showUndoMessage) {
        messageBody = (<div style={{ display: 'flex', flexDirection: 'column', marginTop: '-12px' }}>
            <div>{message || 'Are you sure you want to delete?'}</div>
            <div><b>You cannot undo this action!</b></div>
        </div>);
    }
    if (open && item) {
        popup = (
            <Popup header={<span></span>} style={{ width: 'auto', height: 'auto', zIndex: 10000 }}>
                <h5 style={{ fontWeight: 'bold', color: '#2A4D63' }}>Are you sure?</h5>
                <AlertNotification alertType="error">{messageBody}</AlertNotification>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
                    <IconButton variant='secondary' style={{ minWidth: '200px', marginRight: '1em' }} onClick={() => { setOpen(false); }}>Cancel</IconButton>
                    <IconButton variant='error' style={{ minWidth: '200px', }} onClick={() => { deleteItemCallback(item); setOpen(false); }}>{deleteLabel}</IconButton>
                </div>
            </Popup>
        );
    }

    return [popup, setOpen, setItem];
};
