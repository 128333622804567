import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { colors, Input, FilledChevronIcon, LabeledIconInput, Row, SearchIcon, Avatar, Table, TH, THead, TBody, TR, TD } from '@commonsku/styles';
import { map, get, includes, trim, toLower, isMatch, keys, pickBy, groupBy, isEmpty, differenceWith, isEqual } from 'lodash';

function GlobalFilter({
  globalFilter,
  handleGlobalSearch,
}) {
  return (
    <LabeledIconInput
      Icon={<SearchIcon />}
      label=''
      placeholder='Filter'
      value={globalFilter || ''}
      onChange={e => {
        handleGlobalSearch(e.target.value || '');
      }}
      iconLabelStyles={{ background: 'white', }}
      iconColor={colors.neutrals[50]}
      containerStyle={{ marginBottom: 10, width: '100%' }}
    />
  );
}

const columns = [
  {
    Header: 'Name',
    accessor: 'contact_full_name',
    Cell: ({ value, row }) => {
      const userImage = get(row.original, ['user_image_paths', 'small']) || '/images/user-avatar3-120x120.png';
      return <div style={{ display: 'flex', alignItems: 'center' }}><Avatar pic={userImage} shape='circle' size='medium' mr='16' /> {value}</div>
    }
  },
  {
    Header: 'Email',
    accessor: 'contact_email',
  },
  {
    Header: 'Login ID',
    accessor: 'sage_username',
    Cell: ({ value: initialValue, row: { index, original }, column: { id }, updateUsersData, duplicatedUsernames }) => {
      const [value, setValue] = useState(initialValue);

      const handleOnChange = (e) => {
        setValue(toLower(e.target.value));
      };
      const onBlur = () => {
        updateUsersData(index, id, value);
      };
      const duplicated = includes(duplicatedUsernames, value);

      return <>
        <Input data-id={`username_${index}`} error={duplicated} style={{ marginBottom: 0 }} value={value} onChange={handleOnChange} onBlur={onBlur} />
        {duplicated && <div style={{ color: colors.errors['60'], lineHeight: '24px', fontSize: 14 }}>Can’t have duplicate user names</div>}
      </>;
    }
  },
  {
    Header: 'Authentication Key',
    accessor: 'sage_auth_key',
    Cell: ({ value: initialValue, row: { index }, column: { id }, updateUsersData, data }) => {
      const [value, setValue] = useState(initialValue || '');
      const handleOnChange = (e) => {
        setValue(trim(e.target.value));
      };

      const onBlur = () => {
        updateUsersData(index, id, trim(value));
      };

      return <Input
        type="password" data-id={`auth_key_${index}`} style={{ marginBottom: 0 }}
        placeholder={get(data, [index, 'has_sage_auth_key']) ? '********' : ''}
        value={value}
        onChange={handleOnChange} onBlur={onBlur}
      />;
    },
    disableSortBy: true
  },
];

function SageUserTable({ users: initialUsers, onChange }) {
  const [updatedUsersData, setUpdatedUsersData] = useState({});

  const users = useMemo(() => {
    return map(initialUsers, (user, i) => {
      const modified = updatedUsersData[i] ?? null;
      return !isMatch(user, modified) ? { ...user, ...modified } : user;
    });
  }, [initialUsers, updatedUsersData]);

  const duplicatedUsernames = keys(pickBy(
    groupBy(users, 'sage_username'),
    (group, key) => group.length > 1 && !isEmpty(key)
  ));
  const hasConflict = duplicatedUsernames.length > 0;

  useEffect(() => {
    const updatedUsers = differenceWith(users, initialUsers, isEqual);
    if (!isEmpty(updatedUsers) && onChange) {
      onChange({ updatedUsers, hasConflict });
    }
  }, [hasConflict, onChange, users, initialUsers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: users,
      duplicatedUsernames,
      updateUsersData: useCallback((index, id, value) => {
        setUpdatedUsersData((prev) => {
          return { ...prev, [index]: { ...prev[index], [id]: value } };
        });
      }, []),
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy
  );

  return <>
    <Row style={{ width: '100%', position: 'sticky', top: '0px', paddingTop: '37px', background: colors.neutrals.white, zIndex: 100 }}>
      <GlobalFilter
        globalFilter={state.globalFilter}
        handleGlobalSearch={setGlobalFilter}
      />
    </Row>
    <Row style={{ position: 'relative', flexGrow: 2, display: 'initial' }} >
      <Table className='table' {...getTableProps()} style={{ border: 'none', borderCollapse: 'separate' }} >
        <THead style={{ position: 'sticky', top: '88px' }}>
          {headerGroups.map(headerGroup => (
            <TR {...headerGroup.getHeaderGroupProps()} style={{ paddingLeft: 16 }}>
              {headerGroup.headers.map(column => (
                <TH
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    background: colors.neutrals['20'],
                  }}
                >
                  {column.render('Header')}
                  {column.canSort ? (
                    column.isSorted ?
                      (
                        column.isSortedDesc ?
                          <FilledChevronIcon direction="down" style={{ width: 'auto', verticalAlign: 'middle' }} />
                          :
                          <FilledChevronIcon direction="up" style={{ width: 'auto', verticalAlign: 'middle' }} />
                      )
                      :
                      <FilledChevronIcon direction="updown" style={{ width: 'auto', verticalAlign: 'middle' }}
                      />
                  ) : (
                    ''
                  )}
                </TH>
              ))}
            </TR>
          ))}
        </THead>

        <TBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TR {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TD
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        fontSize: 16,
                        background: colors.neutrals.white,
                        cursor: 'auto'
                      }}
                    >
                      {cell.render('Cell')}
                    </TD>
                  );
                })}
              </TR>
            );
          })}
        </TBody>
      </Table>
    </Row>
  </>;
}

export default SageUserTable;
