import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {Button, LabeledInput, Row, Col, LabeledCheckbox, colors} from '@commonsku/styles';
import TopInfo from '../misc/Info';
import {parseRestBoolean} from "../../../utils";
import config from '../../../config';

export default function CompanyCustomWebhook({ data, save }) {
    const defaultValues = useMemo(() => ({
        webhook_invoice_url: '',
        webhook_bill_url: '',
        webhook_password: '',
        webhook_po_financial_url: '',
        webhook_po_financial_test_flag: false,
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    useEffect(() => {
        let values = defaultValues;
        if (data.webhook_invoice_url) {
            values = {
                webhook_invoice_url: data.webhook_invoice_url,
                webhook_bill_url: data.webhook_bill_url,
                webhook_password: data.webhook_password,
                webhook_po_financial_url: data.webhook_po_financial_url,
                webhook_po_financial_test_flag: parseRestBoolean(data.webhook_po_financial_test_flag)
            };
        }
        setForm(values);
    }, [data.webhook_bill_url, data.webhook_invoice_url, data.webhook_password, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.type === 'checkbox' ? target.checked : target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['webhook_invoice_url', 'webhook_bill_url', 'webhook_password']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        save({ ...form }, true);
    }, [form, save, validateForm]);

    const getTextInput = (field, label = null, col = 4, type = 'text') => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <LabeledInput type={type} label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const getCheckbox = (field, label = null, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col} added style={{ padding: 40}}>
                <LabeledCheckbox id={field} label={label || field} name={field} checked={form[field]} onChange={setFormFieldValue} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
                {form[field] && <TopInfo
                    message={`Only Projects with the Prefix  - ${config.po_financials_test_prefix} - at the beginning of the project name will be exported.  Eg.    Project name: ***test*** trying out the webhook 1.`}
                    showLink={false}
                />}
            </Col>
        );
    };

    const formFields = (
        <>
            <TopInfo
                message='A webhook is an HTTP callback. This option allows commonsku to POST client invoices and supplier bills to your predefined URL. Contact support@commonsku.com to learn more.'
                showLink={false}
            />
            <Row>
                {getTextInput('webhook_invoice_url', 'Invoice Webhook URL:', 12)}
                {getTextInput('webhook_bill_url', 'Bill Webhook URL:', 12)}
                {getTextInput('webhook_password', 'Webhook Password:', 12, 'password')}
                {data.po_webhook_enabled && getTextInput('webhook_po_financial_url', 'PO Financials URL:', 6)}
                {data.po_webhook_enabled && getCheckbox('webhook_po_financial_test_flag', 'Test Mode', 6)}
                <Col sm={12} padded><Button size='small' onClick={saveForm}>Save</Button></Col>
            </Row>
        </>
    );

    return formFields;
}
