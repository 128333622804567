import React, { useEffect, useState } from 'react';
import { LightIndicator, TagIcon } from '@commonsku/styles';
import { oauth } from '../../utils';
import { pluralize } from '../tag-management';

const TAG_FETCH_ERROR = "Unable to fetch tag count";
const LOADING_TAGS_MSG = "Loading tags...";
const ONE_TAG_MSG = "1 tag";
const MANY_TAGS_MSG = (n) => `${n} tags`;
const NO_TAGS_MSG = "No tags added";

export const AdminTagsSummary = () => {
    const [tagCount, setTagCount] = useState<number>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchTagCount = async () => {
            try {
                const { json } = await oauth('GET', 'tagged-resource/summary', {});
                setTagCount(json.count);
            } catch (e) {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
        fetchTagCount();
    }, []);

    const getTagSummary = () => {
        if (loading) {
            return LOADING_TAGS_MSG;
        }

        if (error || tagCount == null) {
            return TAG_FETCH_ERROR;
        }

        return pluralize(
            tagCount,
            ONE_TAG_MSG,
            MANY_TAGS_MSG,
            NO_TAGS_MSG,
        );
    };

    const tagIndicatorOn = !error 
        && !loading 
        && tagCount != null 
        && tagCount > 0;

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <TagIcon size="large" mr={16} color='#DAE9EE' />
            </div>
            <div>
                <h5>Tags</h5>
                <LightIndicator name={getTagSummary()} on={tagIndicatorOn} />
            </div>
        </div>
    );
}
