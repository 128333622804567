import React from 'react';
import { IntegrationsIcon, LightIndicator } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import { usePromodataStatus } from '../promo-data/hooks';
import { filter, isEmpty, trim } from 'lodash';
import { useHasCapabilities } from '../../hooks';

export default function AdminTabbedIntegrationSummary({ title }) {
    const [{ data }] = useCompanyGeneralData();
    const promodataStatus = usePromodataStatus();

    let accountingSoftware = data?.companyData?.accounting_software;
    const marketing = data?.companyData?.marketing_software;
    const creditCard = data?.companyData?.credit_card_software;
    const sage = data?.sage_account && data?.use_sage_connect == '1';
    const avalara = data?.companyData?.avalara_enable;
    const users = data?.users ?? [];
    if (!accountingSoftware) accountingSoftware = 'CSV';

    const marketingMap = {
        MAILCHIMP: 'Mailchimp',
        WISHPOND: 'Wishpond',
        HUBSPOT: 'HubSpot',
    };

    const accountingMap = {
        QBO: 'Quickbooks Online',
        XERO: 'Xero',
        WEBHOOK: 'Custom Webhook',
        XML: 'XML Export',
        CSV: 'CSV Export',
    };

    const creditcardMap = {
        CARDCONNECT: 'Clover Connect',
        STRIPE: 'Stripe',
    };

    const count = filter(users, user => {
        return !isEmpty(trim(user.sage_username))
    }).length;
    return (
        <div style={{ display: 'flex' }}>
            <div><IntegrationsIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                {useHasCapabilities(['HAS-MARKETING-INTEGRATIONS']) &&
                    <LightIndicator name={<>Marketing Integration{marketing ? <>: {marketingMap[marketing]}</> : ' not configured'}</>} on={marketing ? true : false} />
                }
                <LightIndicator name={<>Credit Card Integration{creditCard ? <>: {creditcardMap[creditCard]}</> : ' not configured'}</>} on={creditCard ? true : false} />
                <LightIndicator name={<>Accounting Integration{accountingSoftware ? <>: {accountingMap[accountingSoftware]}</> : ' not configured'}</>} on={accountingSoftware ? true : false} />
                <LightIndicator name={<>SAGE {sage ? ' configured' : ' not configured'}</>} on={sage ? true : false} />
                <LightIndicator name={<>Credentials entered for {count}/{users.length} users</>} on={count == users.length} />
                <LightIndicator name={<>Promodata Token {promodataStatus === 'ACTIVE' ? ' configured' : promodataStatus === 'EXPIRED' ? ' expired' : ' not configured'}</>} on={promodataStatus === 'ACTIVE' ? true : false} />
                {useHasCapabilities(['HAS-AVALARA']) &&
                    <LightIndicator name={<>Avalara {avalara ? ' configured' : ' not configured'}</>} on={avalara ? true : false} />
                }
            </div>
        </div>
    );
}
