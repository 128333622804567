import React from 'react';
import { LightIndicator, Link, PercentIcon } from '@commonsku/styles';
import useCompanyCommisionRate from './hooks/useCompanyCommisionRate';

export default function AdminCommissionSummary({ title, paywalled }) {
    const [rates] = useCompanyCommisionRate();
    return (
        <div style={{ display: 'flex' }}>
            <div><PercentIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                { !paywalled ?
                    <LightIndicator name={`${rates?.length} rates defined`} on={rates?.length > 0 ? true : false} />
                : <>
                    <p style={{marginBottom: 0}}>Upgrade to the Advanced tier to gain access to the Commissions Management feature.</p>
                    <Link as={'a'} href={'#'}>Learn More</Link>
                </>}
            </div>
        </div>
    );
}
