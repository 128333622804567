import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
date_created: "2022-06-10 10:01:36"
qbo_account_ref: "36"
qbo_tax_account_id: "7083e9f4-1469-4e34-a894-1e6a819e14da"
tax_id: "b260d7ca-fff9-11e0-b240-1231392503bd"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_tax_account_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboTaxAccounts', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'qbo-tax-account',  {});
    dispatch(received(res.json.qbo_tax_accounts));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'qbo-tax-account', data);
    dispatch(added(Object.assign(res.json.qbo_tax_account, {new: true})));
    dispatch(loaded());
    return res.json.qbo_tax_account;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-tax-account/' + data.qbo_tax_account_id, data);
    dispatch(updated({ id: data.qbo_tax_account_id, changes: data }));
    dispatch(loaded());
    return res.json.qbo_tax_account;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
