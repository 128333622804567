import { useEffect, useCallback } from 'react';
import { getData, getExtraData, updateData, updateFeature } from '../redux/companyGeneralData';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// This hook is intended to be used for commonsku admins or distributors admins
// retrieving and saving distributors information. Make sure to utilize the
// companyGeneralDataReducer reducer for this hook to work.

export default function useCompanyGeneralDataById(companyId, companyType = 'DISTRIBUTOR') {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.companyGeneralDataById);
    const type = companyType === 'SUPPLIER' ? 'supplier' : 'tenant';

    const reloadData = useCallback(() => {
        dispatch(getData(companyId, type));
        dispatch(getExtraData(companyId));
    }, [ dispatch, companyId, type ]);

    useEffect(() => {
        if (!reduxState.data.active) {
            reloadData();
        }
    }, [ reloadData ]);

    const save = useCallback((data, showToast = true) => {
        const updateCall = async () => {
            try {
                await dispatch(updateData(companyId, type, data));
                if (showToast) {
                    toast.success("Saved successfully");
                }
            } catch (error) {
                toast.error("Cannot save Company data", { autoClose: false });
                reloadData();
            }
        };

        return updateCall();
    }, [dispatch, companyId, type]);

    const saveFeature = useCallback(({featureId, paymentModel, applyAdvancedCoupon, showToast = true}) => {
        const updateCall = async () => {
            try {
                await dispatch(updateFeature(companyId, featureId, paymentModel, applyAdvancedCoupon));
                if (showToast) {
                    toast.success("Saved successfully");
                }
            } catch (error) {
                toast.error("Cannot save Feature Level", { autoClose: false });
                reloadData();
            }
        };

        return updateCall();
    }, [dispatch, companyId]);

    return {
        company: reduxState.data,
        loading: reduxState.loading,
        save,
        saveFeature,
    };
};
