import React from 'react';
import { SetTargetTabledWindow } from '../../containers/SetTargetTabledWindow';

const AdminCompanySalesTarget = () => {

    return (
        <SetTargetTabledWindow role='management' />
    );
};

export default AdminCompanySalesTarget;
