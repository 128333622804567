import React, { useState, useCallback } from 'react';

import { Col, Row, Box, Button, Popup, GearIcon, LightIndicator, EditIcon, EpsIcon, Progress, InfoIcon } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import AdminEmailSignature from './AdminEmailSignature';
import AdminUserEsp from './AdminUserEsp';
import useUserGeneralData from './hooks/useUserGeneralData';
import Avatar from 'react-avatar';
import { calculateProfileCompleteness } from '../../utils';

const AdminMySettings = () => {
    const identity = useIdentity();
    const [{ data }] = useUserGeneralData();
    const [selectedModule, setSelectedModule] = useState(null);
    const signature = { __html: data?.user_email_signature };

    const profileProgress = calculateProfileCompleteness(data);
    const profileSummary = (
        <>
            <Progress style={{ height: '10px' }} value={profileProgress.sumCompleted} max={profileProgress.sumAll} />
            <div style={{ marginTop: '12px' }}>
                {profileProgress.notCompleted.slice(0, 5).map((item) => {
                    return <div style={{ display: 'flex', alignItems: 'center' }} key={item.key}>
                        <InfoIcon width='16px' color='#FFD302' />
                        <span style={{ marginLeft: '8px' }}>{item.label}</span>
                    </div>;
                })}
            </div>
        </>
    );

    const moduleMap = {
        'profilePage': {
            'component': null, 'title': 'Profile', 'link': '/user.php?id=' + identity.user_id,
            'content': profileSummary,
            'height': '190px',
            'icon': identity?.user_image_paths?.medium ? <Avatar src={identity?.user_image_paths?.medium || null} name={identity?.user_first_name} round={true} size={30} style={{ marginRight: '16px' }} /> : null,
            'show': true,
        },
        'emailSignature': {
            'component': AdminEmailSignature,
            'title': 'Email Signature',
            'content': <div dangerouslySetInnerHTML={signature}></div>,
            'height': '190px',
            'icon': <EditIcon size='large' mr={16} color='#DAE9EE' />,
            'maxWidth': '800px',
            'show': true,
        },
        'esp': {
            'component': AdminUserEsp,
            'title': 'ESP',
            'popupTitle': 'ESP Integration',
            'content': <LightIndicator name={data.esp_account ? "Configured" : "Not configured"} on={data.esp_account ? true : false} />,
            'icon': <EpsIcon size='large' mr={16} color='#DAE9EE' />,
            'show': 'SUPPLIER' !== identity.company_type,
        },
    };

    const onCloseModule = useCallback(() => {
        setSelectedModule(null);
    }, []);

    let popup = null;
    if (selectedModule) {
        const ModuleContainer = moduleMap[selectedModule]['component'];
        const maxWidth = moduleMap[selectedModule]['maxWidth'] ?? '600px';
        if (ModuleContainer) {
            popup = (
                <Popup
                    className={moduleMap[selectedModule]['isTabbed'] ? 'tabbed' : 'non-tabbed'}
                    style={{ padding: moduleMap[selectedModule]['isTabbed'] ? 0 : 20, maxWidth: maxWidth, maxHeight: '650px' }}
                    width='calc(100vw - 100px)'
                    height='calc(100vh - 100px)'
                    header={
                        <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                            <h2 style={{ margin: '0.2em' }}>{moduleMap[selectedModule]['popupTitle'] || moduleMap[selectedModule]['title']}</h2>
                            <div>
                                <Button onClick={onCloseModule}>Done</Button>
                            </div>
                        </Row>
                    }>
                    <ModuleContainer identity={identity} inPopup={true} />
                </Popup>
            );
        }
    }

    const list = Object.keys(moduleMap).filter(
        moduleKey => moduleMap[moduleKey].show
    ).map((moduleKey) => {
        const SummaryContainer = moduleMap[moduleKey]['summary'] ?? null;
        const externalLink = moduleMap[moduleKey]['link'] ?? null;
        const height = moduleMap[moduleKey]['height'] ?? null;
        const content = moduleMap[moduleKey]['content'] ?? null;
        const icon = moduleMap[moduleKey]['icon'] ?? null;

        return (
            <Col key={moduleKey} lg={6} sm={12}>
                <Box style={{ padding: '15px', margin: '15px' }} onClick={(e) => {
                    if (externalLink) {
                        window.open(externalLink);
                    } else {
                        setSelectedModule(moduleKey);
                    }
                }}>
                    <div style={{ height: height || '80px', overflow: 'hidden' }}>
                        {SummaryContainer ?
                            <SummaryContainer title={moduleMap[moduleKey]['title']} /> :
                            <div style={{ display: 'flex' }}>
                                <div>{icon ? icon : <GearIcon size='large' mr={16} color='#DAE9EE' />}</div>
                                <div style={{ flexGrow: 1 }}>
                                    <h5>{moduleMap[moduleKey]['title']}</h5>
                                    {content ? <div className='tile-summary-content'>{content}</div> : null}
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </Col>
        );
    });

    return (
        <>
            <Row>
                {list}
            </Row>
            {popup}
        </>
    );
};

export default AdminMySettings;
