import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import AdminStripeConnect from './AdminStripeConnect';
import AdminStripeSetup from './AdminStripeSetup';

export default function AdminStripe() {
    const [{ data }, , , saveExtra, , , , disconnectStripe] = useCompanyGeneralData();
    const setup = !!data?.companyData.stripe_access_token;

    return (
        <>
            {setup ? <AdminStripeSetup disconnect={disconnectStripe} save={saveExtra} data={data} /> : <AdminStripeConnect  />}
        </>
    );
}
