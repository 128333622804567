import React from 'react';
import Form from './form/CompanyCommissionPeopleSalesTarget';
import useCompanyCommissionSalesTargetRates from './hooks/useCompanyCommissionSalesTargetRates';
import useTenantUsers from './hooks/useTenantUsers';

export default function AdminCommissionPeopleSalesTarget() {
    const [users, saveUser] = useTenantUsers();
    const [rates] = useCompanyCommissionSalesTargetRates();

    const list = (users || []).map((item) => {
        return (
            <tr key={item.user_id + '-sales-target'}>
                <Form item={item} rates={rates} saveItem={saveUser} />
            </tr>
        );
    });

    return (
        <>
            <table>
                <thead>
                    <tr>
                    <th style={{ minWidth: '200px' }}>Annual Sales Goal</th>
                    {rates.map((r, index) => {
                        const min = r.sales_target_min;
                        const max = rates[index + 1]?.sales_target_min || '∞';
                        return <th key={r.commission_sales_target_id + '-header'}>
                            {`${min}% - [x${r.multiplier}] - ${max}%`}
                        </th>;
                    })}
                    </tr>
                </thead>
                <tbody>
                    {list}
                </tbody>
            </table>
        </>
    );
}
