import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
account_name: "asdgasdg"
account_number: "dsagdagd"
account_postal: "adsgadg"
active: "1"
client_id: null
courier_id: "37867ac9-e12c-11e9-8659-ac2b6e37e335"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
date_created: "2022-04-28 13:45:51"
job_id: null
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
third_party_account_id: "abeec948-a4f5-445c-bfb2-47a007536d7b"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.third_party_account_id,
    sortComparer: (a, b) => (a.account_name + a.third_party_account_id).localeCompare(b.account_name + b.third_party_account_id)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyShipAccounts', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'third-party-shipping-account';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.third_party_shipping_accounts));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.third_party_shipping_account, {new: true})));
    dispatch(loaded());
    return res.json.third_party_shipping_account;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.third_party_account_id, data);
    dispatch(updated({ id: data.third_party_account_id, changes: data }));
    dispatch(loaded());
    return res.json.tax;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.third_party_account_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.third_party_account_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
