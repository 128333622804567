import { AlertIcon, Col, Label, Loading, Row, SelectionTable, Text, VirtualTableStyles, colors } from "@commonsku/styles";
import React, { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import { Column } from "react-table";
import { TagInfo } from "./types";
import { caseInsensitiveSort } from "./utils";
import './TagTable.css';

const FETCH_TAGS_ERROR = "Unable to retrieve tags";
const LOADING_MSG = "Retrieving tags...";

type RowType = Record<keyof TagInfo, TagInfo[keyof TagInfo]>;

export interface TagTableProps {
    tags: TagInfo[]
    height?: number
    error?: boolean
    loading?: boolean
    selectedTags?: TagInfo[]
    onSelectTags?: (tags: TagInfo[]) => void
}

export const TagTable = ({
    tags,
    height,
    error,
    loading,
    selectedTags,
    onSelectTags,
}: TagTableProps) => {
    const handleSelectionChange = useCallback((selectedTags: RowType[]) => {
        if (onSelectTags != null) {
            onSelectTags(selectedTags as TagInfo[]);
        }
    }, [onSelectTags]);

    const columns: Column<RowType>[] = useMemo(() => [
        {
            Header: <Label>Tag Name</Label>,
            accessor: 'label',
            sortType: caseInsensitiveSort,
        },
        {
            Header: <Label>Projects</Label>,
            accessor: 'projects',
        },
        {
            Header: <Label>Clients</Label>,
            accessor: 'clients',
        },
        {
            Header: <Label>Contacts</Label>,
            accessor: 'contacts',
        },
        {
            Header: <Label>Suppliers</Label>,
            accessor: 'suppliers',
        },
        {
            Header: <Label>Products</Label>,
            accessor: 'products',
        },
    ], []);

    if (error) {
        return (
            <TableStatus Icon={<AlertIcon size="huge" />}>
                <Text style={{ color: colors.error }}>
                    {FETCH_TAGS_ERROR}
                </Text>
            </TableStatus>
        );
    }

    if (loading) {
        return (
            <TableStatus Icon={<Loading />}>
                <Text style={{ color: colors.teal['65'] }}>
                    {LOADING_MSG}
                </Text>
            </TableStatus>
        );
    }

    return (
        <VirtualTableStyles className="tag-table-wrapper" scrollIndicators>
            <SelectionTable
                className="tag-table"
                height={height}
                data={tags}
                columns={columns}
                selectedRows={selectedTags}
                onSelectionChange={handleSelectionChange}
            />
        </VirtualTableStyles>
    );
}

const TableStatus = ({
    Icon,
    children,
}: PropsWithChildren<{ Icon: ReactNode }>) => {
    return (
        <div className="tag-table-status">
            <Row>
                <Col>{Icon}</Col>
            </Row>
            <Row>
                <Col>
                    {children}
                </Col>
            </Row>
        </div>
    );
};
