import { Button, LabeledCheckbox, Col, Row, LightIndicator } from '@commonsku/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyForm from './form/quickbooks/Currency';
import useQuickbookGetMappingOptions from './hooks/useQuickbookGetMappingOptions';
import LineItemType from './form/quickbooks/LineItemType';
import Tax from './form/quickbooks/Tax';
import TaxCode from './form/quickbooks/TaxCode';
import CustomField from './form/quickbooks/CustomField';
import FieldMapping from './form/quickbooks/FieldMapping';
import ClassReference from './form/quickbooks/ClassReference';
import useConfirmDelete from './hooks/useConfirmDelete';
import TopInfo from './misc/Info';

export default function AdminQuickbooksSetup({ data, save, disconnect }) {
    const [qboCurrencies, qboItems, qboTaxCodes, qboAccounts, qboCustomFields, qboInvoiceFields, qboClasses] = useQuickbookGetMappingOptions();

    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({
        deleteItemCallback: disconnect,
        message: 'Disconnecting will clear your token and you will need to re-authenticate.',
        deleteLabel: 'Disconnect',
        showUndoMessage: false,
    });

    const disconnectQuickbook = () => {
        setDeleteItem({});
        setPopupOpen(true);
    };

    const topMessage = "Use the dropdowns below to map your commonsku items and taxes to QuickBooks Online (QBO). " +
        "Don't see the QBO account you're looking for? Try disconnecting and reconnecting the integration to pull an up-to-date list of accounts.";

    const isUS = data?.companyData?.qbo_country === 'US';

    return (
        <div>
            <TopInfo message={topMessage} link='https://help.commonsku.com/knowledge/connect-to-quickbooks-online' />
            <LightIndicator name={`Connected to ${data?.companyData?.qbo_company_name}`} on={true} />
            <Button size='small' onClick={disconnectQuickbook}>Disconnect from QuickBooks</Button>

            {qboCurrencies.length > 0 ? <>
                <h5 className='section-title'>Multicurrency Accounts Payable</h5>
                <p>Select the relevant accounts payable for each foreign currency.</p>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col sm={6}><b>Currency</b></Col>
                    <Col sm={6} style={{ paddingLeft: '1rem' }}><b>QuickBooks Online Account Payable</b></Col>
                </Row>
                <CurrencyForm qboCurrencies={qboCurrencies} qboAccounts={qboAccounts} />
            </> : null}

            <h5 className='section-title'>Chart of Accounts</h5>
            <p>Select the appropriate QBO accounts for your commonsku bills, items, and services.
                Avalara Invoice Tax mapping is only necessary for teams using <a href="https://help.commonsku.com/knowledge/commonsku-avalara" target="_blank">Avalara in commonsku</a>.
            </p>
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm={6} ><b>Item Type</b></Col>
                <Col sm={6} style={{ paddingLeft: '1rem' }}><b>QuickBooks Online Account</b></Col>
            </Row>
            <LineItemType qboCurrencies={qboCurrencies} qboAccounts={qboAccounts} qboItems={qboItems} />

            <h5 className='section-title'>{isUS ? (+data?.companyData?.qbo_partner_tax_enabled === 1 ? 'Invoice Tax' : 'Invoice Taxes') : 'Taxes'}</h5>
            {isUS ? <>
                <LightIndicator
                    name={`QuickBooks Online automated sales tax is ${+data?.companyData?.qbo_partner_tax_enabled === 1 ? 'enabled' : 'disabled'}`}
                    on={+data?.companyData?.qbo_partner_tax_enabled === 1}
                />
                <p>
                    QuickBooks Online offers automated sales tax calculations for US companies.{' '}
                    <a target="_blank" href="https://community.intuit.com/articles/1567657-set-up-and-use-automated-sales-tax">Learn how to set up and use automated sales tax in QuickBooks Online.</a>
                </p>
            </>
                :
                <p>Match your QBO tax names to commonsku tax codes.</p>
            }
            {+data?.companyData?.qbo_partner_tax_enabled === 1 ? null : (
                <>
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col sm={6} ><b>QuickBooks Online Tax Name</b></Col>
                        <Col sm={6} style={{ paddingLeft: '1rem' }}><b>commonsku Tax Codes</b></Col>
                    </Row>
                    <Tax qboTaxCodes={qboTaxCodes} />
                </>
            )}

            {isUS ? <>
                <h5 className='section-title'>{+data?.companyData?.qbo_partner_tax_enabled !== 1 || !isUS ? 'Bill Taxes' : 'Bill Tax'}</h5>
                <p>Match your commonsku tax codes to your QBO liability accounts. Each commonsku tax code must map to a unique QBO liability account. This mapping is only necessary for teams paying supplier bills tax.</p>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col sm={6} ><b>commonsku Tax Codes</b></Col>
                    <Col sm={6} style={{ paddingLeft: '1rem' }}><b>QuickBooks Online Liability  Account</b></Col>
                </Row>
                <TaxCode qboAccounts={qboAccounts} />
            </> : null}

            <h5 className='section-title'>Custom Fields</h5>
            <p>Map important information from your commonsku invoice to your QBO invoice’s custom fields by choosing from the available commonsku options.</p>
            <Row style={{ marginBottom: '1rem' }}>
                <Col sm={6} ><b>QuickBooks Online Custom Fields</b></Col>
                <Col sm={6} style={{ paddingLeft: '1rem' }}><b>commonsku Options</b></Col>
            </Row>
            <CustomField qboCustomFields={qboCustomFields} />

            {+data?.companyData?.qbo_field_mapping_enabled === 0 ? null : (
                <>
                    <h5 className='section-title'>Invoice Address Fields</h5>
                    <p>Map important information from your commonsku invoice to your QBO invoice's address fields by choosing from the available commonsku options.</p>
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col sm={6} ><b>QuickBooks Online Invoice Address Fields</b></Col>
                        <Col sm={6} style={{ paddingLeft: '1rem' }}><b>commonsku Options</b></Col>
                    </Row>
                    <FieldMapping qboInvoiceFields={qboInvoiceFields} />
                </>
            )}

            {qboClasses.length > 0 ? (<>
                <h5 className='section-title'>Class Tracking</h5>
                <p>{
                    +data?.companyData?.qbo_partner_tax_enabled === 1 ?
                        'Organize your QBO transactions by class by choosing from the available commonsku options.' :
                        'Organize your QBO invoices by class by choosing from the available commonsku options.'
                }</p>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col sm={6} ><b>QuickBooks Online Class Reference Field</b></Col>
                    <Col sm={6} style={{ paddingLeft: '1rem' }}><b>commonsku Options</b></Col>
                </Row>
                <ClassReference qboClasses={qboClasses} />
            </>) : null}
            {deletePopup}
        </div >

    );
}
