import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, Col, Row, HandleIcon, IconButton } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';
import { DraggableItemContainer } from '../../DraggableItem';

const CompanyNextActionType = ({
    item, saveItem, deleteItem,
    index, onDrop, onMove,
}) => {
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        active: '',
        label: '',
        display_order: '',
        date_created: '',
        company_type: '',
        company_id: '',
        next_action_type_id: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['label']) {
            if (data[field].trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4, onBlurSave = true) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <Input className='no-margin-bottom' name={field} value={form[field]} onChange={setFormFieldValue} onBlur={onBlurSave ? saveForm : null} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    return (
        <DraggableItemContainer
            index={index}
            onDrop={onDrop}
            onMove={onMove}
            item={item}
            canDrag={Boolean(item)}
            type="Item"
        >
            <div style={{ width: '100%' }}>
                <Row>
                    <Col sm={1} className='align-self-center'>{item ? <HandleIcon color="#DAE9EE" altText={form.label || ''} /> : ''}</Col>
                    {item || showForm ? getTextInput('label', 10, item ? true : false) : null}

                    {!item && !showForm ?
                        <Col sm={12} style={{ textAlign: 'center' }}>
                            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                                <AddButton />
                            </Button>
                        </Col>
                        : null}

                    <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                        {item ? <IconButton preset='delete' onClick={() => {
                            setPopupOpen(true);
                            setDeleteItem(item);
                        }} /> : (showForm ? <Button size="small" onClick={() => { saveForm(); }}>Add</Button> : null)
                        }
                    </Col>
                </Row>
                {deletePopup}
            </div>
        </DraggableItemContainer>
    );
};

export default CompanyNextActionType;
