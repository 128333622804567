import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

const getAccountStatusAdapter = () => createEntityAdapter({
    selectId: (object) => object.account_status_id,
    sortComparer: (a, b) => {
        return (a.account_status_name + a.account_status_id).localeCompare(b.account_status_name + b.account_status_id);
    }
});

const getGeneralSlice = (name, adapter) => createSlice({
    name: name,
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        added: adapter.addOne,
        deleted: adapter.removeOne,
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        updated: adapter.updateOne
    }
});

const getAccountStatusThunkActions = ({ loading, received, added, loaded, updated, deleted }, company_type = 'CLIENT') => {
    const getAll = () => async (dispatch, getState) => {
        dispatch(loading());
        const res = await oauth('GET', 'account-status', { company_type: company_type });
        dispatch(received(res.json.account_statuses));
        return res.json;
    };

    const addItem = (data) => async (dispatch, getState) => {
        dispatch(loading());
        const res = await oauth('POST', 'account-status', data);
        dispatch(added(Object.assign(res.json.account_status, { new: true })));
        dispatch(loaded());
        return res.json.account_status;
    };

    const updateItem = (data) => async (dispatch, getState) => {
        console.log(company_type, 'Update client status', data);
        dispatch(loading());
        const res = await oauth('PUT', 'account-status', data);
        dispatch(updated({ id: data.account_status_id, changes: res.json.account_status }));
        dispatch(loaded());
        return res.json.account_status;
    };

    const deleteItem = (data) => async (dispatch, getState) => {
        dispatch(loading());
        try {
            await oauth('DELETE', 'account-status/' + data.account_status_id, {});
        } catch (err) {
            console.log('Failed to delete');
            return false;
        }
        dispatch(deleted(data.account_status_id));
        dispatch(loaded());
        return true;
    };

    return {
        getAll,
        addItem,
        updateItem,
        deleteItem,
    };
};

export {
    getAccountStatusAdapter,
    getGeneralSlice,
    getAccountStatusThunkActions,
};
