import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Button, LabeledInput, Col, Row, IconButton } from '@commonsku/styles';
import useConfirmDelete from '../hooks/useConfirmDelete';
import RichTextEditor from '../../LazyRichTextEditorTinymce';

export default function EmailTemplate({
    item, saveItem, deleteItem
}) {
    const defaultValues = useMemo(() => ({
        email_template_text: '',
        email_template_title: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);

    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem, message: 'You are about to delete the template ' + form.email_template_title + '.' });

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['email_template_title', 'email_template_text']) {
            if (data[field].trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, label, col = 4) => {
        const hasError = formErrors[field] !== '';
        const Component = LabeledInput;
        return (
            <Col padded sm={col}>
                <Component
                    label={label}
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <Row>
            {getTextInput('email_template_title', 'Template Name', 11)}

            {!item ?
                (<Col padded sm={1} style={{ alignSelf: 'center' }} >
                    <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                </Col>)
                : null}

            {item ?
                <Col padded sm={1} style={{ alignSelf: 'center' }} >
                    <IconButton preset='delete' onClick={() => {
                        setPopupOpen(true);
                        setDeleteItem(item);
                    }} />
                </Col>
                : null}

            <Col padded sm={12}>
                <RichTextEditor value={form.email_template_text}
                    onChange={(value) => {
                        setFormFieldValue({
                            target: {
                                name: 'email_template_text',
                                value: value,
                            }
                        });
                    }}
                    onBlur={() => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
            </Col>
            {formErrors['email_template_text'] !== '' ? <span style={{ color: 'red' }}>{formErrors['email_template_text']}</span> : ''}

            {deletePopup}

        </Row>
    );

    return formFields;
}
