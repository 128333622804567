import React from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyCommissionService';
import useCompanyService from './hooks/useCompanyService';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';

export default function AdminCompanyService() {
    const [items, , save, remove] = useCompanyService();

    const list = items.map((item, index) => {
        return (
            <div key={item.service_id} data-testid={`row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove}  />
                </Row>
            </div>
        );
    });

    return (
        <>
            <TopInfo message='Choose to automatically include or exclude freight, fulfillment, and shipping on your margin and commission calculations.' link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#excludes' />
            {list}
            <ReactTooltip />
        </>
    );
}
