import React from 'react';
import { InfoIcon } from '@commonsku/styles';

export default function TopInfo({ message, link, linkLabel, buttons = [], showLink = true }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#EDF4F7', borderRadius: '5px', margin: '0 -8px 10px -8px', padding: '1rem' }}>
            <div style={{ flexGrow: 1 }}>
                {message}
            </div>
            {!showLink ? null : <div style={{ display: 'flex', alignItems: 'center', minWidth: '110px' }}>
                <InfoIcon width='16px' />
                <a target='_blank' href={link} style={{ marginLeft: '8px' }}>{linkLabel || 'Learn More'}</a>
            </div>}
            {buttons.map(b => <div key={b.key} style={{ marginLeft: '0.5rem' }}>{b}</div>)}
        </div>
    );
}
