import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Row, Col } from '@commonsku/styles';
import Avatar from 'react-avatar';

export default function CompanyCommissionSalesTarget({
    item, saveItem, rates
}) {
    const defaultValues = useMemo(() => ({
        sales_target: '',
    }), []);

    const [form, setForm] = useState(defaultValues);

    useEffect(() => {
        if (item) {
            let initValues = defaultValues;
            initValues = {
                sales_target: Number(item.sales_target).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            };
            setForm(initValues);
        }
    }, [defaultValues, item]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        let targetValue = Number(target.value.replaceAll(',', ''));
        if (isNaN(targetValue)) targetValue = 0;
        setForm((form) => {
            const value = targetValue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const newValues = { ...form, [target.name]: value };
            return newValues;
        });
    }, []);

    const targets = useMemo(() => rates.map((r, index) => {
        const targetValue = Number(form.sales_target.replaceAll(',', ''));
        let max = Number(rates[index + 1]?.sales_target_min / 100 * targetValue).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return {
            min: Number(r.sales_target_min / 100 * targetValue).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            max: isNaN(Number(max.replaceAll(',', ''))) ? '∞' : max,
        };
    }), [form.sales_target, rates]);

    let formFields = (
        <>
            <td style={{ borderRight: '1px dashed #ececec' }}>
                <Row style={{ alignItems: 'center' }}>
                    <Col sm={1} padded>
                        <Avatar src={item.user_image_paths?.small || null} name={item.contact_full_name} round={true} size='30' />
                    </Col>
                    <Col sm={5} padded>
                        {item.contact_full_name}
                    </Col>
                    <Col sm={6} padded style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '0.5rem' }}>$</span>
                        <Input
                            style={{ marginBottom: '0' }}
                            name='sales_target'
                            value={form['sales_target']}
                            onChange={setFormFieldValue}
                            onBlur={(e) => {
                                saveItem(item, 'sales_target', Number(e.target.value.replaceAll(',', '')));
                            }}
                        />
                    </Col>
                </Row>
            </td>
            {
                targets.map((t, index) => (
                    <td key={item.user_id + '-td-' + index}>
                        {`$${t.min} - $${t.max}`}
                    </td>
                ))
            }
        </>
    );

    return formFields;
}
