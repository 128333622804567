import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { LabeledCheckbox, Row, Col } from '@commonsku/styles';
import TopInfo from '../misc/Info';

export default function StripePaymentMethods({ data, save }) {
    const defaultValues = useMemo(() => ({
        stripe_payment_cc: '1',
        stripe_payment_ach: '0',
    }), []);

    const [form, setForm] = useState(defaultValues);

    useEffect(() => {
        let values = defaultValues;
        values = {
            stripe_payment_cc: data.stripe_payment_cc ?? 1,
            stripe_payment_ach: data.stripe_payment_ach ?? 0,
        };
        setForm(values);
    }, [data.stripe_payment_ach, data.stripe_payment_cc, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    if (data.stripe_country !== 'US') {
        return null;
    }

    const getCheckbox = (field, label = null, col = 4) => {
        return (
            <Col padded sm={col}>
                <LabeledCheckbox label={label} checked={form[field] == '1'} onChange={(e) => {
                    if (field === 'stripe_payment_cc' && form['stripe_payment_ach'] == 0 && form['stripe_payment_cc'] == 1) {
                        // Not allowed to turn off cc if ach is off too
                        return;
                    }

                    if (field === 'stripe_payment_ach' && form['stripe_payment_cc'] == 0 && form['stripe_payment_ach'] == 1) {
                        // Not allowed to turn off ach if cc is off too
                        return;
                    }

                    setFormFieldValue({
                        target: {
                            name: field,
                            value: form[field] == '1' ? '0' : '1',
                        }
                    });
                    save({ [field]: form[field] == '1' ? '0' : '1' });
                }} />
            </Col>
        );
    };

    const formFields = (
        <>
            <Row>
                <Col md={12}>
                    <TopInfo
                        message={(
                            <>
                                If both Credit Card and ACH are enabled, customers will be able to choose which payment method they want to use.
                                The settings on this page will affect the options available to customers for all future payments.
                            </>
                        )}
                        link="#"
                    >
                    </TopInfo>
                </Col>
                {getCheckbox('stripe_payment_cc', 'Credit Card', 12)}
                {getCheckbox('stripe_payment_ach', 'ACH', 12)}
            </Row>
        </>
    );

    return formFields;
}
