import React, { useEffect } from 'react';
import { Row, Col, InfoIcon } from '@commonsku/styles';
import Form from './form/CompanyCommissionRate';
import useCompanyCommissionRate from './hooks/useCompanyCommisionRate';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';
import { rebuildTooltip } from '../helpers';

export default function AdminCommissionRate() {
    const [items, , save, remove] = useCompanyCommissionRate();

    useEffect(() => {
        if (items?.length > 0) {
            rebuildTooltip();
        }
    }, [items?.length]);

    const list = items.map((item) => {
        return (
            <Row key={item.commission_rate_id}>
                <Form item={item} saveItem={save} deleteItem={remove} />
            </Row>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Create a commission rate for each unique commission percentage. You can create a single rate if you pay all reps the same or create multiple rates for the different percentages.' link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#base-rate' />
            <Row>
                <Col padded sm={6}>
                    <b>Label</b>
                </Col>
                <Col padded sm={2}>
                    <b>Base Rate</b>
                </Col>
                <Col padded sm={3}>
                    <b>Type</b>
                </Col>
                <Col padded sm={1}>
                </Col>
            </Row>
            {list}
            {add}
            <ReactTooltip />
        </>
    );
}
