import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
active: "1"
created_by: "d6baac9c-9327-48f9-b51e-f3378a894f30"
date_created: "2011-10-27 00:00:00"
description: "Tax Exempt"
editable: "0"
label: "E"
percent: "0.000"
tax_exempt: "1"
tax_id: "b260d7ca-fff9-11e0-b240-1231392503bd"
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
tenant_registration: ""
zip2tax_id: null
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.tax_id,
    sortComparer: (a, b) => {
        if (a.tax_exempt == 1) {
            if (b.tax_exempt == 1) {
                return (a.label + a.description + a.percent).localeCompare(b.label + b.description + b.percent);
            } else {
                return -1;
            }
        } else {
            if (b.tax_exempt == 1) {
                return 1;
            } else {
                return (a.label + a.description + a.percent).localeCompare(b.label + b.description + b.percent);
            }
        }
    }
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyTaxes', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'tax';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.taxes));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.tax, {new: true})));
    dispatch(loaded());
    return res.json.tax;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.tax_id, data);
    dispatch(updated({ id: data.tax_id, changes: data }));
    dispatch(loaded());
    return res.json.tax;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.tax_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.tax_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
