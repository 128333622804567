import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PopupViewProps, TagInfo } from './types';
import { EmptyStateArrow, H1, H2, IconButton, InfoIcon, Row } from '@commonsku/styles';
import { AddTag } from './AddTag';
import './NoTagsView.css';

const MIN_ARROW_HEIGHT = 300;

export const NoTagsTitle = () => null;

export const NoTagsView = ({
    onNewTags,
    onUpdateView,
}: PopupViewProps) => {
    const arrowTopRef = useRef<HTMLDivElement>(null);
    const arrowBottomRef = useRef<HTMLDivElement>(null);
    const boundingRef = useRef<HTMLDivElement>(null);
    const [arrowHeight, setArrowHeight] = useState(0);
    const [arrowBase, setArrowBase] = useState(0);

    useEffect(() => {
        if (boundingRef.current == null) {
          return;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (arrowTopRef.current == null || arrowBottomRef.current == null) {
                return;
            } 

            const top = arrowTopRef.current.offsetTop;
            const bottom = arrowBottomRef.current.offsetTop;
            const style = getComputedStyle(arrowTopRef.current);
            const width = arrowTopRef.current.offsetWidth 
                - parseFloat(style.paddingLeft) 
                - parseFloat(style.paddingRight);

            setArrowHeight(bottom - top);
            setArrowBase(width);
        });
        
        resizeObserver.observe(boundingRef.current);
    
        return () => resizeObserver.disconnect();
      },
      []
    );

    const onAddTag = useCallback((tag: TagInfo) => {
        onNewTags([tag]);
        onUpdateView('table');
    }, [onNewTags]);

    return (
        <div className="no-tags" ref={boundingRef}>
            <div className="no-tags-content">
                <Row className="no-tags-info">
                    <H1>You have no tags set up</H1>
                    Tags are a powerful tool to organize commonsku on a micro-level.
                    <ul>
                        <li>Tag <b>projects</b> to group reporting on a project level. Track reorders, referral sources, or whether the order was made through a commonsku Shop.</li>
                        <li>Tag <b>clients</b>, and filter reports by those tags.</li>
                        <li>Tag <b>contacts</b>, to organize and find them.</li>
                        <li>Tag <b>suppliers</b> to more easily find them. Do they offer free samples? Do you have EQP with them?</li>
                        <li>Tag <b>products</b> to quickly find them when adding them to an order.</li>
                    </ul>
                </Row>
                <Row className="no-tags-cta" ref={arrowTopRef}>
                    <H2>Start adding tags below</H2>
                    "Preferred", "EQP", etc.
                    {arrowHeight > MIN_ARROW_HEIGHT &&
                        <div className="arrow-container">
                            <EmptyStateArrow
                                direction="down"
                                size="medium"
                                style={{ height: arrowHeight, width: arrowBase }}
                                transform={`scale(${1.6 * (arrowHeight / 500)})`}
                            />
                        </div>
                    }
                </Row>
            </div>
            <Row className="no-tags-actions" ref={arrowBottomRef}>
                <AddTag
                    onAddTag={onAddTag}
                    isDuplicateTag={() => false}
                    highlight
                />
                <a
                    href="https://help.commonsku.com/knowledge/tags"
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconButton
                        className="info-button"
                        Icon={<InfoIcon />}
                    >
                        Learn more about tags
                    </IconButton>
                </a>
            </Row>
        </div>
    );
}
