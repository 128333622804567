import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, Row, LabeledSelect, IconButton, LabeledIconInput } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

export default function CompanyShipAccount({
    couriers, item, saveItem, deleteItem
}) {
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        courier_id: '',
        account_name: '',
        account_number: '',
        account_postal: '',
        third_party_account_id: '',
    }), []);

    const [form, setForm] = useState(item || defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);
    const selectedCourier = couriers?.find(c => c.value === form['courier_id']);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['account_name', 'courier_id', 'account_number', 'account_postal']) {
            if (data[field].trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4, hasIcon = false) => {
        const hasError = formErrors[field] !== '';
        const Component = hasIcon ? LabeledIconInput : Input;
        if (hasIcon) {

        }
        return (
            <Col padded sm={col}>
                <Component
                    style={{ marginBottom: '0' }}
                    Icon={<div>#</div>}
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <>
            {getTextInput('account_name', 4)}

            <Col padded sm={2}>
                <LabeledSelect
                    label=""
                    name="courier_id"
                    value={selectedCourier}
                    options={couriers}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: 'courier_id', value: selected.value } });

                        // Direct save item call
                        if (item?.third_party_account_id) {
                            saveItem({ ...form, courier_id: selected.value });
                        }
                    }}
                />
                {formErrors['courier_id'] !== '' ? <span style={{ color: 'red' }}>{formErrors['courier_id']}</span> : ''}
            </Col>

            {getTextInput('account_number', 3, true)}
            {getTextInput('account_postal', 2)}

            {!item ?
                (<Col padded sm={1} style={{ textAlign: 'left' }} >
                    <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                </Col>)
                : null}

            {item ?
                <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                    <IconButton preset='delete' onClick={() => {
                        setPopupOpen(true);
                        setDeleteItem(item);
                    }} />
                </Col>
                : <Col sm={1}></Col>}
            {deletePopup}
        </>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
}
