import React from 'react';
import XeroAdminNew from '../XeroAdminNew';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';

export default function AdminXero() {
    const [, , , , , reloadData] = useCompanyGeneralData();
    return (
        <>
            <XeroAdminNew reloadData={reloadData} />
        </>
    );
}
