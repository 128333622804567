import React from 'react';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import withReducers from '../../store/withReducers';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import moment from 'moment';
import { UsersIcon } from '@commonsku/styles';
import Features from '../../constants/Features';
import { isPast } from '../../utils';

function AdminBillingRenewalSummary({ title }) {
    const [ { data } ] = useCompanyGeneralData();
    const onContract = !!data.contracted_year;

    let summary: string;

    if (!onContract && !data.churning) {
        summary = 'Upcoming changes to your commonsku account';
    } else {
        const now = new Date();
        const pastMigrationDueDate: boolean = !data.migration_due_date ? true : isPast(data.migration_due_date);
        const nextAction = data.churning ? 'end' : (
          pastMigrationDueDate ? 'automatically renew' : 'start'
        );
      
        const nextActionDate = data.churning ?? (
          pastMigrationDueDate ? data.renewal_date : data.migration_due_date
        );

        summary = `Your contract will ${nextAction} on ${moment(nextActionDate).format("MMM [1st] YYYY")}`;
    }

    return (
        <div style={{ display: 'flex' }}>
            <div><UsersIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <p className='tile-summary-content'>{summary}</p>
            </div>
        </div>
    );
}

export default withReducers(AdminBillingRenewalSummary, {
    companyGeneralData: companyGeneralDataReducer,
});
