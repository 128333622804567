import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { oauth } from '../../../utils';

export default function useQuickbookGetMappingOptions() {
    const dispatch = useDispatch();
    const [qboCurrencies, setQboCurrencies] = useState([]);
    const [qboItems, setQboItems] = useState([]);
    const [qboTaxCodes, setQboTaxCodes] = useState([]);
    const [qboAccounts, setQboAccounts] = useState([]);
    const [qboCustomFields, setQboCustomFields] = useState([]);
    const [qboInvoiceFields, setQboInvoiceFields] = useState([]);
    const [qboClasses, setQboClasses] = useState([]);

    /*
    $this->qboTaxCodes = Commonsku_Core_Functions::getQboTaxCodes();
    $this->qboItems = Commonsku_Core_Functions::getQboItems();
    $this->qboAccounts = Commonsku_Core_Functions::getQboAccounts();
    $this->qboCustomFields = Commonsku_Core_Functions::getQboCustomFields();
    $this->qboCurrencies = Commonsku_Core_Functions::getQboCurrencies();
    $this->qboInvoiceFields = Commonsku_Core_Functions::getQboInvoiceFields();
    $this->qboClasses = Commonsku_Core_Functions::getQboClasses();
    */
    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_tax_codes'});
            const items = [];
            for (const key in res.json.taxes) {
                items.push({name: res.json.taxes[key], ref: key});
            }
            setQboTaxCodes(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_items'});
            const items = [];
            for (const key in res.json.items) {
                items.push({name: res.json.items[key], ref: key});
            }
            setQboItems(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_accounts'});
            const items = [];
            for (const key in res.json.accounts) {
                items.push({...res.json.accounts[key], ref: key});
            }
            setQboAccounts(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_custom_fields'});
            const items = [];
            for (const key in res.json.custom_fields) {
                items.push(res.json.custom_fields[key]);
            }
            setQboCustomFields(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_currencies'});
            const items = [];
            for (const key in res.json.currencies) {
                items.push(res.json.currencies[key]);
            }
            setQboCurrencies(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_invoice_fields'});
            const items = [];
            for (const key in res.json.invoice_fields) {
                items.push(res.json.invoice_fields[key]);
            }
            setQboInvoiceFields(items);
        };
        call();
    }, [dispatch]);

    useEffect(() => {
        const call = async () => {
            const res = await oauth('POST', 'qbo', {action: 'get_classes'});
            const items = [];
            for (const key in res.json.classes) {
                items.push(res.json.classes[key]);
            }
            setQboClasses(items);
        };
        call();
    }, [dispatch]);

    return [qboCurrencies, qboItems, qboTaxCodes, qboAccounts, qboCustomFields, qboInvoiceFields, qboClasses];
};
