import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem, updateItem, deleteItem } from '../../../redux/emailTemplates';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function useEmailTemplate(setSelected) {
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state.emailTemplates);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll('ADMIN'));
    }, [dispatch]);

    const save = useCallback((item) => {
        const updateCall = async () => {
            let itemUpdated = null;
            if (item.email_template_id) {
                itemUpdated = await dispatch(updateItem({ id: item.email_template_id, ...item }));
            } else {
                itemUpdated = await dispatch(addItem(item));
            }
            toast.success("Saved successfully");
            setSelected(itemUpdated);
        };

        updateCall();
    }, [dispatch, setSelected]);

    const remove = useCallback((item) => {
        const deleteCall = async () => {
            await dispatch(deleteItem(item));
            toast.success("Deleted successfully");
            setSelected('first');
        };

        deleteCall();
    }, [dispatch, setSelected]);

    return [
        items,
        loading,
        save,
        remove
    ];
};
