import React from 'react';
import { GearIcon, LightIndicator } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import useCompanyAddress from './hooks/useCompanyAddress';
import useCompanyTerm from './hooks/useCompanyTerm';
import useCompanyTax from './hooks/useCompanyTax';
import useCompanyNextActionType from './hooks/useCompanyNextActionType';
import useCompanyShipAccount from './hooks/useCompanyShipAccount';
import useCompanyImprint from './hooks/useCompanyImprint';
import useClientStatus from './hooks/useClientStatus';
import useSupplierStatus from './hooks/useSupplierStatus';
import useDistributorStatus from './hooks/useDistributorStatus';
import useCompanyShipMethod from './hooks/useCompanyShipMethod';
import useCompanyBenefits from './hooks/useCompanyBenefits';
import { useIdentity } from '../../hooks';

export default function AdminSetupSummary({ title }) {
    const identity = useIdentity();
    const [data] = useCompanyGeneralData();
    const [addresses] = useCompanyAddress();
    const [terms] = useCompanyTerm();
    const [taxes] = useCompanyTax();
    const [actions] = useCompanyNextActionType();
    const [shipAccounts] = useCompanyShipAccount();
    const [imprints] = useCompanyImprint();
    const [clientStatuses] = useClientStatus();
    const [supplierStatuses] = useSupplierStatus();
    const [distributorStatuses] = useDistributorStatus();
    const [shipMethods] = useCompanyShipMethod();
    const [benefits] = useCompanyBenefits();
    return (
        <div style={{ display: 'flex' }}>
            <div><GearIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${addresses?.length} addresses`} on={addresses?.length > 0 ? true : false} />}
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${terms?.length} payment terms`} on={terms?.length > 0 ? true : false} />}
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${taxes?.length} tax codes`} on={taxes?.length > 0 ? true : false} />}
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${actions?.length} next action types`} on={actions?.length > 0 ? true : false} />}
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${shipAccounts?.length} shipping accounts`} on={shipAccounts?.length > 0 ? true : false} />}
                {'SUPPLIER' !== identity.company_type && <LightIndicator name={`${imprints?.length} imprint methods`} on={imprints?.length > 0 ? true : false} />}
                <LightIndicator name={`${clientStatuses?.length + supplierStatuses?.length + distributorStatuses?.length} statuses`} on={(clientStatuses?.length + supplierStatuses?.length + distributorStatuses?.length) > 0 ? true : false} />
                <LightIndicator name={`${shipMethods?.length} ship methods`} on={shipMethods?.length > 0 ? true : false} />
                {'SUPPLIER' === identity.company_type && <LightIndicator name={`${benefits?.filter(b => b.supplier_benefit_id)?.length} benefits`} on={benefits?.filter(b => b.supplier_benefit_id)?.length > 0 ? true : false} />}
            </div>
        </div>
    );
}
