import React, { Component, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { parseMysqlDate, formatMoney } from '../utils';

import { window } from '../global';

const NoFeeRow = () => (
  <tr>
    <td colSpan="12">
      <p><a href="https://help.commonsku.com/knowledge/shops-overview" target="_blank">Learn more about commonsku Shops.</a></p>
    </td>
  </tr>
);

const ShopFeeRow = ({ fee, chargedDate, onClick }) => {
  return (
    <div
      className={chargedDate === fee.charged_date ? "fee-box selected" : "fee-box"}
      onClick={onClick}
    >
      <div className="header-date">{parseMysqlDate(fee.charged_date)}</div>
      <div className="header-fee">{formatMoney(fee.charged_sum)} {fee.charged_currency_converted}</div>
      {chargedDate === fee.charged_date && <ShopTable fee={fee} />}
    </div>
  );
};

const InvoiceTable = ({ shop }) => {
  const { headerStyle, tdTableStyle, tableStyle, tbodyStyle } = style;

  return (
    <tr>
      <td colSpan="5" style={tdTableStyle}>
        <table className="inner-table" style={tableStyle}>
          <thead>
            <tr>
              <td>Project Name</td>
              <td>Created</td>
              <td style={{ textAlign: 'right' }}>Value (Order Currency)</td>
              <td style={{ textAlign: 'right' }}>Converted Value ({shop.charged_currency_converted})</td>
              <td style={{ textAlign: 'right' }}>Fee ({shop.charged_currency_converted})</td>
            </tr>
          </thead>
          <tbody style={tbodyStyle}>
            {Object.values(shop.invoices).map(i =>
              <InvoiceRow key={i.order_id} invoice={i} />
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const InvoiceRow = ({ invoice })  => {
  return (
    <tr onClick={() => window.open(`${window.S3_URL}/charged_invoices/${invoice.order_id}`, '_blank')}>
      <td>#{invoice.job_number} &mdash; {invoice.job_name}</td>
      <td>{parseMysqlDate(invoice.date_created)}</td>
      <td style={{ textAlign: 'right' }}>{formatMoney(invoice.charged_order_subtotal)} {invoice.charged_order_currency}</td>
      <td style={{ textAlign: 'right' }}>{formatMoney(invoice.charged_subtotal_converted)}</td>
      <td style={{ textAlign: 'right' }}>{formatMoney(invoice.charged_fee)}</td>
    </tr>
  );
};

const ShopTable = ({ fee }) => {
  const [shopId, setShopId] = useState(null);
  return (
    <table className="outer-table" key={fee.charged_date + '-shops'}>
      <thead>
        <tr>
          <td>Shop Name</td>
          <td>Client</td>
          <td style={{ textAlign: 'right' }}>Invoices</td>
          <td style={{ textAlign: 'right' }}>Total Value ({fee.charged_currency_converted})</td>
          <td style={{ textAlign: 'right' }}>Total Fee ({fee.charged_currency_converted})</td>
        </tr>
      </thead>
      <tbody>
        {Object.values(fee.shops).map(s => <ShopRow key={s.shop_id} shop={s} selected={shopId === s.shop_id}  onClick={() => setShopId(s.shop_id)} />)}
      </tbody>
    </table>
  );
};

const ShopRow = ({ shop, selected, onClick }) => (
  <>
    <tr className={selected ? "outer-row selected" : "outer-row"} onClick={onClick}>
      <td>#{shop.shop_number} &mdash; {shop.shop_name}</td>
      <td>{shop.client_name}</td>
      <td className="invoice-count" style={{ textAlign: 'right' }}>{shop.shop_invoice_count}</td>
      <td style={{ textAlign: 'right' }}>{formatMoney(shop.shop_invoice_subtotal)}</td>
      <td style={{ textAlign: 'right' }}>{formatMoney(shop.shop_charged_sum)}</td>
    </tr>
    <InvoiceTable shop={shop} />
  </>
);

const getShopsFees = state => Object.values(state.entities.shops_fees);

const ShopsFeesView = () => {
  const [chargedDate, setChargedDate] = useState(null);
  const shop_fees = useSelector(getShopsFees);

  if (shop_fees.length > 0) {
    return (
      <div class="shop-fees">
        {shop_fees.map(f => <ShopFeeRow key={f.charged_date} chargedDate={chargedDate} onClick={() => setChargedDate(f.charged_date)} fee={f} />)}
      </div>
    );
  } else {
    return <NoFeeRow />;
  }
};

const mapStateToProps = (state) => {
    return {
        shops_fees: state.entities.shops_fees
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

const style = {
    headerStyle: {
        top: '120px',
        background: '#EDF2F5'
    },
    tdTableStyle: {
        padding: 0
    },
    tableStyle: {
        margin: 0
    }
};

export default ShopsFeesView;
