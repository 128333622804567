import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

/*
active: "1"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
date_created: "2022-07-26 08:11:03"
email_template_id: "9e8349b1-6b68-4846-b45b-15c21894893b"
email_template_text: "<!-- x-tinymce/html -->\n<h2>A simple table to play with</h2>"
email_template_title: "asdg"
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.email_template_id,
    sortComparer: (a, b) => {
        return (a.email_template_title + a.date_created).localeCompare(b.email_template_title + b.date_created);
    }
});

/**
 * The slice/reducers
 */
const slice = createSlice({
    name: 'emailTemplates',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        added: adapter.addOne,
        deleted: adapter.removeOne,
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        updated: adapter.updateOne
    }
});

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = (parent_type = 'ADMIN') => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'email-template',  {parent_type: parent_type});
    dispatch(received(res.json.email_templates));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'email-template', data);
    dispatch(added(Object.assign(res.json.email_template, {new: true})));
    dispatch(loaded());
    return res.json.email_template;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'email-template/' + data.email_template_id, data);
    dispatch(updated({ id: data.email_template_id, changes: res.json.email_template }));
    dispatch(loaded());
    return res.json.email_template;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        await oauth('DELETE', 'email-template/' + data.email_template_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    dispatch(deleted(data.email_template_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
