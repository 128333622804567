import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, Row, LabeledSelect, IconButton, HandleIcon } from '@commonsku/styles';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';
import { DraggableItemContainer } from '../../DraggableItem';

const CompanyShipMethod = ({
    couriers, item, saveItem, deleteItem,
    index, onMove, onDrop,
}) => {
    const [showForm, setShowForm] = useState(false);
    const defaultValues = useMemo(() => ({
        courier_id: '',
        ship_method_name: '',
        ship_method_id: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);

    const [formErrors, setFormErrors] = useState(defaultValues);
    const selectedCourier = couriers?.find(c => c.value === form['courier_id']);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['ship_method_name', 'courier_id']) {
            if (data[field].trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
        saveItem({ ...item, ...form });
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <Input
                    className='no-margin-bottom'
                    name={field} value={form[field]}
                    onChange={setFormFieldValue}
                    onBlur={(e) => {
                        if (item) {
                            saveForm(form);
                        }
                    }}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    let formFields = (
        <DraggableItemContainer
            index={index}
            onDrop={onDrop}
            onMove={onMove}
            item={item}
            canDrag={Boolean(item)}
            type="Item"
        >
            <div style={{ width: '100%' }}>
                <Row>
                    <Col sm={1} className="align-self-center">{item ? <HandleIcon color="#DAE9EE" altText={form.ship_method_name || ''} /> : ''}</Col>
                    {getTextInput('ship_method_name', 3)}

                    <Col padded sm={7}>
                        <LabeledSelect
                            label=""
                            name="courier_id"
                            value={selectedCourier}
                            options={couriers}
                            onChange={(selected) => {
                                setFormFieldValue({ target: { name: 'courier_id', value: selected.value } });

                                // Direct save item call
                                if (item?.ship_method_id) {
                                    saveItem({ ...form, courier_id: selected.value });
                                }
                            }}
                        />
                        {formErrors['courier_id'] !== '' ? <span style={{ color: 'red' }}>{formErrors['courier_id']}</span> : ''}
                    </Col>

                    {!item ?
                        (<Col padded sm={1} style={{ textAlign: 'left' }} >
                            <Button size='small' onClick={(e) => saveForm(form)}>{item ? 'Save' : 'Add'}</Button>
                        </Col>)
                        : null}

                    {item ?
                        <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                            <IconButton preset='delete' onClick={() => {
                                setPopupOpen(true);
                                setDeleteItem(item);
                            }} />
                        </Col>
                        : <Col sm={1}></Col>}
                    {deletePopup}
                </Row>
            </div>
        </DraggableItemContainer>
    );

    if (!item && !showForm) {
        formFields = <Col padded sm={12} style={{ textAlign: 'center' }}>
            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                <AddButton />
            </Button>
        </Col>;
    }

    return formFields;
};

export default CompanyShipMethod;
