import React from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyTaxNexus';
import useCompanyTaxNexus from './hooks/useCompanyTaxNexus';

export default function AdminCompanyTaxNexus() {
    const [items, loading, save, remove] = useCompanyTaxNexus();

    const list = items.map((item, index) => {
        return (
            <div key={item.tax_nexus_id} data-testid={`row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <h5 style={{ textAlign: 'center' }}>Tax Nexus</h5>
            {list}
            {add}
        </>
    );
};
