import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
state: "AZ"
tax_nexus_id: "087f85f9-e49e-4945-af3e-5d7a44e97bfc"
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.tax_nexus_id,
    sortComparer: (a, b) => (a.state).localeCompare(b.state)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyTaxNexus', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'tax-nexus';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.tax_nexus));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    let res;
    try {
        res = await oauth('POST', baseUrl, data);
    } catch (err) {
        return false;
    }

    dispatch(added(Object.assign(res.json.tax_nexus, {new: true})));
    dispatch(loaded());
    return res.json.tax_nexus;
};

export const updateItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.tax_nexus_id, data);
    dispatch(updated({ id: data.tax_nexus_id, changes: data }));
    dispatch(loaded());
    return res.json.tax_nexus;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.tax_nexus_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.tax_nexus_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
