import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, LabeledIconInput, Row, Col, LabeledCheckbox } from '@commonsku/styles';

export default function CompanyGeneralFormNumbers({ data, loading, save }) {
    const defaultValues = useMemo(() => ({
        next_job_number: '',
        next_presentation_form_number: '',
        next_order_form_number: '',
        next_purchase_order_form_number: '',
        po_with_job_number: 0,
    }), []);

    const formNumbers = data.form_numbers;

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    useEffect(() => {
        let values = defaultValues;
        if (formNumbers) {
            values = {
                next_job_number: parseInt(formNumbers.job_number) + 1,
                next_presentation_form_number: parseInt(formNumbers.presentation_number) + 1,
                next_order_form_number: parseInt(formNumbers.sales_order_number) + 1,
                next_purchase_order_form_number: parseInt(formNumbers.purchase_order_number) + 1,
                po_with_job_number: +data.po_with_job_number,
            };
        }
        setForm(values);
    }, [data.po_with_job_number, defaultValues, formNumbers]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['next_job_number', 'next_presentation_form_number', 'next_order_form_number', 'next_purchase_order_form_number']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        save(form);
    }, [form, save, validateForm]);

    const getTextInput = (field, label = null, col = 4) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <LabeledIconInput label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} onBlur={saveForm}
                    Icon={<div>#</div>}
                />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const formFields = (
        <Row>
            {getTextInput('next_job_number', 'Next Project', 6)}
            {getTextInput('next_presentation_form_number', 'Next Presentation', 6)}
            {getTextInput('next_order_form_number', 'Next Order', 6)}
            {getTextInput('next_purchase_order_form_number', 'Next Purchase Order', 6)}

            <Col padded sm={12} style={{ textAlign: 'right' }} >
                <LabeledCheckbox label={<div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ textAlign: 'left', fontWeight: 'bold' }}>Add the project number as a prefix on each purchase order</div>
                    <div>This simplifies communications between your suppliers and decorators</div>
                </div>} checked={form['po_with_job_number'] === 1} onChange={(e) => {
                    setFormFieldValue({
                        target: {
                            name: 'po_with_job_number',
                            value: form['po_with_job_number'] === 1 ? 0 : 1,
                        }
                    });
                    save({ po_with_job_number: form['po_with_job_number'] === 1 ? 0 : 1 });

                }} />
            </Col>
        </Row>
    );

    return formFields;
}
