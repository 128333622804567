import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
company_id: "60d613ea-c3db-4265-9a46-442553c8619a"
created_by: "36fb1277-c48b-440c-819b-f6c9c79a02ed"
date_created: "2022-06-10 15:05:06"
mapped_field: "CUSTOMER-PO"
qbo_custom_field_id: "b959f38d-98de-4074-9943-ecae979d0e88"
qbo_custom_field_ref: "1"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.qbo_custom_field_id
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyQboCustomFields', adapter);

export const {
    added,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'qbo-custom-field', data);
    dispatch(added(Object.assign(res.json.qbo_custom_field, {new: true})));
    dispatch(loaded());
    return res.json.qbo_custom_field;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'qbo-custom-field/' + data.qbo_custom_field_id, data);
    dispatch(updated({ id: data.qbo_custom_field_id, changes: data }));
    dispatch(loaded());
    return res.json.qbo_custom_field;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
