import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { oauth } from '../../../utils';

export default function useClients() {
    const [clients, setClients] = useState([]);
    const [rate, setRate] = useState(null);

    let filteredClients = clients;
    if (!!rate) {
        filteredClients = clients.filter(c => c.commission_client_rate_id === rate);
    }

    const loadClients = useCallback((company_name = '', rate_id = false) => {
        setRate(rate_id);
        const call = async () => {
            const res = await oauth('GET', 'client',  {
                'max-results': 100,
                'order-by': 'latest_use',
                'order-dir': 'desc',
                'rate-id': rate_id,
                search: company_name,
            });
            setClients(res.json?.clients);
        };
        call();
    }, []);

    const saveClient = useCallback((clientId, data) => {
        console.log('save data', data);
        const updateCall = async () => {
            const res = await oauth('PUT', 'account/' + clientId,  {
                company_type: 'CLIENT',
                commission_client_rate_id: data.commission_client_rate_id,
            });
            toast.success("Saved successfully");
        };

        updateCall();
    }, []);

    return [
        filteredClients,
        loadClients,
        saveClient,
    ];
};
