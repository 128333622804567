import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { oauth, makeCancelable } from '../../../utils';

export default function useCouriers() {
    const dispatch = useDispatch();
    const [couriers, setCouriers] = useState([]);

    useEffect(() => {
        const { cancel, promise } = makeCancelable(oauth('GET', 'courier', {}));
        promise.then(
            (res) => setCouriers(
                res.json.couriers.map(c => ({ label: c.courier_label, value: c.courier_id }))
            ),
            () => {}
        );
        return cancel;
    }, [dispatch]);

    return couriers;
};
