import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
active: "1"
created_by: ""
date_created: "2015-09-08 21:10:13"
default_cost: "0.0000"
exclude_from_margin: "0"
service_description: ""
service_id: "861fb1f5-568f-11e5-95af-0acec3a87af1"
service_name: "Fulfillment"
supplier_id: ""
tenant_id: "60d613ea-c3db-4265-9a46-442553c8619a"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.service_id,
    sortComparer: (a, b) => (a.service_name).localeCompare(b.service_name)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyServices', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'service';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.services));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(added(Object.assign(res.json.service, {new: true})));
    dispatch(loaded());
    return res.json.service;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.service_id, data);
    dispatch(updated({ id: data.service_id, changes: data }));
    dispatch(loaded());
    return res.json.service;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.service_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.service_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
