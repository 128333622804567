import React, { useEffect } from 'react';
import { LightIndicator, TableIcon } from '@commonsku/styles';
import { useSelector, useDispatch } from 'react-redux';

import {
    createLoadDecoratorList
} from '../../actions/admin_decoration_charges';

export default function AdminDecoratorMatrixSummary({ title }) {
    const dispatch = useDispatch();
    const decorators = useSelector(state => state.dropdowns?.decorators);

    useEffect(() => {
        dispatch(createLoadDecoratorList());
    }, [dispatch]);

    return (
        <div style={{ display: 'flex' }}>
            <div><TableIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <LightIndicator name={`${decorators?.length} decorators configured`} on={decorators?.length > 0 ? true : false} />
            </div>
        </div>
    );
}
