import React from 'react';
import Form from './form/CompanyGeneralDefaults';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import TopInfo from './misc/Info';
import useCompanyTax from './hooks/useCompanyTax';

export default function AdminCompanyGeneralDataDefaults() {
    const [data, loading, save, saveExtra] = useCompanyGeneralData();
    const [taxes] = useCompanyTax();

    return (
        <>
            <TopInfo message='Set your preferences for your commonsku sales and purchase forms.' link='https://help.commonsku.com/knowledge/other-options' />
            <Form data={data.data} loading={loading} save={save} saveExtra={saveExtra} taxes={taxes} />
        </>
    );
}
