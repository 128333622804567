import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

/*
active: "1"
address_city: "Maitland"
address_company: "Billing Address"
address_country: "United States"
address_default: "NONE"
address_id: "9e1e4962-d788-42f3-a3c1-deab71e6d5ab"
address_line_1: "2515 Chanute Trail"
address_line_2: ""
address_line_3: ""
address_line_4: ""
address_name: "Head Office"
address_postal: "32751"
address_state: "FL"
address_type: "BILLING"
avalara_resolution: ""
created_by: ""
date_created: "2018-02-20 22:57:33"
date_exported: null
date_updated: "2019-12-30 14:42:12"
ignore_tax_on_shipping: "0"
parent_id: "60d613ea-c3db-4265-9a46-442553c8619a"
parent_type: "TENANT"
tax_id: null
tenant_default_address: false
test: "0"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.address_id,
    sortComparer: (a, b) => {
        return (a.address_name + a.address_id).localeCompare(b.address_name + b.address_id);
    }
});

/**
 * The slice/reducers
 */
const slice = createSlice({
    name: 'companyAddresses',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        added: adapter.addOne,
        deleted: adapter.removeOne,
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        updated: adapter.updateOne
    }
});

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getAll = (companyId) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', 'address',  {parent_id: companyId});
    dispatch(received(res.json.addresses));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', 'address', data);
    dispatch(added(Object.assign(res.json.address, {new: true})));
    dispatch(loaded());
    return res.json.address;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', 'address/' + data.address_id, data);
    dispatch(updated({ id: data.address_id, changes: res.json.address }));
    dispatch(loaded());
    return res.json.account_status;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        await oauth('DELETE', 'address/' + data.address_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    dispatch(deleted(data.address_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
