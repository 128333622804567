import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Button, Input, Col, Row, HandleIcon, IconButton } from '@commonsku/styles';
import { DraggableItemContainer } from '../../DraggableItem';
import AddButton from '../misc/AddButton';
import useConfirmDelete from '../hooks/useConfirmDelete';

const CompanyTerm = ({
    item, saveItem, deleteItem,
    index, onMove, onDrop,
}) => {
    const [showForm, setShowForm] = useState(false);
    const [deletePopup, setPopupOpen, setDeleteItem] = useConfirmDelete({ deleteItemCallback: deleteItem });

    const defaultValues = useMemo(() => ({
        terms_name: '',
        terms_id: '',
        display_order: '',
        date_created: '',
        company_type: '',
        company_id: '',
        company_term_id: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    useEffect(() => {
        setForm(item || defaultValues);
    }, [defaultValues, item]);
    const [formErrors, setFormErrors] = useState(defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['terms_name']) {
            if (data[field].trim() === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const saveForm = useCallback(() => {
        if (!validateForm(form)) {
            return;
        }
        saveItem({ ...item, ...form });
        if (!item) {
            setForm(defaultValues);
            setShowForm(false);
        }
    }, [defaultValues, form, item, saveItem, validateForm]);

    const getTextInput = (field, col = 4, onBlurSave = true) => {
        const hasError = formErrors[field] !== '';
        return (
            <Col padded sm={col}>
                <Input className='no-margin-bottom' name={field} value={form[field]} onChange={setFormFieldValue} onBlur={onBlurSave ? saveForm : null} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </Col>
        );
    };

    const formFields = (
        <DraggableItemContainer
            index={index}
            onDrop={onDrop}
            onMove={onMove}
            item={item}
            canDrag={Boolean(item)}
            type="Item"
        >
            <div style={{ width: '100%' }}>
                <Row>
                    <Col padded sm={1} className="align-self-center">{item ? <HandleIcon color="#DAE9EE" altText={form.terms_name || ''} /> : ''}</Col>
                    {item || showForm ? getTextInput('terms_name', 10, item ? true : false) : null}

                    {!item && !showForm ?
                        <Col padded sm={12} style={{ textAlign: 'center' }}>
                            <Button className='add-button' onClick={() => { setShowForm(true); }}>
                                <AddButton />
                            </Button>
                        </Col>
                        : null}

                    <Col padded sm={1} style={{ textAlign: 'left', paddingLeft: '1rem' }} >
                        {item ? <IconButton preset='delete' onClick={() => {
                            setDeleteItem(item);
                            setPopupOpen(true);
                        }} /> : (showForm ? <Button size="small" onClick={() => { saveForm(); }}>Add</Button> : null)
                        }
                    </Col>
                </Row>
                {deletePopup}
            </div>
        </DraggableItemContainer>
    );

    return formFields;
};

export default CompanyTerm;
