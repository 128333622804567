import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

import { oauth } from '../utils';

const adapter = createEntityAdapter({
    selectId: (division) => division.division_id,
    sortComparer: (a, b) => a.division_name.localeCompare(b.division_name)
});

const slice = createSlice({
    name: 'companyDivisions',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
    }
});

export const  {
    loading,
    received,
} = slice.actions;

const baseUrl = 'division';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl + '/toc',  { type: 'supplier' });
    dispatch(received(res.json.divisions));
    return res.json;
};

export const selectors = adapter.getSelectors();

export default slice.reducer;

