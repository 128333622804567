import React, { useEffect, useRef, useCallback } from 'react';
import { Box } from '@commonsku/styles';
import { Row, LightIndicator } from '@commonsku/styles';
import TopInfo from './misc/Info';

export default function AdminQuickbooksConnect({ reloadData }) {
    const callbackUrl = 'https://' + window.location.hostname + '/qbo_request_token.php';
    const popupRef = useRef(null);

    const doSomething = useCallback(() => {
        console.log('Do something hereeee');
        reloadData();
    }, [reloadData]);

    const checkPopupWindow = useCallback(() => {
        setTimeout(() => {
            // Detect open window is closed
            const interval = setInterval(() => {
                if (null === popupRef.current) {
                    popupRef.current = window.open("", "ippPopupWindow");
                }
                if (popupRef.current.closed) {
                    console.log('quickbook is closed. do something');
                    doSomething();
                    clearInterval(interval);
                }
            }, 1000);
        }, 3000);
    }, [doSomething]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js";
        script.async = true;
        script.onload = () => {
            const script = document.createElement('script');
            script.innerHTML = `
                intuit.ipp.anywhere.setup({
                    menuProxy: '',
                    grantUrl: '${callbackUrl}'
                });
            `;
            script.type = "text/javascript";
            document.body.appendChild(script);

            // attach click event
            setTimeout(() => {
                // add click event
                const button = document.getElementsByClassName('intuitPlatformConnectButton')[0];
                button.addEventListener('click', () => {
                    checkPopupWindow();
                });
            }, 1000);
        };

        document.body.appendChild(script);
    }, [callbackUrl, checkPopupWindow]);

    return (
        <>
            <TopInfo
                message={(
                    <>
                        Click the Connect to QuickBooks button below to authorize commonsku and
                        QuickBooks Online to connect and <a href="https://appcenter.intuit.com/cmsimages/promo/intuit_app_data_sharing/dynamicDataSharing/DataSharingFrame.html?scopes=com.intuit.quickbooks.accounting" target="_blank">share data for the purpose of our integration</a>,
                        following commonsku's <a href="https://commonsku.com/terms" target='_blank'>terms of service</a> and <a href="https://commonsku.com/privacy" target='_blank'>privacy policy</a>.
                        For added security, we will prompt you to reconnect commonsku and QuickBooks every 6 months.
                        However, you can disconnect the integration anytime.
                    </>
                )}
                link="https://help.commonsku.com/knowledge/connect-to-quickbooks-online"
            >
            </TopInfo>
            <LightIndicator name={'Not connected'} on={false} />
            <div dangerouslySetInnerHTML={{ __html: "<ipp:connectToIntuit></ipp:connectToIntuit>" }}></div>
        </>
    );
}
