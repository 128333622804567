import {
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';
import {  getGeneralSlice } from './helpers';

/*
"exchange_rate_id":"9d31534c-4282-4214-b46b-87b9bbcacf4d",
"tenant_id":"60d613ea-c3db-4265-9a46-442553c8619a",
"from_currency":"CAD",
"to_currency":"USD",
"rate":"1.200000"
*/
const adapter = createEntityAdapter({
    selectId: (object) => object.exchange_rate_id,
    sortComparer: (a, b) => (a.from_currency + a.to_currency).localeCompare(b.from_currency + b.to_currency)
});

/**
 * The slice/reducers
 */
const slice = getGeneralSlice('companyExchangeRates', adapter);

export const {
    added,
    deleted,
    loading,
    loaded,
    received,
    updated
} = slice.actions;

const baseUrl = 'currency-exchange-rate';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', baseUrl,  {});
    dispatch(received(res.json.exchange_rates));
    return res.json;
};

export const addItem = (data) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('POST', baseUrl, data);
    dispatch(received(res.json.exchange_rates));
    return res.json;
};

export const updateItem = (data) => async (dispatch, getState) => {
    console.log('Update', data);
    dispatch(loading());
    const res = await oauth('PUT', baseUrl + '/' + data.exchange_rate_id, data);
    dispatch(updated({ id: data.exchange_rate_id, changes: data }));
    dispatch(loaded());
    return res.json.exchange_rate;
};

export const deleteItem = (data) => async (dispatch, getState) => {
    console.log('delete', data);
    dispatch(loading());
    try {
        await oauth('DELETE', baseUrl + '/' + data.exchange_rate_id, {});
    } catch (err) {
        console.log('Failed to delete');
        return false;
    }
    console.log('deleted', data);
    dispatch(deleted(data.exchange_rate_id));
    dispatch(loaded());
    return true;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;
