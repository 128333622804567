import React from 'react';
import Form from './form/EmailSignature';
import useUserGeneralData from './hooks/useUserGeneralData';

const AdminEmailSignature = () => {
    const [data, loading, save] = useUserGeneralData();

    return (
        <>
            <Form data={data.data} loading={loading} save={save} />
        </>
    );
};

export default AdminEmailSignature;
