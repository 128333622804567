import React from 'react';
import { Row, Col, InfoIcon } from '@commonsku/styles';
import Form from './form/CompanyShipAccount';
import useCompanyShipAccount from './hooks/useCompanyShipAccount';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';

export default function AdminShipMethod() {
    const [items, loading, save, remove, couriers] = useCompanyShipAccount();

    const list = items.map((item, index) => {
        return (
            <div key={item.third_party_account_id} data-testid={`editable-row-${index}`}>
                <Row>
                    <Form item={item} saveItem={save} deleteItem={remove} couriers={couriers} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} couriers={couriers} />
        </Row>
    );

    return (
        <>
            <TopInfo message="Add your company's shipping details so suppliers can ship POs on your company account. Make sure to choose the appropriate courier for each account (FedEx, UPS, or Other)." link='https://help.commonsku.com/knowledge/3rd-party-shipping-accounts-2' />
            <Row>
                <Col padded sm={4}>
                    <b>Account Name</b>
                </Col>
                <Col padded sm={2}>
                    <b>Courier</b>
                </Col>
                <Col padded sm={3}>
                    <b>Account Number</b>
                </Col>
                <Col padded sm={2}>
                    <b>Billing Postal/Zip Code</b>
                </Col>
                <Col padded sm={1}>
                    <b>Delete</b>
                </Col>
            </Row>
            {list}
            {add}
            <ReactTooltip />
        </>
    );
}
