export const currencies = [
    'USD',
    'CAD',
    'AUD',
    'NZD',
    'SGD',
    'GBP',
    'BBD',
    'INR',
    'EUR',
];

export const presentation_style_options = [
    {value: 'TILE', label: 'Tiles'},
    {value: 'LIST', label: 'List'},
    {value: 'GALLERY', label: 'Gallery'},
    {value: 'SMART', label: 'Smart Gallery'},
    {value: 'SHOP_DESIGN', label: 'Design Beta'}
];

export const invoice_view_options = [
    {value: 'DETAIL', label: 'Detail'},
    {value: 'SUMMARY', label: 'Summary'}
];

export const shipdate_options = [
    {value: 'SHIPPING-DATE', label: 'Shipping Date'},
    {value: 'IN-HANDS-DATE', label: 'In Hands Date'}
];

export const invoice_views = ['SUMMARY', 'DETAIL'];

export const shipdate = ['SHIPPING-DATE', 'IN-HANDS-DATE'];

export const qbo_custom_field_options = [
    { value: '', label: ' '},
    { value: 'CUSTOMER-PO', label: 'Customer PO' },
    { value: 'CLIENT-REP', label: 'Client Rep' },
    { value: 'ORDER-REP', label: 'Order Rep' },
    { value: 'EVENT-TYPE', label: 'Event Type' },
    { value: 'IN-HANDS-DATE', label: 'In Hands Date' },
    { value: 'JOB-NAME', label: 'Project Name' },
    { value: 'JOB-NUMBER', label: 'Project Number' },
    { value: 'BILLING-CONTACT', label: 'Billing Contact' },
];

export const qbo_invoice_field_labels = [
    { value: '', label: ' '},
    { value: 'QBO_BILLADDR_LINE1', label: 'Billing Address Line 1' },
    { value: 'QBO_BILLADDR_LINE2', label: 'Billing Address Line 2' },
    { value: 'QBO_BILLADDR_LINE3', label: 'Billing Address Line 3' },
    { value: 'QBO_BILLADDR_LINE4', label: 'Billing Address Line 4' },
    { value: 'QBO_BILLADDR_CITY', label: 'Billing Address City' },
    { value: 'QBO_BILLADDR_STATE', label: 'Billing Address State' },
    { value: 'QBO_BILLADDR_COUNTRY', label: 'Billing Address Country' },
    { value: 'QBO_BILLADDR_POSTAL', label: 'Billing Address Postal' },
    { value: 'QBO_SHIPADDR_LINE1', label: 'Shipping Address Line 1' },
    { value: 'QBO_SHIPADDR_LINE2', label: 'Shipping Address Line 2' },
    { value: 'QBO_SHIPADDR_LINE3', label: 'Shipping Address Line 3' },
    { value: 'QBO_SHIPADDR_LINE4', label: 'Shipping Address Line 4' },
    { value: 'QBO_SHIPADDR_CITY', label: 'Shipping Address City' },
    { value: 'QBO_SHIPADDR_STATE', label: 'Shipping Address State' },
    { value: 'QBO_SHIPADDR_COUNTRY', label: 'Shipping Address Country' },
    { value: 'QBO_SHIPADDR_POSTAL', label: 'Shipping Address Postal' },
];

export const qbo_invoice_field_options = [
    { value: '', label: ' '},
    { value: 'client_name', label: 'Client Name', },
    { value: 'client_tenant_account_number', label: 'Client Account Number', },
    { value: 'invoice_number', label: 'Invoice Number', },
    { value: 'customer_po', label: 'Customer PO', },
    { value: 'bill_address_line_1', label: 'Billing Address Line 1', },
    { value: 'bill_address_line_2', label: 'Billing Address Line 2', },
    { value: 'bill_address_line_3', label: 'Billing Address Line 3', },
    { value: 'bill_address_line_4', label: 'Billing Address Line 4', },
    { value: 'bill_address_city', label: 'Billing Address City', },
    { value: 'bill_address_state', label: 'Billing Address State', },
    { value: 'bill_address_country', label: 'Billing Address Country', },
    { value: 'bill_address_postal', label: 'Billing Address Postal', },
    { value: 'ship_address_line_1', label: 'Shipping Address Line 1', },
    { value: 'ship_address_line_2', label: 'Shipping Address Line 2', },
    { value: 'ship_address_line_3', label: 'Shipping Address Line 3', },
    { value: 'ship_address_line_4', label: 'Shipping Address Line 4', },
    { value: 'ship_address_city', label: 'Shipping Address City', },
    { value: 'ship_address_state', label: 'Shipping Address State', },
    { value: 'ship_address_country', label: 'Shipping Address Country', },
    { value: 'ship_address_postal', label: 'Shipping Address Postal', },
    { value: 'billing_contact', label: 'Billing Contact', },
    { value: 'shipping_contact', label: 'Shipping Contact', },
    { value: 'client_rep', label: 'Client Rep', },
    { value: 'order_rep', label: 'Order Rep', },
    { value: 'job_number', label: 'Project Number', },
    { value: 'job_name', label: 'Project Name', },
    { value: 'client_tags', label: 'Client Tags', },
    { value: 'order_tags', label: 'Order Tags' },
];

export const qbo_class_options = [
    { value: '', label: ' '},
    { value: 'client_name', label: 'Client Name', },
    { value: 'client_tenant_account_number', label: 'Client Account Number', },
    { value: 'client_rep', label: 'Client Rep', },
    { value: 'order_rep', label: 'Order Rep', },
    { value: 'job_number', label: 'Project Number', },
    { value: 'job_name', label: 'Project Name', },
    { value: 'client_tags', label: 'Client Tags', },
    { value: 'order_tags', label: 'Order Tags' },
];
