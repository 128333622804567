import React from 'react';
import { Row, Col, InfoIcon } from '@commonsku/styles';
import Form from './form/CompanyCommissionMarginRate';
import useCompanyCommissionMarginRates from './hooks/useCompanyCommissionMarginRates';
import TopInfo from './misc/Info';
import ReactTooltip from 'react-tooltip';

export default function AdminCommissionMarginRate() {
    const [items, , save, remove] = useCompanyCommissionMarginRates();

    const list = items.map((item, index) => {
        return (
            <Row key={item.commission_margin_id}>
                <Form item={item} nextItem={items[index + 1]} saveItem={save} deleteItem={remove}  />
            </Row>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message={'Increase or decrease your team\'s commissions by applying a multiplier to their commission based on their final billed margin.'} link='https://help.commonsku.com/knowledge/how-to-create-update-commissions#table' />
            <Row>
                <Col padded sm={4}>
                    <b>Margin Minimum</b>
                </Col>
                <Col padded sm={4}>
                    <b>Margin Maximum</b>
                </Col>
                <Col padded sm={3}>
                    <b>Multiplier</b>
                </Col>
                <Col padded sm={1}>
                </Col>
            </Row>
            {list}
            {add}
            <ReactTooltip />
        </>
    );
}
