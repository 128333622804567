import React, { useState, useCallback, useEffect } from 'react';

import { Col, Row, Box, Button, Popup, GearIcon, Loading, UsersIcon } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import { oauth } from '../../utils';
import ContactAdmin from './ContactAdmin';
import '../../../scss/css/admin-settings.css';
import useCompanyGeneralDataById from '../../hooks/useCompanyGeneralDataById';
import withReducers from '../../store/withReducers';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import AdminLicensesLicensesDetails from './AdminLicensesLicensesDetails';
import { useHasCapabilities } from "../../hooks";

const AdminLicenses = () => {
    const identity = useIdentity();
    const [selectedModule, setSelectedModule] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [state, setState] = useState({
        quantity: 0, activeUsers: 0
    });
    const canEdit = useHasCapabilities(['MODIFY-SETTINGS']);
    const { company } = useCompanyGeneralDataById(identity.company_id);

    const updateSeats = async (quantity: number) => {
        if (quantity < 2) {
            return;
        }

        await oauth('POST', 'stripe', {
            action: 'change-plan',
            quantity,
        });

        setState({
            quantity,
            activeUsers: state.activeUsers,
        });
    };

    useEffect(() => {
        setLoading(true);
        oauth('GET', 'stripe', {}).then(
            ({ json }) => {
                setLoading(false);
                const { stripe_info, active_users } = json || {};
                if (!stripe_info.stripe_subscription_item) {
                    setError('Unable to retrieve plans');
                    return;
                }
                setState({
                    quantity: stripe_info?.stripe_subscription_item?.quantity ?? 0,
                    activeUsers: active_users || 0,
                });
            },
            () => setError('No payment information found')
        );
    }, []);

    const moduleMap = {
        'licenses': {
            'component': AdminLicensesLicensesDetails,
            'title': 'Licenses',
            'content': `${state.quantity} licenses`,
            'icon': <UsersIcon size='large' mr={16} color='#DAE9EE' />,
            'show': true,
        },
    };

    const onCloseModule = useCallback(() => {
        setSelectedModule(null);
    }, []);

    if (loading) {
        return <Loading mt={30} />;
    }

    if (error !== '') {
        return <p style={{textAlign: 'center', fontWeight: 'bolder', fontSize: '18px', margin: '30px auto', color: 'rgb(229, 38, 51)'}}>{error}</p>;
    }

    let popup = null;
    if (selectedModule) {
        const ModuleContainer = moduleMap[selectedModule]['component'];
        if (ModuleContainer) {
            popup = (
                <Popup
                    className={moduleMap[selectedModule]['isTabbed'] ? 'tabbed' : 'non-tabbed'}
                    style={{ padding: moduleMap[selectedModule]['isTabbed'] ? 0 : 20, maxWidth: '800px', maxHeight: '450px' }}
                    width='calc(100vw - 100px)'
                    height='calc(100vh - 100px)'
                    header={
                        <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                            <h2 style={{ margin: '0.2em' }}>{moduleMap[selectedModule]['title']}</h2>
                            <div>
                                <Button onClick={onCloseModule}>Done</Button>
                            </div>
                        </Row>
                    }>
                    <ModuleContainer identity={identity} inPopup={true} state={state} onSave={updateSeats} company={company} />
                </Popup>
            );
        }
    }

    const list = Object.keys(moduleMap).filter(
      moduleKey => moduleMap[moduleKey].show
    ).map((moduleKey) => {
        const SummaryContainer = moduleMap[moduleKey]['summary'] ?? null;
        const onclickCallback = moduleMap[moduleKey]['onclick'] ?? null;
        const height = moduleMap[moduleKey]['height'] ?? null;
        const content = moduleMap[moduleKey]['content'] ?? null;
        const icon = moduleMap[moduleKey]['icon'] ?? null;

        return (
            <Col key={moduleKey} lg={6} sm={12}>
                <Box style={{ padding: '15px', margin: '15px', cursor: canEdit ? 'pointer': 'default' }} onClick={(e) => {
                    if (!canEdit) return;
                    if (onclickCallback) {
                        onclickCallback();
                    } else {
                        setSelectedModule(moduleKey);
                    }
                }}>
                    <div style={{ height: height || '80px', overflow: 'hidden' }}>
                        {SummaryContainer ?
                            <SummaryContainer title={moduleMap[moduleKey]['title']} state={state} company={company} canEdit={canEdit} /> :
                            <div style={{ display: 'flex' }}>
                                <div>{icon ? icon : <GearIcon size='large' mr={16} color='#DAE9EE' />}</div>
                                <div style={{ flexGrow: 1 }}>
                                    <h5>{moduleMap[moduleKey]['title']}</h5>
                                    {content ? <div className='tile-summary-content'>{content}</div> : null}
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </Col>
        );
    });

    return (
        <>
            {!canEdit &&
                <ContactAdmin message="You don't have permission to edit Licenses Settings. Please contact one of your team's admins to make changes:" />
            }
            <Row>
                {list}
            </Row>
            {popup}
        </>
    );
};

export default withReducers(AdminLicenses, {
  companyGeneralDataById: companyGeneralDataReducer,
});
