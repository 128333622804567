import React, { useEffect } from 'react';
import { Row, Col, InfoIcon } from '@commonsku/styles';
import Form from './form/CompanyExchangeRate';
import TopInfo from './misc/Info';
import useCompanyExchangeRate from './hooks/useCompanyExchangeRate';
import ReactTooltip from 'react-tooltip';
import { rebuildTooltip } from '../helpers';

export default function AdminExchangeRate() {
    const [items, loading, save, remove, currencies] = useCompanyExchangeRate();

    useEffect(() => {
        if (items?.length > 0) {
            rebuildTooltip();
        }
    }, [items?.length]);

    let index = 0;
    const list = items.map((item, itemIndex) => {
        return (
            <div key={item.exchange_rate_id} data-testid={`row-${itemIndex}`}>
                <Row>
                    <Form index={index++} item={item} saveItem={save} deleteItem={remove} currencies={currencies} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row>
            <Form saveItem={save} currencies={currencies} />
        </Row>
    );

    return (
        <>
            <TopInfo message="Use currency conversion to simplify exchange rates on multicurrency orders (i.e., if you buy products in USD and sell them in CAD). Choose which currency you need to convert from, which to convert to, set your rate, and watch it automatically apply to your shops, sales forms, and supplier bills." link='https://help.commonsku.com/knowledge/how-to-create-and-adjust-your-margin-currency' />
            <Row>
                <Col padded sm={2}>
                    <b>From</b>
                </Col>
                <Col padded sm={2}>
                    <b>To</b>
                </Col>
                <Col padded sm={7}>
                    <b>Rate</b>  <InfoIcon altText={''} style={{ verticalAlign: 'middle' }}
                        data-padding='8px 8px'
                        data-html={true}
                        data-tip='<div style="padding: 10px">Info</div>'
                        width='16px' />
                </Col>
                <Col padded sm={1}>
                    <b>Delete</b>
                </Col>
            </Row>
            {list}
            {add}
            <ReactTooltip />
        </>
    );
}
