import React from 'react';
import { Row } from '@commonsku/styles';
import Form from './form/CompanyAddress';
import useCompanyAddress from './hooks/useCompanyAddress';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import TopInfo from './misc/Info';

export default function AdminCompanyAddress() {
    const [items, loading, save, remove] = useCompanyAddress();
    const [data, , saveCompanyData] = useCompanyGeneralData();

    const list = items.map((item, index) => {
        return (
            <div key={item.address_id} data-testid={`row-${index}`}>
                <Row style={{ borderBottom: '1px solid #ececec', marginBottom: '10px', paddingBottom: '10px' }}>
                    <Form defaultAddressId={data.data.tenant_default_address_id} item={item} saveItem={save} deleteItem={remove} saveCompanyData={saveCompanyData} />
                </Row>
            </div>
        );
    });

    const add = (
        <Row style={{ marginBottom: '250px' }}>
            <Form saveItem={save} />
        </Row>
    );

    return (
        <>
            <TopInfo message='Add or update the billing and shipping addresses that appears at the bottom of your sales and purchase forms.' link='https://help.commonsku.com/knowledge/company-contact-info' />
            {list}
            {add}
        </>
    );
}
