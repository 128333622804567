import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useQuickbookLineItemType from '../../hooks/useQuickbookLineItemType';
import { getObjectByKey} from './helpers';

export default function LineItemType({ qboAccounts, qboItems }) {
    const [lineItemTypes, saveQboAccount, saveQboItem] = useQuickbookLineItemType();
    const accountsPayable = qboAccounts.filter(q => q.AccountType === 'Accounts Payable');
    const accountsExpense = qboAccounts.filter(q => q.AccountType === 'Cost of Goods Sold' || q.AccountType === 'Expense');

    if (lineItemTypes.length === 0) return <Loading />;

    const placeHolders = {
        'BILL': 'Select an accounts payable',
        'INVOICE-SERVICE': 'Select a service',
        'BILL-SERVICE': 'Select a COGS or expense account',
        'BILL-ITEM': 'Select a COGS or expense account',
        'INVOICE-ITEM': 'Select a non-inventory product',
    };

    const getSelect = (isAccount, lineItemType) => {
        let options = [];
        let selectedValue = null;

        if (isAccount) {
            if (lineItemType.line_item_type_name === 'BILL') {
                options = accountsPayable;
            } else {
                options = accountsExpense;
            }
            options = options.map(a => ({ label: a.Name, value: a.ref }));
            const existingAccountRef = lineItemType.qbo_account_ref;
            selectedValue = existingAccountRef ? getObjectByKey(existingAccountRef, qboAccounts) : null;
            selectedValue = selectedValue ? {label: selectedValue.Name, value: selectedValue.ref} : null;
        } else {
            options = qboItems.map(a => ({ label: a.name, value: a.ref }));

            const existingItemRef = lineItemType.qbo_item_ref;

            selectedValue = existingItemRef ? getObjectByKey(existingItemRef, qboItems) : null;
            selectedValue = selectedValue ? {label: selectedValue.name, value: selectedValue.ref} : null;
        }
        options.unshift({label: '', value: ''});

        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder={placeHolders[lineItemType.line_item_type_name] || 'Select a product or service'}
                    label=''
                    options={options}
                    value={selectedValue}
                    onChange={(item) => {
                        item = item === null ? {label: '', value: ''} : item;
                        if (isAccount) {
                            saveQboAccount({
                                qbo_account_ref: item.value,
                            }, lineItemType);
                        } else {
                            saveQboItem({
                                qbo_item_ref: item.value,
                            }, lineItemType);
                        }
                    }}
                />
            </>
        );
    };

    const currentMappings = lineItemTypes.map((lineItemType) => {
        const isAccount = !!lineItemType.qbo_account;
        const item = isAccount ? lineItemType.qbo_account : lineItemType.qbo_item;

        return (
            <Row key={'qbo-currency-' + item.line_item_type_id}>
                <Col sm={6}>
                    <Input value={item.line_item_type_label} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{paddingLeft: '1rem'}}>
                    {getSelect(isAccount, item)}
                </Col>

            </Row>
        );
    });

    return (
        <>
            {currentMappings}
        </>
    );
}
